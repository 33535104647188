import React, {useState, useEffect} from 'react';
import { useSpring, config, animated as a } from 'react-spring';
import coin from '../../../assets/images/coin.png'
import lifeFilled from '../../../assets/images/life-filled.svg'
import lifeEmpty from '../../../assets/images/life-empty.svg'
import star48ColorIcon from '../../../assets/images/training-assets/star-48-color.png';
import { useTimerCount } from '../../hooks/timerCount';
import {Howl} from 'howler';

export const TimerBox = React.memo(()=>{

    const [days, hours, minutes, seconds] = useTimerCount(new Date());
  
    return (
      <div style={{
        padding:'3px 3px',
        paddingRight:10,
        background:'none',
        //boxShadow:'rgba(255, 153, 0, 0.52) -1px 0px 12px 3px',
        borderRadius:5,
        display:'flex',
        alignItems:'center',
        paddingRight:10,
        paddingLeft:10,
  
      }}>
  
  
    <div style={{display:'flex', paddingLeft:10, width:50, flexDirection:'column'}}>
      <span style={{fontSize:24, lineHeight:1, padding:'3px 5px', fontWeight:'normal', textShadow: '0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.5)', color:'#FF9900'}}>
      {hours}
      </span>
      <span style={{fontSize:8, fontWeight:'normal', color:'#FEFEFE'}}>Hr</span>
    </div>
    <div style={{display:'flex', paddingLeft:10, width:50, flexDirection:'column'}}>
      <span style={{fontSize:24, lineHeight:1, padding:'3px 5px', fontWeight:'normal', textShadow: '0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.5)', color:'#FF9900'}}>
      {minutes}
      </span>
      <span style={{fontSize:8, fontWeight:'normal', color:'#FEFEFE'}}>Min</span>
    </div>
    <div style={{display:'flex', paddingLeft:10, width:50, flexDirection:'column'}}>
      <span style={{fontSize:24, lineHeight:1, padding:'3px 5px', fontWeight:'normal', textShadow: '0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.5)', color:'#FF9900'}}>
      {seconds}
      </span>
      <span style={{fontSize:8, fontWeight:'normal', color:'#FEFEFE'}}>Sec</span>
    </div>
  
    
  </div>
    )
  })
  


export const ScoreBox = React.memo(React.forwardRef((props, forwardRef) => {
  
    const [springProps, api] = useSpring(()=>({from:{x:0.8}, config:config.slow}))
  
    const sound = new Howl({src:['audio/coin-win.wav'], volume:0.1,});
      
    useEffect(()=>{
  
        api.start({from:{x:0.8},to:{x:0}});
  
        
        if(props.points > 0 && props.isAudioOn)
        {
          sound?.play()
        }      
  
        console.log("P",props.points)          
  
    },[props.points])
    
    return(
      <a.div style={{
        padding:'3px 10px',          
        background:'rgba(200,200,200,0.1)',
        boxShadow:springProps.x.to(xVal => `rgba(255, 153, 0, ${xVal}) -1px 0px 12px 3px`),//`rgba(255, 153, 0, ${springProps.x.to(xVal => xVal)}) -1px 0px 12px 3px`,
        borderRadius:5,//springProps.x.to(v => v),
        display:'flex',
        alignItems:'center',
      }}>        
        <img ref={forwardRef} src={coin} width={36} height={36} />
        <div style={{display:'flex', paddingLeft:10, flexDirection:'column'}}>
        <span style={{fontSize:24, lineHeight:1, textShadow: '0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.5)', padding:'3px 5px', fontWeight:'normal', color:'#FF9900'}}>
        {props.points}
        </span>
          <span style={{fontSize:8, fontWeight:'normal', color:'#FCFCFC'}}>Points</span>
        </div>
        
      </a.div>
    )
  }),(p,c) => c.points == p.points)


  

export const GamePlayLifeBox = React.memo(React.forwardRef((props, forwardRef) => {
  
  const [springProps, api] = useSpring(()=>({from:{x:0.8}, config:config.slow}))

  const sound = new Howl({src:['audio/coin-win.wav'], volume:0.1,});
    
  useEffect(()=>{

      api.start({from:{x:0.8},to:{x:0}});

      
      //if(props.lives > 0 && props.isAudioOn)
      //{
      //  sound?.play()
      //}      

      //console.log("P",props.lives)          

  },[props.lives])
  
  return(
    <a.div style={{
      padding:'3px 10px',          
      background:'rgba(200,200,200,0.1)',
      boxShadow:springProps.x.to(xVal => `rgba(255, 153, 0, ${xVal}) -1px 0px 12px 3px`),//`rgba(255, 153, 0, ${springProps.x.to(xVal => xVal)}) -1px 0px 12px 3px`,
      borderRadius:5,//springProps.x.to(v => v),
      display:'flex',
      alignItems:'center',
    }}>        
      <img ref={forwardRef} src={lifeFilled} width={36} height={36} />
      <div style={{display:'flex', paddingLeft:10, flexDirection:'column'}}>
      <span style={{fontSize:24, lineHeight:1, textShadow: '0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.5)', padding:'3px 5px', fontWeight:'normal', color:'#FF9900'}}>
      {props.lives}
      </span>
        <span style={{fontSize:8, fontWeight:'normal', color:'#FCFCFC'}}>Lives</span>
      </div>
      
    </a.div>
  )
}),(p,c) => c.lives == p.lives)



export const GamePlayLevelBox = React.memo(React.forwardRef((props, forwardRef) => {
  
  const [springProps, api] = useSpring(()=>({from:{x:0.8}, config:config.slow}))

  const sound = new Howl({src:['audio/coin-win.wav'], volume:0.1,});
    
  useEffect(()=>{

      api.start({from:{x:0.8},to:{x:0}});

      
      //if(props.levels > 0 && props.isAudioOn)
      //{
      //  sound?.play()
      //}      

      //console.log("P",props.levels)          

  },[props.levels])
  
  return(
    <a.div style={{
      padding:'3px 10px',          
      background:'rgba(200,200,200,0.1)',
      boxShadow:springProps.x.to(xVal => `rgba(255, 153, 0, ${xVal}) -1px 0px 12px 3px`),//`rgba(255, 153, 0, ${springProps.x.to(xVal => xVal)}) -1px 0px 12px 3px`,
      borderRadius:5,//springProps.x.to(v => v),
      display:'flex',
      alignItems:'center',
    }}>        
      <img ref={forwardRef} src={star48ColorIcon} width={36} height={36} />
      <div style={{display:'flex', alignItems:'center', paddingLeft:10, flexDirection:'column'}}>
      <span style={{fontSize:24, lineHeight:1, textShadow: '0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.5)', padding:'3px 5px', fontWeight:'normal', color:'#FF9900'}}>
      {props.levels}
      </span>
        <span style={{fontSize:8, fontWeight:'normal', color:'#FCFCFC'}}>Levels</span>
      </div>
      
    </a.div>
  )
}),(p,c) => c.levels == p.levels)