import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import Logo1  from '../assets/images/brain-trainer-logo-large.svg';
import backgroundImage from '../assets/images/background_01.svg';
import DropIns from '../games/components/DropIns/DropIns';
import {animated, config, useSpring, animated as a} from 'react-spring';
import { useSelector, useDispatch} from 'react-redux';

import imageO1 from '../assets/images/cognitive-areas/01.svg';
import GameSelector from '../games/GameSelector/GameSelector';
import { useCountdownTimerCount } from '../games/hooks/timerCount';
import star48ColorIcon from '../assets/images/training-assets/star-48-color.png';
import star48greyIcon from '../assets/images/training-assets/star-48-grey.png';
import star100ColorIcon from '../assets/images/training-assets/star-100-color.png';
import lockedIcon from '../assets/images/training-assets/locked-icon.png';

import backIcon from '../assets/images/back-icon.png';
import FlashNotification from '../games/components/FlashNotification';


const CountDownBox = React.memo(({duration, onEndHandler}) =>{

    const {time, countDown} = useCountdownTimerCount(duration);

    const [days, hours, minutes, seconds] = time;

    const [springs,api] = useSpring(()=>({
        from: {
          //strokeDashoffset: 120,
          stroke:'#90ee90'
        },
        to: {
          //strokeDashoffset: 0,
          stroke:'#ff3300'
        },
        loop:true,
        //transform: `rotateX(${flipped ? 180 : 0}deg) translate(-50%, 0)`,
        //`perspective(600px) rotateX(${flipped ? 180 : 0}deg) translate(-50%, 0)`,
        //onRest:handleOnRest,//()=>{},//{console.log("Animation Ended"); props.onEnd && props.onEnd()},
        config: { duration: duration? duration * 1000 : 5000},
      }))

    useEffect(()=>{
        if(countDown === 0)
        {
            console.log("time ended");
            onEndHandler && onEndHandler();
        }
    },[countDown])


    //lightgreen => #90ee90

    return(
        <div style={{display:'flex', opacity:countDown <= 0 ? 0.3 : 1.0, alignItems:'center', width:'100%', justifyContent:'space-between', height:40}}>
               <div style={{margin:5, height:12, width:'70%', boxShadow:'inset 2px 2px 5px rgba(30,30,30,0.8)', borderRadius:6,background:'#CCCCCC'}}>
               {countDown > 0 && <a.div style={{margin:0, height:12, width:`${countDown/duration*100}%`, border:'1px solid transparent', transition:'width 1s', boxShadow:'inset -2px -2px 5px rgba(30,30,30,0.6)', borderRadius:6,
                    background:springs.stroke.to(s=>s)}}>
                
                    </a.div>}
               </div>
               <div>
               <span style={{fontWeight:'bold', letterSpacing:'2px', textShadow:'rgb(255 255 255 / 30%) 0px 1px 0px, rgb(0 0 0 / 50%) 0px -1px 0px', color:`${countDown > 10 ?'green': 'ec3209'}`, fontSize:24}}>{minutes}:{seconds}</span>
               </div>
               
            </div>
    )
})

const LevelCompletionSummaryPage = ({user, 
    currentLevel,
    currentStage,
    currentLevelScore,
    currentLevelTargetScore,
    currentLevelCount,
    currentStagesCount,
    currentStageName,
    currentGameTitle,
    retryHandler
    }) => {

    const [{x, opacity}, api] = useSpring(()=>({from:{x:-60, opacity:0}, config:config.wobbly}))

    //const user = useSelector( state => state.user);

    useEffect(()=>
    {
        api.start(()=>({from:{x:-60, opacity:0},to:{x:0, opacity:1}}))
    },[])

    return(
        <div style={{display:'flex', 
        flexDirection:'column',
        alignItems:'center',
        padding:30}}>
        <div style={{color:'#0f5a4d', fontSize:28, maxWidth:250}}  >
            <div>Hi, {user?.name}</div> 
            <div style={{color:'grey', fontSize:16}}>You're doing great!</div>   
        </div>

        <div style={{position:'relative', display:'flex', flexDirection:'column', height:'auto'}}>                
        <div style={{/* display:'flex', alignItems:'center' */}}>
           {/*  <div className='numbering-style-01'>1</div> */}
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                
                <animated.img width={150} style={{position:'relative',left:x, opacity}} src={star100ColorIcon}/>
                <div style={{display:'flex', padding:5, flexDirection:'column'}}>
                    <span style={{color:'#333333', fontSize:22}}>{currentStageName}</span>
                    <span style={{color:'#888888', fontSize:12}}>{currentGameTitle}</span>
                </div>
            </div>
        </div>
        <div style={{display:'flex', padding:15, flexDirection:'column'}}>
            <span style={{color:'#333333', fontSize:22}}>Level {currentLevel} of {currentLevelCount}</span>
            <span style={{color:'#777777', fontSize:18}}>+{currentLevelScore} of <span style={{color:'#b77a16', fontWeight:'bold'}}>{currentLevelTargetScore}</span> Points</span>
        </div>

        <div>
            <button style={{fontFamily:'Righteous',}} onClick={()=>retryHandler && retryHandler()}  className='flat-button-2'>{"Try Again"}</button>
            <div style={{color:'#0f5a4d', position:'relative', bottom:-15, fontSize:12, maxWidth:200}} >
                <p>
                You need a total of {currentLevelTargetScore} points to unlock a new level. 
                </p>
            </div>
        </div>
        {/* <div style={{display:'flex', alignItems:'center'}}>
            <div className='numbering-style-01'>2</div>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                
                <animated.img width={60} style={{position:'relative',left:x, opacity}} src={Logo1}/>
                <span>Speed</span>
            </div>
        </div>
        <div style={{display:'flex', alignItems:'center'}}>
            <div className='numbering-style-01'>3</div>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                
                <animated.img width={60} style={{position:'relative',left:x, opacity}} src={Logo1}/>
                <span>Attention</span>
            </div>
        </div>
        <div style={{display:'flex', alignItems:'center'}}>
            <div className='numbering-style-01'>3</div>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                
                <animated.img width={60} style={{position:'relative',left:x, opacity}} src={Logo1}/>
                <span>Reasoning</span>
            </div>
        </div> */}
    </div>
    </div>
    )
}

const emptyTrainingData = {
    currentStage:1,
    currentLevel:1,
    date:'',
    stages:[
        {
            index:1,
            gameCode:'spot-the-circles',                
            currentLevel:1,
            levels:[
                {
                    index:1,
                    passScore:5,
                    obtainedPoints:0,
                    time:60
                },
                {
                    index:2,
                    passScore:10,
                    obtainedPoints:0,
                    time:80
                },
                {
                    index:3,
                    passScore:20,
                    obtainedPoints:0,
                    time:120
                },
                /*{
                    index:4,
                    passScore:30,
                    obtainedPoints:0,
                    time:150
                }*/
            ],               
            cognitiveCategory:'memory',
        },
        {
            index:2,
            gameCode:'recall-the-digits',                
            currentLevel:1,
            levels:[
                {
                    index:1,
                    passScore:2,
                    obtainedPoints:0,
                    time:18
                },
                {
                    index:2,
                    passScore:5,
                    obtainedPoints:0,
                    time:30
                },
                {
                    index:3,
                    passScore:10,
                    obtainedPoints:0,
                    time:50
                }
            ],                
            cognitiveCategory:'speed',
        }
    ]
}

const TrainingView = (props) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const viewStages = ["name", "gender", "age"]

    const gamesList =useSelector(state => state.games) //[{ code:'spot-the-circles'}, {code:'recall-the-digits'}]
    const gameInfo = useSelector(state => state.gameInfo)
    const cognitiveCategories = useSelector(state => state.cognitiveCategories) 
    const savedTrainingData = useSelector(state => state.trainingData.find(td => td.sessionId === new Date().toISOString().slice(0,10)))

    const [currentTrainingStage, setCurrentTrainingStage] = useState(0);
    const [isTrainingStarted, setIsTrainingStarted] = useState(false);
    const [isCurrentGameEnded, setIsCurrentGameEnded] = useState(false);

    const [currentStage, setCurrentStage] = useState(savedTrainingData ? savedTrainingData.currentStage : 1);
    const [currentLevel, setCurrentLevel] = useState(savedTrainingData ? savedTrainingData.currentLevel : 1);
    const [currentLevelScore, setCurrentLevelScore] = useState(0);
    const [currentLevelTargetScore, setCurrentLevelTargetScore] = useState(0);   
    
    const [currentLevelCount, setCurrentLevelCount] = useState(0);
    const [currentStagesCount, setCurrentStagesCount] = useState(0); 

    const [currentStageName, setCurrentStageName] = useState("");
    const [currentGameTitle, setCurrentGameTitle] = useState("");   
    
    const [session, setSession] = useState(Math.ceil(Math.random()*10000000000000000000000))

    const [showLevelUpNotification, setShowLevelUpNotification] = useState(false);
    const [showStageUpNotification, setShowStageUpNotification] = useState(false);
    const [showTrainingCompletedNotification, setShowTrainingCompletedNotification] = useState(false);

    const getGameTitle = (gameCode) => {
        return gameInfo.find(g => g.code === gameCode)?.title;
    }

    const getCognitiveCategoriesName = (categoryCode) => {

        //console.log("EC",{categoryCode ,cognitiveCategories})//.find(g => g.code === categoryCode))

        return cognitiveCategories.find(g => g.code === categoryCode)?.name;
    }

    const [trainingData, setTrainingData] =  useState(savedTrainingData ?
   {...savedTrainingData} : {...emptyTrainingData}
    )

    const [{x, opacity}, api] = useSpring(()=>({from:{x:-60, opacity:0}, config:config.wobbly}))

    const user = useSelector( state => state.user);

    useEffect(()=>
    {
        api.start(()=>({from:{x:-60, opacity:0},to:{x:0, opacity:1}}))
    },[currentTrainingStage])

    const updateUserField = (e) => {
        const {name, value} = e.target;
        dispatch({type:"UPDATE_USER_INFO", data:{[name]:value, sessionTime:new Date().toISOString()}});    

    }

    const gotToNext = () => {

        if(currentTrainingStage >= 2)
        {
            navigate("/all-games")
        }
        else
        {
            setCurrentTrainingStage(c => c + 1)
        }

    }

    const goToBack = () => {

        if(currentTrainingStage == 0)
        {
            navigate("/all-games")
        }
        else
        {
            setCurrentTrainingStage(c => c - 1)
        }

    }

    const onCountDownEndHandler = () =>{
        setIsCurrentGameEnded(true);
    }

    const onSessionPointChange = (points) => {
        console.log("SESSION_SCORE", points)

        let currentStageLevelScore = trainingData.stages[currentStage-1]?.levels[currentLevel-1]?.obtainedPoints + points;

        let currentLevelTarget = trainingData.stages[currentStage-1]?.levels[currentLevel-1]?.passScore;

        let currentLevelCount = trainingData.stages[currentStage-1]?.levels?.length;

        let currentStagesCount = trainingData.stages.length;

        let currentStageLevel = currentLevel;
        let currentTrainingStage = currentStage;

        let isLevelToBeUpraded = false;

        setCurrentLevelScore(currentStageLevelScore);

        console.log("level info", {currentStageLevelScore, 
            currentLevel,
            currentLevelCount,
            currentLevelTarget})

        if(currentStageLevelScore >= currentLevelTarget)// && currentStageLevel <= currentLevelCount)
        {
            // isLevelToBeUpraded = true;
            if(currentLevel < currentLevelCount) 
            {
                currentStageLevel++;
                setCurrentLevel(currentStageLevel);
                console.log("level up 1")

                if(currentStageLevel > 1)
                {
                    console.log("level up 2")
                    setShowLevelUpNotification(true);

                    setTimeout(()=>setShowLevelUpNotification(false), 2000);
                }
            }
            else
            {
                if(currentTrainingStage < currentStagesCount)
                {
                    currentTrainingStage++;

                    if(currentTrainingStage > 1)
                    {
                        console.log("level up 2")
                        setShowStageUpNotification(true);

                        setTimeout(()=>setShowStageUpNotification(false), 2000);
                    }

                    setCurrentStage(currentTrainingStage);            
                    currentStageLevel = 1;
                    setCurrentLevel(currentStageLevel);
                }  
                else{
                    //alert("Today's training completed.")
                    setShowTrainingCompletedNotification(true);
                    setCurrentLevel(currentLevelCount + 1); // To completed current level
                }          
            }
        }

        


        

        setTrainingData(td => ({
            ...td,
            currentLevel:currentStageLevel,
            stages: td.stages.map(st => 
                st.index === currentStage ? 
                
                ({
                    ...st,
                    levels: st.levels.map(lv => 
                        lv.index === currentLevel ? 
                        
                        ({
                            ...lv,
                            obtainedPoints: lv.obtainedPoints + points
                        })
                        :
                        ({
                           ...lv
                        })
                    )
                })
                :
                ({
                   ...st 
                })
                
             )
        }))
    }


    const goToNextStage = () => {
        setCurrentStage(c => {
            if(c + 1 <= trainingData.stages.length)
            {                
                return c + 1;
            }
            else
            {
                return c
            }
        })
    }

    const goToPreviousStage = () => {
        setCurrentStage(c => {
            if(c - 1 > 0)
            {
                return c - 1;
            }
            else
            {
                return c
            }
        })
    }
    
    useEffect(()=>{
        let currentLevelCount = trainingData.stages[currentStage-1]?.levels?.length;

        let currentStagesCount = trainingData.stages.length;

        setCurrentLevelCount(currentLevelCount);
        setCurrentStagesCount(currentStagesCount);

        setCurrentStageName(getCognitiveCategoriesName(trainingData.stages[currentStage-1]?.cognitiveCategory));
        setCurrentGameTitle(getGameTitle(trainingData.stages[currentStage-1]?.gameCode));  
        
        // Persist
        dispatch({type:"SAVE_TRAINING_DATA", data:{...trainingData, sessionId:new Date().toISOString().slice(0,10)}});
   

    }, [trainingData])

    useEffect(()=>{
        let currentStageLevelScore = trainingData.stages[currentStage-1]?.levels[currentLevel-1]?.obtainedPoints;

        let currentLevelTarget = trainingData.stages[currentStage-1]?.levels[currentLevel-1]?.passScore;

        let currentLevelCount = trainingData.stages[currentStage-1]?.levels?.length;

        //let currentLevel = trainingData.stages[currentStage-1]?.currentLevel;

        setCurrentLevelScore(currentStageLevelScore);
        setCurrentLevelTargetScore(currentLevelTarget);
        setCurrentLevelCount(currentLevelCount);
        //setCurrentLevel(trainingData.stages[currentStage-1]?.currentLevel)

        setCurrentStageName(getCognitiveCategoriesName(trainingData.stages[currentStage-1]?.cognitiveCategory));
        setCurrentGameTitle(getGameTitle(trainingData.stages[currentStage-1]?.gameCode));   


    }, [currentLevel, currentStage])
        
    const clearTrainingData = () => {
        setCurrentLevel(1)
        setCurrentStage(1)
        setTrainingData({...emptyTrainingData, sessionId:new Date().toISOString().slice(0,10)})
        //dispatch({type:"SAVE_TRAINING_DATA", data:{...emptyTrainingData, sessionId:new Date().toISOString().slice(0,10)}});
    }

    return(
        <div style={{display:'flex',
         justifyContent:'space-between',
         padding:10,
         textAlign:'center',
         alignItems:'center',
         position:'fixed', 
         flexDirection:'column',
         background:'linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%)',
         background:`url('${backgroundImage}')`,
         //background:`white`,
         backdropFilter:'greyscale(80%)',
         backgroundRepeat:'no-repeat',
         backgroundSize:'cover',
         height:'100vh', width:'100vw'}}>

            {!isTrainingStarted ? <>
            <div style={{
                width:'100%',
                fontSize:20,
                color:'#108c54',
                display:'flex',
                justifyContent:'space-between'
            }}>
                <span onClick={()=>goToBack()} style={{
                    cursor:'pointer',
                    //borderBottom:'1px dotted green',
                    padding:5
                }}>
                    <animated.img width={40} style={{position:'relative',left:x, opacity}} src={backIcon}/>
                </span>

                
            </div>
                          
          
           
           <div style={{color:'#0f5a4d', fontSize:28, maxWidth:250}}  >
                <div>Hi, {user?.name}</div> 
                <div style={{color:'grey', fontSize:16}}>Let's train...</div>   
            </div>

            <div style={{position:'relative', width:'100%', alignItems:'center', display:'flex', flexDirection:'column', height:'auto'}}>  
                <div style={{display:'flex', flexDirection:'row', width:'90%', justifyContent:'space-around', alignItems:'center'}}>  
                    {/* <span onClick={()=>goToPreviousStage()}>Previous</span> */}            
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <div className='numbering-style-01'>{currentStage}</div>
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            
                            <animated.img width={150} style={{position:'relative',left:x, opacity}} src={imageO1}/>
                            <div style={{display:'flex', padding:5, flexDirection:'column'}}>
                                <span style={{color:'#333333', fontSize:22}}>{getCognitiveCategoriesName(trainingData.stages[currentStage-1]?.cognitiveCategory)}</span>
                                <span style={{color:'#888888', fontSize:12}}>{getGameTitle(trainingData.stages[currentStage-1]?.gameCode)}</span>
                            </div>
                        </div>
                    </div>
                    {/* <span onClick={()=>goToNextStage()}>Next</span>  */}
                </div>
                {/* <div style={{display:'flex', alignItems:'center'}}>
                    <div className='numbering-style-01'>2</div>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        
                        <animated.img width={60} style={{position:'relative',left:x, opacity}} src={Logo1}/>
                        <span>Speed</span>
                    </div>
                </div>
                <div style={{display:'flex', alignItems:'center'}}>
                    <div className='numbering-style-01'>3</div>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        
                        <animated.img width={60} style={{position:'relative',left:x, opacity}} src={Logo1}/>
                        <span>Attention</span>
                    </div>
                </div>
                <div style={{display:'flex', alignItems:'center'}}>
                    <div className='numbering-style-01'>3</div>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        
                        <animated.img width={60} style={{position:'relative',left:x, opacity}} src={Logo1}/>
                        <span>Reasoning</span>
                    </div>
                </div> */}
            </div>
            <animated.div style={{ position:'relative', bottom:x, opacity,}} >
                
            <div style={{display:'flex', alignItems:'center', width:'100%', justifyContent:'space-between', height:50}}>
                
                {/* <img width={36} src={imageO1}/> */}
                <div style={{display:'flex', padding:5, flexDirection:'column'}}>
                    <span style={{color:'#333333', fontSize:22}}>Level {currentLevel}</span>
                    <span style={{color:'#777777', padding:5, fontSize:14}}>+{trainingData.stages[currentStage-1]?.levels[currentLevel-1]?.obtainedPoints} of <span style={{color:'#b77a16', fontWeight:'bold', fontSize:14}}>{trainingData.stages[currentStage-1]?.levels[currentLevel-1]?.passScore}</span> Points</span>
                </div>

                {trainingData.stages[currentStage-1]?.levels.map(l => 
                    <img width={24} src={l.index > currentLevel -1 ? star48greyIcon : star48ColorIcon}/>
                )}
                
                
            </div>
           
                
            </animated.div>
            <div style={{display:'flex', flexDirection:'column'}}>
                <button onClick={()=>setIsTrainingStarted(true)} style={{fontFamily:'Righteous',}}  className='flat-button-2'>{currentLevel > 1 || currentStage > 1 ? "CONTINUE" : "START"}</button>
                
                {(currentLevel > 1 || currentStage > 1) && <button onClick={()=>clearTrainingData()} style={{fontFamily:'Righteous',fontSize:12, border:0, background:'none', padding:20, color:'orange'}} >{"CLEAR TRAINING DATA"}</button>}
                

                <div style={{color:'#0f5a4d', position:'relative', bottom:-15, fontSize:12, maxWidth:250}} >
                    <p>
                    Brain Trainer 2022
                    </p>
                </div>
            </div>
            </>
            : showTrainingCompletedNotification ?

            <div style={{fontSize:30,
                position:'relative', height:'100%', width:'100%', 
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column',
                background:'rgba(20,20,20,0.9)', color:'#ff9900', fontWeight:'bold'}}>
               ...Training completed!
               <div>
               <button onClick={()=>{setIsTrainingStarted(false);setIsCurrentGameEnded(false);/* setShowTrainingCompletedNotification(false) */}} style={{fontFamily:'Righteous', boxShadow:'0px 1px 0px 1px rgb(26 108 140)'}}  className='flat-button-2'>{"CLOSE"}</button>
                
               </div>
           </div>

            :

            <>
            <div style={{display:'flex', alignItems:'center', width:'100%', justifyContent:'space-between', height:50}}>
                <span onClick={()=>{setIsTrainingStarted(false);setIsCurrentGameEnded(false)}} style={{
                    cursor:'pointer',
                    //borderBottom:'1px dotted green',
                    padding:5
                }}>
                    <animated.img width={40} style={{position:'relative',left:x, opacity}} src={backIcon}/>
                </span>
                
                <div style={{display:'flex', padding:5, flexDirection:'row'}}>
                    <img width={40} src={imageO1}/>
                    <div style={{display:'flex', padding:5, flexDirection:'column'}}>
                        <span style={{color:'#333333', fontSize:22}}>{getCognitiveCategoriesName(trainingData.stages[currentStage-1]?.cognitiveCategory)}</span>
                        <span style={{color:'#888888', fontSize:12}}>{getGameTitle(trainingData.stages[currentStage-1]?.gameCode)}</span>
                    </div>
                </div>
                <div style={{display:'flex', padding:5, flexDirection:'column'}}>
                    <div>
                    {trainingData.stages[currentStage-1]?.levels.map(l => 
                        <img width={24} src={l.index > currentLevel -1 ? star48greyIcon : star48ColorIcon}/>
                    )}
                    </div>
                    <span style={{color:'grey'}}><span style={{fontSize:18,color:'#e69500'}}>+{currentLevelScore}</span>/{currentLevelTargetScore}</span>
                </div>               
            </div>
            <CountDownBox  key={`${currentLevel}-${currentStage}-${session}`} duration={trainingData.stages[currentStage-1]?.levels[currentLevel-1]?.time} onEndHandler={onCountDownEndHandler} />
            <div style={{position:'relative', borderRadius:14, boxShadow:'2px 2px 8px rgba(30,30,30,0.4)', overflow:'hidden', height:'100%',width:'100%'}}>    
            {isCurrentGameEnded ?                
                    
                    <LevelCompletionSummaryPage 
                    currentLevel={currentLevel}
                    currentStage={currentStage}
                    currentLevelScore={currentLevelScore}
                    currentLevelTargetScore={currentLevelTargetScore}
                    currentLevelCount={currentLevelCount}
                    currentStagesCount={currentStagesCount}
                    currentStageName={currentStageName}
                    currentGameTitle={currentGameTitle}
                    retryHandler={()=>{setIsCurrentGameEnded(false);setSession(Math.ceil(Math.random()*10000000000000000000000))}}
                    user={user}/>
                    :
                    <GameSelector key={`${currentLevel}-${currentStage}`} hideGameTime={true} gameTime={10} onSessionPointChange={onSessionPointChange} onGameEnd={()=>console.log('gameEnded')} gameCode={trainingData.stages[currentStage-1]?.gameCode}/>
            }
            {showLevelUpNotification && <div style={{fontSize:30,
                 position:'relative', height:'100%', width:'100%', 
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
                 flexDirection:'column',
                 background:'rgba(20,20,20,0.9)', color:'#ff9900', fontWeight:'normal'}}>
                <FlashNotification size={260}><span style={{fontSize:30, color:'#065806',fontWeight:'normal'}}>Level Up!</span></FlashNotification>
                <div  style={{fontSize:36, fontWeight:'normal'}}>Level {currentLevel}</div>
                <div  style={{fontSize:20, padding:10, color:'white'}}>Unlocked!</div>
            </div>}
            {showStageUpNotification && <div style={{fontSize:30,
                 position:'relative', height:'100%', width:'100%', 
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
                 flexDirection:'column',
                 background:'rgba(20,20,20,0.9)', color:'#9cef9c', fontWeight:'normal'}}>
                <FlashNotification size={260}><span style={{fontSize:30, color:'#065806',fontWeight:'normal'}}>Stage Up!</span></FlashNotification>
                
                <div  style={{fontSize:26, padding:10, fontWeight:'normal'}}><span style={{fontSize:30, color:'#ff9900'}}>{currentStageName}</span></div>
               <div  style={{fontSize:20,  fontWeight:'normal', color:'white'}}>training stage</div>                
                 {/* <div  style={{fontSize:26, fontWeight:'normal'}}> {currentGameTitle}!</div> */}
                <div  style={{fontSize:26, padding:10, color:'white'}}>Unlocked!</div>
            </div>}
            </div>
            </>
           
            }
        {/* <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
         */}</div>
        )
}

export default TrainingView;//LayoutWrapper(SplashScreen, {showNavigationBar:false, showSideMenu:false});