import React, {useState, useEffect} from 'react';
//import {Box} from '../Board/Box.js';
import Card from './Card.js';
//import { DndProvider } from 'react-dnd'
  
import { Cell } from './Cell.js';
import CustomDragLayer from './CustomDragLayer.js';

import data from "./dictionary.json";
import { useSprings, animated } from '@react-spring/web';

import FlipCard from '../components/FlipCard/FlipCard';
import CountDownTicker from '../components/CountDownTicker/CountDownTicker';
import FluidBox from '../../components/FluidBox/FluidBox';
import useMeasure from 'react-use-measure';
import ok_button from '../../assets/images/ok-button.svg';
import removeIcon from '../../assets/images/remove_icon_small.png'
import { useTimerCount } from '../hooks/timerCount';
import {useDispatch, useSelector} from 'react-redux';
import PullRelease from '../../components/PullRelease/PullRelease';
import { ScoreBox, TimerBox } from '../components/InfoComponents/infoComponents';
import { shuffle } from '../../utilities/arrayUtilities';
import HorizontalScrollView from '../components/HorizontalScrollView/HorizontalScrollView.js';

import FlashNotification from '../components/FlashNotification';


/*

Circles	    1	2	3	4	5	 	7	8	 	10	11	12	13	14
Triangles	1	2	3	4	5		7	8		10	11	12	13	14
Crosses	    1	2	3		5		7			10	11		13	14
Squares	    1	2	3		5		7			10	11		13	14
Stars	    1	2	3	4	5		7	8
Whot!	Four or five letters, numbered "20" in some packs.

*/

const words = data.words;//

/*[
    {
        word:"Badly",
        definition:"Not good",
        wordClass:"noun"
    },
    {
        word:"Intelligence",
        definition:"The ability...",
        wordClass:"noun"
    },
    {
        word:"Intelligence2",
        definition:"The ability to...",
        wordClass:"noun"
    },
    {
        word:"Intelligence3",
        definition:"The ability to do what...",
        wordClass:"noun"
    }
]*/

const letters = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p",
                "q","r","s","t","u","v","w","x","y","z",]
const vowels = ["a","e","i","o","u",]

const letters2 = [
    {letter:"a", id: "1"},
    {letter:"b", id: "2"},
    {letter:"c", id: "3"},
    {letter:"d", id: "4"},
    {letter:"e", id: "5"},
    {letter:"f", id: "6"},
    {letter:"g", id: "7"},
    {letter:"h", id: "8"},
    {letter:"i", id: "9"},
    {letter:"j", id: "10"},
    {letter:"k", id: "11"},
    {letter:"l", id: "12"},
    {letter:"m", id: "13"},
    {letter:"n", id: "14"},
    {letter:"o", id: "15"},
    {letter:"p", id: "16"},
    {letter:"q", id: "17"},
    {letter:"r", id: "18"},
    {letter:"s", id: "19"},
    {letter:"t", id: "20"},
    {letter:"u", id: "21"},
    {letter:"v", id: "22"},
    {letter:"w", id: "23"},
    {letter:"x", id: "24"},
    {letter:"y", id: "25"},
    {letter:"z", id: "26"},  
   
]




      const getChunks = (chunkSize, array, chunksCount = 0) => {
        //const chunkSize = 10;
        let chunks = [];
        let count = 0;
        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            chunks.push(chunk);
            count++;
            // do whatever
            if(chunksCount > 0 && chunksCount == count)
            {
                chunks.push([...array.slice(i + chunkSize)]);
                break;
            }
            
        }

        return chunks;
      }


      const DropIns = (props) => {
        const [springs, api] = useSprings(props.children ? props.children.length : 5, index => ({y:-50, opacity: 0 }))
    
        // Update springs with new props
        React.useEffect(()=>{
            console.log("RErenered")
            api.start(index => ({from:{y:-50, opacity: 0}, to:{y:0, opacity: 1},delay:index*100 }))
        },[props.hash])
        
        // Stop all springs
        //api.stop()
    
        return (<div style={{display:'flex', justifyContent:'center', ...props.style}}>
            {springs.map((styles, index) => <animated.div style={styles}>{props.children && props.children[index]}</animated.div>)}
        </div>)
    }

 const WordGame = (props) => {

    const shuffleCards = shuffle(letters);



    //const shuffleWords = shuffle(words);
    const NUMB_OF_INITIAL_CARDS = 8;
    const NUMB_OF_PLAYERS = 2;

    const [generatedOptions, setGeneratedOptions] = React.useState([])
    const [currentOption, setCurrentOptions] = React.useState(null)

   
    const tref = React.useRef(null);

    
    const [isShowingPickedItems, setIsShowingPickingCard] = useState(true);

    const [startRef, { left:startLeft, width:sWidth, top:startTop, height:sHeight }] = useMeasure()
  
    const [endRef, { left:endLeft, width:eWidth, top:endTop, height:eHeight }] = useMeasure()

    const [toggle, setToggle] = React.useState(false)
  
    const [offset, setOffset] = React.useState({x:0,y:0})

    const [showCorrectNotification, setShowCorrectNotification] = useState(false);

    const [showIncorrectNotification, setShowIncorrectNotification] = useState(false);

    const dispatch = useDispatch();

    
    const [flipped, setFlipped] = useState(false);

    
    const [points, setPoints] = useState(0);

    const gamePlayOptions = useSelector(state => state.gamePlayOptions)

    const [selectedGamePlayOptions, setSelectedGamePlayOptions] = useState(null);

    React.useEffect(()=>{

        //if(selectedGame)
        {
            let gameOptions = gamePlayOptions.find(g => g.gameCode === props.gameCode);

            if(gameOptions)
            {
                setSelectedGamePlayOptions({...gameOptions})
            }
            else
            {
                setSelectedGamePlayOptions({
                    gameCode:props.gameCode,
                    isAudioOn:false,
                    difficulty: 1
                }) 
            }
        }

    },[gamePlayOptions])


    React.useLayoutEffect(()=>{
      const {x, y} = tref.current.getBoundingClientRect();
      setOffset({x, y})
    },[])


    const flashCorrectNotification = () => {
        setShowCorrectNotification(true);

        setTimeout(()=>setShowCorrectNotification(false), 1000);
        setToggle(t => !t);
    }

    const flashIncorrectNotification = () => {
        setShowIncorrectNotification(true)
        setTimeout(()=>setShowIncorrectNotification(false), 1000);
    }

    React.useEffect(()=>{
        generateOptions();
    },[])

    React.useEffect(()=>{
        
        console.warn("CURRENT_OPTIONS", {...currentOption})

    },[currentOption])

    const getNextOption = () => {

        // compare letters to word


        console.log({sl:currentOption.selectedLetters, w:currentOption.word})

        if(currentOption.selectedLetters.join("") == currentOption.word)
        {
            setPoints(p => p + 2);
            flashCorrectNotification();
        }
        else
        {
            flashIncorrectNotification();
        }

        let newOptions = [...generatedOptions]

        if(newOptions.length > 0)
        {
            setCurrentOptions({...newOptions.pop()});
            setGeneratedOptions(newOptions);
        }

        //setFlipped()
        setIsShowingPickingCard(true);
    }

    const pickRandomLetters = (wordString) => {

        

        let wordArray = wordString.split("");

        let len = wordArray.length;

        let randomPickedArray = [];

        let shuffled = shuffle(wordArray).splice(0,Math.ceil(len/3) +1);

        //console.log("SHUELLFED", shuffled)

        for(let i = 0; i<wordArray.length; i++)
        {
            if(shuffled.some(s => s === wordArray[i]))
            {
                randomPickedArray.push(wordArray[i])
            }
            else
            {
                randomPickedArray.push("")
            }
        }

        return randomPickedArray;

    }

    const generateOptions = () => {

        let shuffledWords = shuffle(words);

       

        let newGeneratedOptions = shuffledWords.map((word)=>{

            let selectedLetters = pickRandomLetters(word.word);

            let remLetters =  word.word.split("").filter(l => !selectedLetters.filter(se => se != "").some(s => s === l))

            let lettersArr = [...shuffle(remLetters),...shuffle(letters).splice(0,3)]

            
    
            lettersArr = lettersArr.filter(l => selectedLetters.filter(se => se != "").some(s => s != l))
            
            return {
            ...word,
            remLetters,
            selectedLetters,//word.word.split("").map(w => ""),
            fixedLetters:[...selectedLetters],
            letters:[...lettersArr]
        }})

        console.warn("OPTIONS_GH", {newGeneratedOptions, shuffledWords})

        const newCurrentOption = newGeneratedOptions.pop();
        console.warn("OPTIONS_", {newGeneratedOptions, newCurrentOption, shuffledWords})
        setCurrentOptions({...newCurrentOption})
        setGeneratedOptions(newGeneratedOptions);
    }

    const getNextWord = () => {
        
    }

    
    const playCards = getChunks(NUMB_OF_INITIAL_CARDS, shuffleCards, NUMB_OF_PLAYERS);

    //console.log(playCards[0]);

    useEffect(()=>{
        props.onSessionPointChange && props.onSessionPointChange(points)
        dispatch({type:"UPDATE_GAME_SCORE", data:{gameCode:props.gameCode, sessionTime:new Date().toISOString(), sessionId: props.sessionId, points:points}});
 
        //if(points > 0)
        //    setToggle(t => !t);
    },[points])
   

    const playCard = React.useCallback((item, index) =>
    {
        console.log("playCard", currentOption)

        let selectedLetters  = [...currentOption.selectedLetters];

        let characterHolder = selectedLetters[index];

        selectedLetters[index] = item.card;

        let letters = [...currentOption.letters]

        letters.splice(item.index,1);

        if(characterHolder !== "")
        {
            letters.push(characterHolder)
        }

        console.log({index:item.index, selectedLetters, letters})

        setCurrentOptions(co =>({...co, selectedLetters,letters}))//, letters: co.letters.slice(item.index,1)}))

        /* console.warn(item)        
        setPlayer1Pool(gd => gd.filter(c => `${c.letter}_${c.id}` != `${item.card.letter}_${item.card.id}`))
        setSelectedCard(item); */
    },[currentOption])

    const restoreCard = React.useCallback((item, index) =>
    {
        console.log("restoreCard", currentOption)

        let selectedLetters  = [...currentOption.selectedLetters];

        selectedLetters[index] = ""; //item.card;

        //selectedLetters[item.index] = "";

        let letters = [...currentOption.letters];

        letters.push(item);

        console.log({index:item.index, i:index, selectedLetters});

        setCurrentOptions(co =>({...co, selectedLetters, letters}))//, letters: co.letters.slice(item.index,1)}))

        /* console.warn(item)        
        setPlayer1Pool(gd => gd.filter(c => `${c.letter}_${c.id}` != `${item.card.letter}_${item.card.id}`))
        setSelectedCard(item); */ //[a,b,c,d,e]
    },[currentOption])

    const playCardMove = React.useCallback((item, index) =>
    {
        

        let selectedLetters  = [...currentOption.selectedLetters];

        if(selectedLetters[index] !== "") //Move all to the right
        {
            let temp = selectedLetters[item.index];
            selectedLetters[item.index] = "";

            let startIndex = item.index;
            let endIndex = index;

            console.log("playCardMove", {end:index, start:item.index});

            //[0,1,2,3,4].        
            if(startIndex < endIndex)
            {
                for(let i = startIndex+1; i <= endIndex ; i++)
                {
                    // temp = selectedLetters[i+1]
                    if(selectedLetters[i] != "")
                        selectedLetters[i-1] = selectedLetters[i]
                }
            }
            /* else
            {
                for(let i = selectedLetters.length-2 ; i >= index ; i--)
                {
                    // temp = selectedLetters[i+1]
                    if(selectedLetters[i] != "")
                        selectedLetters[i+1] = selectedLetters[i]
                }
            } */
            else
            {
                //for(let i = selectedLetters.length-2 ; i >= index ; i--)
                for(let i = startIndex ; i >= endIndex ; i--)
                {
                    // temp = selectedLetters[i+1]
                    if(selectedLetters[i] != "")
                        selectedLetters[i+1] = selectedLetters[i]
                }
            }

            selectedLetters[index] = item.card;
        }
        else
        {
            selectedLetters[index] = item.card;

            selectedLetters[item.index] = "";
        }
        

        //let letters = [...currentOption.letters]

        //letters.splice(item.index,1)

        console.log({index:item.index, i:index, selectedLetters})


        setCurrentOptions(co =>({...co, selectedLetters}))//, letters: co.letters.slice(item.index,1)}))

        /* console.warn(item)        
        setPlayer1Pool(gd => gd.filter(c => `${c.letter}_${c.id}` != `${item.card.letter}_${item.card.id}`))
        setSelectedCard(item); */
    },[currentOption])

    const goToNextQuestion = React.useCallback((cc)=>{
        //console.log("GG", {containerRef, cc})
        setIsShowingPickingCard(false);
        setFlipped(false);//getNextQuestions()
        
      },[])

      const goToNextQuestion2 = React.useCallback((cc)=>{
        //console.log("GG", {containerRef, cc})
        setIsShowingPickingCard(true);
        setFlipped(true);//getNextQuestions()
        //getNextOption()
      },[])


      useEffect(()=>{

        //getNextQuestions();
        console.warn("PARENT",{flipped, isShowingPickedItems})
        if(!isShowingPickedItems)
            getNextOption();

    },[isShowingPickedItems])


   

    return(
        <>
        <FluidBox start={[startLeft + sWidth/2-offset.x, startTop+sHeight/2 -offset.y]} show={toggle} end={[endLeft + eWidth/2-offset.x, endTop + eHeight/2-offset.y]}>
      
      <div ref={tref} style={{
        width:'100%',
        height:'100%',
        //background:'#FFFFFF',
        display:'flex',
        flexDirection:'column',
       
      }}>
  
      <div className={'game-play-info-header'}>

      {!props.hideGameTime && <TimerBox/>}      
      
        <ScoreBox  isAudioOn={selectedGamePlayOptions?.isAudioOn}  points={points} ref={endRef} />
      </div>
  
       
       
        <div style={{
            height:'100%',
            width:'100%',
            padding:10,
            background:'none',
            position:'relative',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-around',
            alignItems:'center'

        }}>

{true && <div style={{
        width:'100%',
        //flexGrow:1,
        position:'relative',
        //background:'#EEEEEE',
        padding:5,
        height:80,
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexDirection:'column',
      }}>
        {flipped == true ? <CountDownTicker  key={1 +isShowingPickedItems } refreshHash={flipped} duration={3} onEnd={()=>goToNextQuestion()}/>
        : <CountDownTicker key={2 + isShowingPickedItems + currentOption?.word } refreshHash={!flipped} duration={30} onEnd={()=>setFlipped(true)}/>
        } 
        {/* <CountDownTicker refreshHash={!flipped} duration={flipped? 16 : 3} onEnd={()=>setFlipped(f => !f)}/> */}
      </div>}

            {currentOption && currentOption.wordClass && <div style={{height:50, width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <span style={{border:'1px solid green', padding:'5px 15px', background:'none'}}>{currentOption.wordClass}</span>
            </div>}
            <div style={{height:50, width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <span style={{border:'0px solid green', textAlign:'center', padding:'15px 15px', color:'#555555', boxShadow:'2px 2px 10px 2px rgb(62 41 41 / 20%)', borderRadius:10, fontSize:20, background:'white'}}>{currentOption && currentOption.definition}</span>
            </div>
            <div style={{fontSize:25, color:'grey', letterSpacing:2, fontWeight:'bold'}}>{currentOption && currentOption?.selectedLetters?.map( c => c === "" ? "_" : c)}</div>
            <div style={{ width:'100%',height:100,display:'flex', 
          justifyContent:'center', 
          width:'100%',  overflowX:'auto', overflowY:'visible', }}>
                
                
                <FlipCard flipped={flipped} style={{maxWidth:'100%', display:'flex', height:100}}
                
                back={<HorizontalScrollView key={currentOption?.word + "2"}><div style={{flexGrow:1,  display:'inline-flex', /* overflowX:'auto',overflowY:'visible', */  height:'100%', width:'100%', alignItems:'center', justifyContent:'center' }}>
                     
                    {currentOption && currentOption.selectedLetters.map((character, index) =>  <Cell key={`${index}-${Math.random()}`}  index={index} handleCardDrop={playCardMove}  character={character} style={{boxShadow:'2px 2px 0px rgb(12 12 11 / 62%)', borderRadius:10, margin:2, /* padding:'5px 15px', */ background:'linear-gradient(135deg, rgba(60 239 239 0.1) 0%, rgb(255 255 255) 100%)'}}> {character !=="" && <Card key={`${character}-${index}-${Math.random()}`} index={index} card={character} handleCardDrop={currentOption.fixedLetters.some(c => c == character) ? null : playCardMove} style={{border:'0px solid green', borderRadius:10, margin:0, padding:'5px 15px', background:`${currentOption.fixedLetters.some(c => c == character) ? 'linear-gradient(135deg, rgb(60, 239, 239) 0%, rgb(255, 255, 255) 100%)' : 'white'}`}}>
                    {!currentOption.fixedLetters.some(c => c == character) && <div onClick={()=>restoreCard(character, index)} style={{width:28,height:28, borderRadius:'50%', color:'white', fontSize:11, display:'flex', alignItems:'center', justifyContent:'center', bottom:-30, position:'absolute', background:'black'}}><img width={20} src={removeIcon}/></div>}</Card> }</Cell>) } 
                    
                    </div></HorizontalScrollView>}
                front={<HorizontalScrollView key={currentOption?.word}><div style={{flexGrow:1,  display:'inline-flex', overflowX:'auto', overflowY:'visible',  height:'100%',  width:'100%',  alignItems:'center', justifyContent:'center' }}>
                    {currentOption && currentOption.word.split("").map((character, index) =>  <Cell key={`${index}-${Math.random()}`}  index={index} handleCardDrop={playCardMove}  character={character} style={{boxShadow:'2px 2px 0px rgb(12 12 11 / 62%)',borderRadius:10, margin:2, /* padding:'5px 15px', */ background:'linear-gradient(135deg, rgba(60 239 239 0.1) 0%, rgb(255 255 255) 100%)'}}> {character !=="" && <Card key={`${character}-${index}-${Math.random()}`} index={index} card={character} handleCardDrop={playCardMove} style={{border:'0px solid green', borderRadius:10, margin:0, padding:'5px 15px', background:'white'}}>
                    </Card> }</Cell>)} 
                    </div></HorizontalScrollView>}/>
                
                
            </div>
            
            {<div style={{ width:'100%', overflowX:'auto', visibility: flipped ? 'visible' : 'visible', display:'flex', overflowY:'visible'}}>            
               {/*  <HorizontalScrollView key={currentOption?.word+"3"}> ["a","r","h","c","w","w","d","v","b",] currentOption.letters*/}
                    <DropIns hash={currentOption?.word} style={{flexGrow:1,  display:'inline-flex', flexWrap:'wrap', overflowX:'auto',overflowY:'visible', height:'100%', alignItems:'center' }}>
                        {!flipped && currentOption && currentOption.letters.map((userCard, index) => <Card key={`${userCard}-${index}-${Math.random()}`} index={index} card={userCard} handleCardDrop={playCard} style={{boxShadow:'2px 2px 0px rgb(12 12 11 / 62%)', borderRadius:10,  margin:5, padding:'5px 15px', background:'linear-gradient(135deg, rgb(60 239 239) 0%, rgb(255 255 255) 100%)'}}/>)}
                    </DropIns>
                {/* </HorizontalScrollView> */}
            </div>}
           {/*  <div onClick={()=>getNextOption()} style={{height:50, width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <span style={{border:'1px solid green', padding:'5px 15px', background:'yellow'}}>OK</span>
            </div>
            */}
            <div ref={startRef} >
                {<div onClick={()=>goToNextQuestion2()} style={{margin:20, cursor:'handle', visibility: flipped ? 'visible' : 'visible', borderRadius:'50%', cursor:'pointer', 
                margin:10,
                    background:'#96fbc4',
                    borderRadius:20,
                    fontSize:16,
                    color:'#003c36',
                    border:0,
                    padding:'10px 25px',
                    boxShadow:'2px 2px 2px rgba(20,20,20,0.5)'}}>
                  SUBMIT  
                </div>}
            </div>

           {/*   */}
        </div>
{/* <PullRelease/> */}
        </div>

        {showCorrectNotification && <div style={{fontSize:30,
        position:'absolute', height:'100%', width:'100%', 
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        background:'rgba(20,20,20,0.9)', color:'#9cef9c', fontWeight:'normal'}}>
    <FlashNotification size={260}><span style={{fontSize:30, color:'#065806',fontWeight:'normal'}}>Correct!</span></FlashNotification>              
    
    
</div>}    

{showIncorrectNotification && <div style={{fontSize:30,
        position:'absolute', height:'100%', width:'100%', 
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        background:'rgba(20,20,20,0.9)', color:'#9cef9c', fontWeight:'normal'}}>
    <FlashNotification color={'red'} size={260}><span style={{fontSize:30, color:'#FFFFFF',fontWeight:'normal'}}>Incorrect!</span></FlashNotification>              
    
    
</div>}   
      
      </FluidBox>
      
<CustomDragLayer/>
</>
        
    )
}

export default WordGame;