import React, {useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import LayoutWrapper from '../layout/LayoutWrapper';
import ViewWrapper from './ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../components/Prompt/Prompt';
import defaultIcon  from '../assets/images/default-game-icon.svg';
import ToggleSelect from '../components/ToggleSelect/ToggleSelect';

const Settings = (props)  => {

    let history = useNavigate();

    const gamesList =useSelector(state => state.games) //[{ code:'spot-the-circles'}, {code:'recall-the-digits'}]

    const dispatch = useDispatch(); 

    const user = useSelector(state => state.user);
    
    const assets = useSelector(state => state.assets);

    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);

    const [updatedUser, setUpdatedUser] = useState({...user});
    
    const updateUserField = (e) => {
        const {name, value} = e.target;
        setUpdatedUser(u => ({...u, [name]:value}));    

    }

    const save = () => {
       
        dispatch({type:"UPDATE_USER_INFO", data:{...updatedUser, sessionTime:new Date().toISOString()}});    

    }
    /* const deleteAssetRecord = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the asset?",OkAction:()=>{dispatch(deleteAsset(id));setOpenPromptDialog(false)}});
    } */

    return( <ViewWrapper title={'Settings'}>
   
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>


                {/* <div style={{margin:5}}>
                        <div style={{display:'flex', flexDirection:'column', width:'98%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                        <input  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{boxSizing:'border-box', boxShadow:'0px 0px 2px rgba(10,10,10,0.2)', background:"#eeeeee", padding:'2px 10px', outline:'none', height:40, border:'0px solid #CCCCCC', width:'100%', borderRadius:5}}/>
                        </div>      
                </div> */}

                <div style={{margin:5}}>
                <div style={{ width:'100%',display: 'flex', flexDirection:'column',
            gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))', minHeight:'calc(100vh-50px)'}}>
              
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Full Name</label>
                        <input onChange={updateUserField} value={updatedUser.name} name="name"  className='text-input'/>
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Email</label>
                        <input onChange={updateUserField} value={updatedUser.email} name="email"  className='text-input' />
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Gender</label>
                        <select  onChange={updateUserField} value={updatedUser.gender} name="gender"  className='text-input'>
                            <option></option> 
                            <option>Male</option> 
                            <option>Female</option>  
                        </select>
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Age</label>
                        <select  onChange={updateUserField} value={updatedUser.age} name="age"  className='text-input'>
                            <option>Less than 10</option> 
                            <option>10 - 18</option> 
                            <option>19 - 25</option> 
                            <option>26 - 35</option> 
                            <option>36 - 55</option> 
                            <option>over 55</option>    
                        </select>
                        
                    
                    </div>
                   {/*  <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Audio</label>
                        <div  style={{
                        display:'flex',
                        //flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                            <span className='text-input-label'>On</span>
                            <ToggleSelect onChange={s=>setUpdatedUser(u => ({...u,audioOn:s}))} isTrue={updatedUser.audioOn}/>
                            <span className='text-input-label'>Off</span>
                        </div>
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Volume</label>
                        <div  style={{
                        display:'flex',
                        //flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                            <input type={'range'}  onChange={updateUserField} value={updatedUser.audioVolume} name="audioVolume"  min={1} max={10}/>
                        </div>
                        
                    
                    </div> */}
                </div>

                <div style={{display:'flex', justifyContent:'center'}}>
                    <button style={{fontFamily:'Righteous'}} onClick={()=>save()} className='flat-button-2'>SAVE</button>
                </div>
                
                </div>

    </ViewWrapper>)

}   

export default LayoutWrapper(Settings, {showNavigationBar:true, showSideMenu:true});