import React from 'react';
import {useSpring,animated} from 'react-spring';
import FluidBox from '../components/FluidBox/FluidBox';
import useMeasure from 'react-use-measure';
import LayoutWrapper from '../layout/LayoutWrapper'
//import { useTrail, animated } from 'react-spring'
import logo from '../assets/images/logo.svg'



function FlashCards() {
 
    const tref = React.useRef(null);

  const [startRef, { left:startLeft, width:sWidth, top:startTop, height:sHeight }] = useMeasure()

  const [endRef, { left:endLeft, width:eWidth, top:endTop, height:eHeight }] = useMeasure()

  const [toggle, setToggle] = React.useState(false)

  const [offset, setOffset] = React.useState({x:0,y:0})

  const showScoreEffect = () => {

    setToggle(t => !t);

    console.log(tref.current.getBoundingClientRect())

    

    console.log({ startLeft, startTop, endLeft, endTop })

  }

  React.useEffect(()=>{
    const {x, y} = tref.current.getBoundingClientRect();
    setOffset({x, y})
  },[])

  React.useLayoutEffect(()=>{
    const {x, y} = tref.current.getBoundingClientRect();
    setOffset({x, y})
  },[])
  

 
  //export default 

  return (
    
      
      
<FluidBox start={[startLeft + sWidth/2-offset.x, startTop+sHeight/2 -offset.y]} show={toggle} end={[endLeft + eWidth/2-offset.x, endTop + eHeight/2-offset.y]}>
        <div ref={tref} style={{display:'flex', flexDirection:'column', height:'100%', width:'100%', position:'absolute', insert:0}}>
          <div ref={endRef} style={{
            height:100,
            width:100,
            position:'absolute',
            top:0,
            right:0,
            backgroundColor:'red'

          }}> 
          <div style={{
            height:100,
            width:100,
            position:'absolute',
            top:0,
            right:0,
            backgroundColor:'red'

          }}> </div>
          
          </div>
        
        <img src={logo} width={20} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <div ref={startRef} onClick={()=>showScoreEffect()} style={{
            height:100,
            width:100,
            cursor:'pointer',
            backgroundColor:'blue',
            margin:20

          }}> </div>

        <div ref={startRef} onClick={()=>showScoreEffect()} style={{
            height:100,
            width:100,
            cursor:'pointer',
            backgroundColor:'green',
            margin:20

          }}> </div>
     </div>
        </FluidBox>
        
    
  );
}

export default FlashCards;//App;
