import React, {useState, useEffect} from 'react';
import { useSprings, animated } from '@react-spring/web';

const DropIns = (props) => {
    const [springs, api] = useSprings(props.children ? props.children.length : 5, index => ({y:-50, opacity: 0 }))

    // Update springs with new props
    React.useEffect(()=>{
        console.log("RErenered")
        api.start(index => ({from:{y:-50, opacity: 0}, to:{y:0, opacity: 1},delay:index*100 }))
    },[props.hash])
    
    // Stop all springs
    //api.stop()

    return (<div style={{display:'flex', justifyContent:'center', ...props.style}}>
        {springs.map((styles, index) => <animated.div style={styles}>{props.children && props.children[index]}</animated.div>)}
    </div>)
}

export default DropIns;