import React from 'react';
import { useDrop } from 'react-dnd'
import { CellTypes } from './CellTypes.js'

const defaultStyle = {
  height: '50px',
  width: '50px',
  
  /* marginRight: '1.5rem',
  marginBottom: '1.5rem', */
  color: 'white',
  /* padding: '1rem', */
  textAlign: 'center',
  fontSize: '8px',
  lineHeight: 'normal',
  float: 'left',
  
}
export const Cell = (props) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: CellTypes.BOX,
    drop: () => ({ name: 'Dustbin', index: props.index }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))
  const isActive = canDrop && isOver
  let backgroundColor = props.cellType == 1 ?  '#222':'#603940'
  if (isActive) {
    backgroundColor = 'darkgreen' 
  } else if (canDrop) {
    backgroundColor = props.cellType == 1 ?  'darkkhaki':'#603940'
  }

  const cellStyle =  props.style ? props.style : defaultStyle;

  //console.log("draggable", props.currentCardPlay?.card)

  return (
    <div ref={drop} style={{ backgroundColor, minWidth:35, 
      height:40, 
      background:'red', display:'flex', ...cellStyle }} data-testid="emtpy-box">
      {/* isActive ? 'Release to drop' : 'Drag a box here' */}
       { /* props.cellType == 1 &&<Card name="Paper" /> */}
       {props.children}
       
      
    </div>
  )
}
