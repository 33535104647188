import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { useSpring, animated as a, config } from 'react-spring';
import LayoutWrapper from '../layout/LayoutWrapper';
import ViewWrapper from './ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../components/Prompt/Prompt';
import defaultIcon  from '../assets/images/default-game-icon.svg';
import searchIcon from '../assets/images/search.svg'
import PopUp from '../components/PopUp/PopUp';
import DropIns from '../games/components/DropIns/DropIns';
import backgroundImage from '../assets/images/background_01.svg';
import imageO1 from '../assets/images/cognitive-areas/01.svg';
import imageO2 from '../assets/images/cognitive-areas/02.svg';
import imageO3 from '../assets/images/cognitive-areas/03.svg';
import imageO4 from '../assets/images/cognitive-areas/04.svg';
import Moment from 'moment';
import ToggleSelect from '../components/ToggleSelect/ToggleSelect';


const GameIcon = ({code,name, onClick, color, index=0}) => {

    let history = useNavigate();

    
    const gamePlayData = useSelector(state => state.gamePlayData)


    const [styles, api] = useSpring(()=>({from:{y:-20, opacity:0.2, scale:1}, config:config.wobbly}))

    React.useEffect(()=>
    {
        api.start({to:{y:0, opacity:1, scale:1}})
    },[])

    const launchGame = (link) => {
        api.start({to:[{y:-20, opacity:0, scale:5},{y:0, opacity:1, scale:1}]})

        
        setTimeout(()=>{onClick && onClick(link)},300)
    }

    //TODO: move to utility
    const getGameLastPlayDataByCode = (code) => {
        
        //console.log("DDAA",Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime))))

        let lastPlayed = Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime)));

        if(lastPlayed && lastPlayed !== -Infinity)
        {
            console.log("lastPlayed",lastPlayed);
            return Moment(new Date(lastPlayed)).startOf('second').fromNow()
        }
        return "";//new Date(Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime))));
        
    }

    const backgroundsImages = [imageO1,imageO2,imageO3,imageO4]
    return(
        <div key={code} style={{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            margin:10,
            position:'relative'

        }}> <div style={{
            width:150,
            height:150,
            position:'relative',
            
            border:'0px solid green',
            background:'linear-gradient(135deg, rgb(255, 241, 235) 0%, rgb(172, 224, 249) 100%)',
            background:'linear-gradient(135deg, rgb(255, 241, 235) 0%, rgb(237 249 172) 100%)',
            background:'linear-gradient(135deg, rgb(221 205 249) 0%, rgb(230 238 255) 100%)',
            background:'#f0ffff',
            background:color,
            background:'#fbd6d6',
            backgroundColor: '#e3ffed',
            //backgroundImage:`url(${backgroundsImages[(index) % backgroundsImages.length]})`,
            backgroundSize:'100% 100%',
            backdropFilter:'greyscale(1px)',
            //boxShadow:'2px 2px 2px rgba(10,10,10,0.2)',
            borderRadius:5,
            //filter:'drop-shadow(2px 2px 2px rgba(10,10,10,0.7))',
            boxShadow:'0px 0px 30px -8px rgba(20,20,20,3.3)',
            display:'flex',
            justifyContent:'center',
            alignItems:'flex-end',
            overflow:'hidden',
            margin:10,
        }}  onClick={()=>launchGame(code)}><a.div /* to={`/game/${g.code}`} */ onClick={()=>launchGame(code)}
        style={{padding:10,fontSize:12, color:'white',
        transform: styles.scale.to(s => `scale(${s})` ), textAlign:'center', bottom:styles.y, opacity:styles.opacity,position:'absolute', cursor:'pointer', background:'rgba(10,10,10,0.8)', width:'100%'}}
        >{name}</a.div>
            {gamePlayData.filter(g => g.gameCode === code).length > 0 && <span style={{color:'#156d4f', background:'none', padding:'3px 8px',  position:'absolute', top:0, left:0, fontWeight:'normal', fontSize:13}}>{gamePlayData.filter(g => g.gameCode === code).length}&nbsp;x</span>}
            <img  src={defaultIcon} style={{height:80,  margin:20, top:1, /* transform: styles.scale.to(s => `scale(${s})` ), top:styles.y, opacity:styles.opacity, */ position:'absolute',fontFamily:'Dancing Script'}} alt="logo" /> 
            </div>
        {/* <a onClick={()=>launchGame(code)} className={"game-list-link"} >{name}</a> */}
        
        <span style={{color:'#888888', fontWeight:'normal', fontSize:11}}>{getGameLastPlayDataByCode(code)}</span>
                      
        </div>
    )
}

const AllGames = (props)  => {

  

    const gamesList =useSelector(state => state.games) //[{ code:'spot-the-circles'}, {code:'recall-the-digits'}]
    const gameInfo = useSelector(state => state.gameInfo) 
    const gamePlayData = useSelector(state => state.gamePlayData)
    const gamePlayOptions = useSelector(state => state.gamePlayOptions)

    const dispatch = useDispatch(); 

    let history = useNavigate();

    const user = useSelector(state => state.user);
    
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [showGameInfoPopup, setShowGameInfoPopup] = useState(false);
    
    const [selectedGame, setSelectedGame] = useState(null);
    const [selectedGamePlayOptions, setSelectedGamePlayOptions] = useState(null);

    useEffect(()=>{

        if(selectedGame)
        {
            let gameOptions = gamePlayOptions.find(g => g.gameCode === selectedGame.code);

            if(gameOptions)
            {
                setSelectedGamePlayOptions({...gameOptions})
            }
            else
            {
                setSelectedGamePlayOptions({
                    gameCode:selectedGame.code,
                    isAudioOn:false,
                    difficulty: 1
                }) 
            }
        }

    },[gamePlayOptions])

    useEffect(()=>{

        if(selectedGame)
        {
            let gameOptions = gamePlayOptions.find(g => g.gameCode === selectedGame.code);

            if(gameOptions)
            {
                setSelectedGamePlayOptions({...gameOptions})
            }
            else
            {
                setSelectedGamePlayOptions({
                    gameCode:selectedGame.code,
                    isAudioOn:false,
                    difficulty: 1
                }) 
            }
        }

    },[selectedGame])

    const filterList = (searchStr, data, fieldName) => {
        if(data && fieldName)
        {
            return data.filter( d => d[`${fieldName}`].toLowerCase().match(searchStr.toLowerCase()))
        }
        else
        {
           return []; 
        }
    }

    const linkClickHandler = (code) => {

        //history(`/game/${code}`);
        

        console.log({code, d:gameInfo.find(g => g.code === code)})

        setSelectedGame(gameInfo.find(g => g.code === code))

        setShowGameInfoPopup(true)

    }

    const updateGamePlayOption = (name, value) => {
        //const {name, value} = e.target;
        //setUpdatedUser(u => ({...u, [name]:value})); 
        
        dispatch({type:"UPDATE_GAME_PLAY_OPTIONS", data:{...selectedGamePlayOptions, [name]:value}});    


    }

    const getDifficultyColor = (difficulty) => {
        console.log(difficulty)
        switch(parseInt(difficulty))
        {
            case 1: return 'green';

            case 2: return '#b1ab00';

            case 3: return '#ff7700';

            default: return 'red';
        }
    }
   
    const launchGame = (code) =>
    {
        history(`/game/${code}`);
    }
    /* const deleteAssetRecord = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the asset?",OkAction:()=>{dispatch(deleteAsset(id));setOpenPromptDialog(false)}});
    } */

     //TODO: move to utility
     const getGameLastPlayDataByCode = (code) => {
        
        //console.log("DDAA",Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime))))

        let lastPlayed = Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime)));

        if(lastPlayed && lastPlayed !== -Infinity)
        {
            console.log("lastPlayed",lastPlayed);
            return Moment(new Date(lastPlayed)).startOf('second').fromNow()
        }
        return "";//new Date(Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime))));
        
    }

    return(<> <ViewWrapper title={'All Games'}>
   
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>


                <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                    {searchString && <div style={{color:'white', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
                    <input  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{boxSizing:'border-box', boxShadow:'2px 2px 4px rgba(10,10,10,0.5)', background:"#eeeeee", backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%', backgroundPositionY:'50%',padding:'5px 10px', outline:'none', height:50, fontFamily:'Righteous',fontSize:16, color:'green', border:'0px solid #CCCCCC', width:'100%', borderRadius:5}}/>
                    </div>      
                </div>

                <div style={{margin:5}}>
                <div style={{ width:'100%',display: 'inline-grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))', minHeight:'calc(100vh-50px)'}}>
              
                {filterList(searchString, gamesList, "name").map((g,i) => <GameIcon onClick={linkClickHandler} index={i} key={g.code} {...g}/>)}
                </div>
                
                <div style={{height:80}}></div>
                
                </div>
               
                

    </ViewWrapper>
    { selectedGame && <PopUp open={showGameInfoPopup} >
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'left', alignItems:'center', fontFamily:'Dancing Script', fontWeight:'bold', fontSize:30}}>
            <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" /> 
            <div style={{display:'flex', flexDirection:'column'}}>

            <div style={{display:'flex'}}>{selectedGame?.title}{/*.split(' ').map(wd => <><DropIns key={wd}>{wd}</DropIns> &nbsp;</>)*/}</div>
            <div style={{display:'flex'}}>
            <span style={{color:'#888888', fontWeight:'normal', fontFamily:'Righteous',padding:'3px 8px',  fontSize:12}}>{getGameLastPlayDataByCode(selectedGame?.code)}</span>
            {gamePlayData.filter(g => g.gameCode === selectedGame?.code).length > 0 && <span style={{color:'#156d4f', background:'none', padding:'3px 8px', fontFamily:'Righteous',  /* position:'absolute', top:0, left:0, */ fontWeight:'normal', fontSize:13}}>{gamePlayData.filter(g => g.gameCode === selectedGame?.code).length}x</span>}
           
            </div>
            </div>
            
            </div>
            <div style={{flexGrow:1, fontWeight:'normal', color:'#555555', maxHeight:'40vh', background:'#f0fdfa', boxShadow:'inset 0px 2px 5px', padding:20, textAlign:'left', overflowY:'auto'}}>
                <div style={{fontWeight:'bold'}}>Description</div>
                <p>{selectedGame?.description}</p>
                <div style={{fontWeight:'bold'}}>How To Play</div>
                <ol>{selectedGame.howToPlay.map((h) => <li>{`${h}`}</li>)}</ol>
                <div style={{fontWeight:'bold'}}>Benefits</div>
                <ol>{selectedGame.benefits.map((b,i) => <li>{`${b}`}</li>)}</ol>
            </div>
            <div style={{width:'100%', 
        padding:10,
         
        background:'#CECECE', display:'flex', justifyContent:'space-between'}}>
                <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Audio</label>
                        <div  style={{
                        display:'flex',
                        //flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                            <span className='text-input-label'>On</span>
                            <ToggleSelect onChange={s=>updateGamePlayOption('isAudioOn',s)} isTrue={selectedGamePlayOptions?.isAudioOn}/>
                            <span className='text-input-label'>Off</span>
                        </div>
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Difficulty 
                        <div style={{display:'inline-flex',margin:0, height:36, width:36, marginLeft:10, 
                        background: getDifficultyColor(selectedGamePlayOptions?.difficulty),
                        textAlign:'center'}} className='numbering-style-01'>
                            {selectedGamePlayOptions?.difficulty}
                        </div></label>
                        <div  style={{
                        display:'flex',
                        //flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                            <input type={'range'}  onChange={(e)=>updateGamePlayOption('difficulty', e.target.value)} value={selectedGamePlayOptions?.difficulty}  name="difficulty"  min={1} max={4}/>
                        </div>
                        
                    
                    </div>
            </div>      
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='flat-button-2' onClick={()=>launchGame(selectedGame?.code)} >Start</button>
                <button className='flat-button-2' onClick={() => setShowGameInfoPopup(false)}>Cancel</button>
            </div>            
        </div>
    </PopUp>}
    </>)

}   

export default LayoutWrapper(AllGames, {showNavigationBar:true, showSideMenu:true,backgroundImage});