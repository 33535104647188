
import React, { Children } from 'react'
import { useDrag } from 'react-dnd'
import { CellTypes } from './CellTypes.js'
import { getEmptyImage } from 'react-dnd-html5-backend';

import styles from './wordgame.module.css'



const defaultStyle = {
  border: '1px dashed gray',
  backgroundColor: 'red',
  padding: '0.5rem 1rem',
  /* marginRight: '1.5rem',
  marginBottom: '1.5rem', */
  cursor: 'move',
  float: 'left',
  fontSize:15,
  height:50,
  width:50,
  borderRadius:'50%'
}

 const Card = ({ card, style, index, handleCardDrop, children }) =>{
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: CellTypes.BOX,
    item: { card, index },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        handleCardDrop && handleCardDrop(item, dropResult.index)

        console.log("Item", item)
        //alert(`You dropped ${item.card} into ${dropResult.name} ${dropResult.index}!`)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  React.useEffect(()=>{
    preview(getEmptyImage(), {captureDraggingState:true})
  },[])
  const opacity = isDragging ? 0 : 1
  const cardStyle = style ? style : defaultStyle

  
  
  return (
    <div ref={drag} style={{ opacity, display:'flex', position:'relative', width:35, height:40, fontSize:24, ...cardStyle }} className={styles.characterWrapper} data-testid={`box`}>
      {/* name */}
      {card}
      {children}
    </div>
  )
}

export default Card;