import React from 'react'
import ReactGA from 'react-ga'

const useAnalyticsEventTracker = (category = "Games") => {
    const eventTracker = (action = "Test Action", label = "test label") => {
        ReactGA.event({category, action, label})
    }

    return eventTracker;
}


export default useAnalyticsEventTracker 