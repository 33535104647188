import React, { useState } from 'react'
import useMeasure from 'react-use-measure'
import { useTrail, animated } from 'react-spring'//'@react-spring/web'
import coin from '../../assets/images/coin.png'
import styles from './styles.module.css'

const fast = { tension: 200, friction: 40 }
const slow = { mass: 10, tension: 200, friction: 50 }
const trans = (x, y) =>
  `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`

export default function FluidBox(props) {
  //console.log("START", props.start)
  const [trail, api] = useTrail(3, i => ({
   /*  xy: [0, 0],
    opacity:0, */
    config: fast,//i === 0 ? fast : slow,
    duration:1000,
    from:{xy: props.start? [...props.start] : [-50, -50], opacity:0},
    onRest:()=>console.log("end")
  }))
  const [ref, { left, top }] = useMeasure();

  const [isInit, setIsInit] = useState(false)

  const handleMouseMove = e => {
    //console.log("POS",{ xy: [e.clientX - left, e.clientY - top],left,top} )
    //api.start({from:{xy: [0, 0], opacity:0}, to:[{ xy: [e.clientX - left, e.clientY - top] , opacity: 1},{opacity:0}]})
  }

  React.useEffect(()=>
  {
    if(isInit)
    {

      console.log("POSS",[props.end[0]/2,props.end[1]/2])
      //api.start({from:{xy: [...props.start], opacity:0}, to:[{ xy: [(props.end[0]+props.start[0])/2,(props.end[1]+props.start[1])/2] , opacity: 1},{ xy: [...props.end] , opacity: 0}]})
      api.start({from:{xy: [...props.start], opacity:0}, to:[{ xy: [...props.end] , opacity: 1}, {opacity: 0}]})
      
    }

    setIsInit(true);
     
  }, [props.show])


  return (
    <div  ref={ref}  style={{position:'absolute', top:0, right:0, bottom:0, left:0, /* width:'100vw', height:'100vh',  background:'rgba(100,100,100,0.2)'*/}}>
      <svg style={{ position: 'absolute', width: 0, height: 0 }}>
        <filter id="goo">
          {/* <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="30" />
          <feColorMatrix
            in="blur"
            values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 30 -7"
          /> */}
        </filter>
      </svg>
      <div className={styles.hooksMain} onMouseDown={handleMouseMove}
      //onMouseMove={handleMouseMove}
      >
        {props.children}
        {trail.map((props, index) => (
          <animated.div key={index} style={{transform: props.xy.to(trans), opacity: props.opacity.to(x=>x) }}>
            <img width={36} src={coin}/>  
            </animated.div>
        ))}
      </div>
    </div>
  )
}
