import React, {useState} from 'react';
import {useSpring, useSpringRef, useChain, config, animated as a} from 'react-spring';
//import { Howl, Howler } from 'howler';
import styled from 'styled-components';

import useMeasure from 'react-use-measure';

import returnIcon from './images/return-icon.png';

import backspaceIcon from './images/backspace-icon.png'

import arrowUpIcon from './images/arrow-up-icon.png'

import arrowUpGreyIcon from './images/arrow-up-grey-icon.png'

 

const ViewBoxCell = styled.div`

padding: 6px;
background: rgb(232, 253, 232);
margin: 2px;
border-radius:3px;
width: 8%;
font-weight: bold;
color: #078b72;
box-shadow: rgb(10 10 10 / 40%) 1px 1px 2px;
border-bottom: 2px solid green;
aspect-ratio: 1 / 1;
display:flex;
justify-content:center;
align-items:center;
box-sizing:border-box; 

`;

 

const TextWrapper = (props) => {


  const [fontSize, setFontSize] = useState([]);

  const factor = props.children.length > 2 ? props.children.length -2 : 2; 

  const [cellRef, bounds] = useMeasure(); 

  React.useEffect(()=>{

     let width  = bounds.width;
     setFontSize(Math.sqrt(width*width/factor))

  },[bounds])

 

  return(
      <ViewBoxCell ref={cellRef} onClick={props.onClick} style={{...props.style, width:props.width ? props.width : 'auto', fontSize}}>{props.children}</ViewBoxCell>
  )
}

 

const KeyboardInput = ({onChange, onKeyPress, isUpperCase}) => {

  const [isCaps, setIsCaps] = useState(false);

  const letters =  {

    row0:[
      //"abcdefghijklmpopqrstuvwxyz"
      "1234567890"
    ],

    row1:[
      //"abcdefghijklmpopqrstuvwxyz"
      "qwertyuiop"
    ],

    row2:[
      "asdfghjkl;"
    ],

    row3:[
      "zxcvbnm,."
    ],

    row4:[
      "$#@%&!?"
    ],

  }

 

  const handleClick = (character) => {

    onKeyPress && onKeyPress(character);

  }

 

  return (<div style={{display:'flex', flexDirection:'column', width:'95%'}}>

     <div style={{display:'flex', flexWrap:'nowrap', position:'relative', background:'none', width:'100%', flexDirection:'row'}}>

      {letters.row0[0].split('').map(l => isCaps ? l.toLocaleUpperCase() : l.toLocaleLowerCase()).map((l,i,arr) => <TextWrapper onClick={()=>handleClick(l)} width={`${100/(11)}%`} style={{width:'9%'}}>{l}</TextWrapper>)}   

    </div>

    <div style={{display:'flex', flexWrap:'nowrap', position:'relative', background:'none', width:'100%', flexDirection:'row'}}>

      {letters.row1[0].split('').map(l => isCaps ? l.toLocaleUpperCase() : l.toLocaleLowerCase()).map((l,i,arr) => <TextWrapper onClick={()=>handleClick(l)} width={`${100/(11)}%`} style={{width:'9%'}}>{l}</TextWrapper>)}

   

    </div>

    <div style={{display:'flex', flexWrap:'nowrap', position:'relative', background:'none', width:'100%', flexDirection:'row'}}>

      {letters.row2[0].split('').map(l => isCaps ? l.toLocaleUpperCase() : l.toLocaleLowerCase()).map((l,i,arr) => <TextWrapper onClick={()=>handleClick(l)} width={`${100/(11)}%`} style={{width:'9%'}}>{l}</TextWrapper>)}
 
    </div>

    <div style={{display:'flex', flexWrap:'nowrap', position:'relative', background:'none', width:'100%', flexDirection:'row'}}>

      {letters.row3[0].split('').map(l => isCaps ? l.toLocaleUpperCase() : l.toLocaleLowerCase()).map((l,i,arr) => <TextWrapper onClick={()=>handleClick(l)} width={`${100/(11)}%`} style={{}}>{l}</TextWrapper>)}

      <ViewBoxCell  onClick={()=>handleClick("BK_SPC")} style={{aspectRatio:'1',width:`${100/(11)}%`}} width={`${100/(11)}%`}><div style={{display:'flex'}}><img width={'100%'} src={backspaceIcon}/></div></ViewBoxCell>

    </div>

    <div style={{display:'flex', flexWrap:'nowrap', position:'relative', justifyContent:'center', background:'none', width:'100%', flexDirection:'row'}}>

      <ViewBoxCell style={{aspectRatio:'1', visibility:'hidden', width:`${100/(11)}%`}} width={`${100/(11)}%`}>{'A'}</ViewBoxCell>

      <ViewBoxCell onClick={()=>setIsCaps(s => !s)} style={{aspectRatio:'1',width:`${100/(11)}%`}} width={`${100/(11)}%`}><div style={{display:'flex'}}><img width={'100%'} src={isCaps ? arrowUpIcon : arrowUpGreyIcon}/></div></ViewBoxCell>
 
     {/*  {("AB").split('').map((l,i,arr) => <TextWrapper onClick={()=>handleClick(l)} width={`${100/(11)}%`} style={{width:'9%'}}>{l}</TextWrapper>)}
 */}
      <ViewBoxCell  onClick={()=>handleClick("SPACE")} style={{aspectRatio:'auto', width:'40%'}} width={'50%'}>{''}</ViewBoxCell>

      {/* {("AB").split('').map((l,i,arr) => <TextWrapper onClick={()=>handleClick(l)} width={`${100/(11)}%`} style={{width:'9%'}}>{l}</TextWrapper>)}
 */}
      <ViewBoxCell style={{aspectRatio:'1',width:`${100/(11)}%`, background:'#ffe9ab'}}  onClick={()=>handleClick("ENTER")} width={`${100/(11)}%`}><div style={{display:'flex'}}><img width={'90%'} src={returnIcon}/></div></ViewBoxCell>
      <ViewBoxCell style={{aspectRatio:'1', visibility:'hidden', width:`${100/(11)}%`}} width={`${100/(11)}%`}>{'A'}</ViewBoxCell>

    </div>

   {/*  <div style={{display:'flex', flexWrap:'wrap', background:'white', flexDirection:'row'}}>

      {letters.row2[0].split('').map(l => <TextWrapper onClick={()=>handleClick(l)} width={'3%'}>{l}</TextWrapper>)}

   

      {letters.row1[0].split('').map(l => <TextWrapper width={'6%'}>{l}</TextWrapper>)}

   

      {letters.row3[0].split('').map(l => <div style={{padding:10, background:'#e8fde8', margin:5, width:'6%', fontWeight:'bold', aspectRatio:'1', color:'green', boxShadow:'1px 1px 2px rgba(10,10,10,0.4)', borderBottom:'2px solid green'}}>{l}</div>)}

      <ViewBoxCell width={'12%'}>{''}</ViewBoxCell>

    </div> */}

  </div>

   

  )

}

export default KeyboardInput;
/* 
function App() {
 

  const leftSlideRef = useSpringRef()

  const rightSlideRef = useSpringRef()

 

  const slideInLeft = useSpring({

    from: {x: '-100%', opacity:0},

    to:{x:'0%', opacity:1},

    config:config.molasses,

    ref: leftSlideRef

  })

 

  const slideInRight = useSpring({

    from: {x: '100%', opacity:0},

    to:{x:'0%', opacity:1},

    config:config.molasses,

    ref:rightSlideRef

  })

 

  const slideInTop = useSpring({

    from: {y: '-100%', opacity:0},

    to:{y:'0%', opacity:1},

    config:config.molasses

  })

 

  const slideInBottom = useSpring({

    from: {y: '100%', opacity:0},

    to:{y:'0%', opacity:1},

    config:config.molasses

  })

 

  useChain([rightSlideRef, leftSlideRef], [0, 0.5])

 

  const [{ x, y }, api] = useSpring(() => ({ from:{ x: 236.8, y: -341.333} }))

  const bind = useDrag(({ offset: [x, y] }) => api.start({ to:{ x, y }}))

 

 

 

  return (

    <div className="App">

      <header className="App-header">

        <div style={{height:50, width:50, background:'red', position:'relative'}}>

        <a.img src={logo} style={{top:0, left:0, position:'absolute',...slideInLeft}} alt="logo" />

        <a.img src={logo} style={{top:0, left:0, position:'absolute',...slideInTop}} alt="logo" />

        <a.img src={logo} style={{top:0, left:0,position:'absolute',...slideInRight}} alt="logo" />

        <a.img src={logo} style={{top:0, left:0,position:'absolute',...slideInBottom}} alt="logo" />

        </div>

       

        <div style={{width:'95%', overflowY:'auto'}}>

        <KeyboardInput onKeyPress={(l)=>console.log("Pressed", l)}/>

        </div>

        <p>

          Edit <code>src/App.js</code> and save to reload.

        </p>

      

        <a.div {...bind()}  style={{width:100, position:'absolute', height:100, background:"green", x, y }} />

        <a

          className="App-link"

          href="https://reactjs.org"

          target="_blank"

          rel="noopener noreferrer"
        >
          Learn React

        </a>

      </header>

    </div>

  );

}
 */
 
