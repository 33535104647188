import React from 'react';
import Modal from '../Modal/Modal'

const Prompt = (props) => {

    const modalButtons = [
        {
            label: props.OkLabel || "Ok",
            action: props.promptOptions && props.promptOptions.OkAction 
        },
        {
            label: props.CancelLabel || "Cancel",
            action: props.CancelAction || props.modalClosed
        }
    ]

    return (
        <Modal title={props.title || "Prompt"}  showCloseButton={false} modalButtons={modalButtons} isOpen={props.isOpen} modalClosed={props.modalClosed}>
            <div style={{padding:10}}>{props.promptOptions.message}</div>
        </Modal>
    )
}

export default Prompt;