import React, { useState } from 'react';
import './SideMenu.css';
import {
    Link,
    useLocation
} from 'react-router-dom';

import {MenuContext} from './SideMenuContext';
/* import { useSelector } from 'react-redux';
import {signOutUser} from '../../services/user.services';
import { useDispatch } from 'react-redux' */

const mainMenuItemsDef = [
    {
        menuLink:'/all-games',
        name:"Games",
        key:"all-games",
        isCollapsed:true
    },  
    {
        menuLink:'/train',
        name:"Train",
        key:"train",
        isCollapsed:true
    },
    {
        menuLink:'/user-statistics',
        name:"Statistics",
        key:"user-statistics",
        isCollapsed:true
    },
    {
        menuLink:'/settings',
        name:"Settings",
        key:"settings",
        isCollapsed:true
    },
    {
        menuLink:'/help',
        name:"Help",
        key:"help",
        isCollapsed:true
    },
    {
        menuLink:'https://forms.gle/rDM5ZfnR3k5U9rJo8',
        name:"Feedback",
        key:"feedback",
        isCollapsed:true,
        isExternal:true
    },
    
]

const subMenuItems = [];


const SideMenu = (props) =>
{
    //const user = useSelector(state => state.user);
    //const dispatch = useDispatch();
    const location = useLocation();

    const [mainMenuItems, setMainMenuItems] = useState([...mainMenuItemsDef])

    const [menuSearchString, setMenuSearchString] = useState("")
    
    const filterMenuItems = (menuItems) => {
        if(menuSearchString && menuSearchString != "")
        {
            let filteredMenuItems = menuItems.filter( mi => {
                if(mi.name.toLowerCase().match(menuSearchString.toLowerCase()))
                {
                    return true;
                }
                else
                {
                    // Search the submenus
                    let foundSubMenuItems = subMenuItems.filter(sm => sm.parentKey === mi.key && sm.menuCaption.toLowerCase().match(menuSearchString.toLowerCase()))

                    if(foundSubMenuItems && foundSubMenuItems.length>0)
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }
            })

            return filteredMenuItems.map(fm => {return {...fm, isCollapsed:false}});
        }
        else
        {
            return menuItems
        }
    }
    
    const collapseMenuItem = (key) =>{
        let selectedMenuItem = mainMenuItems.find(mi => mi.key === key);

        if(selectedMenuItem)
        {
            selectedMenuItem.isCollapsed = !selectedMenuItem.isCollapsed;

            setMainMenuItems([...mainMenuItems.map(
                (m, i) => m.key === selectedMenuItem.key ? selectedMenuItem
                                        : m)])
        }
    }

    const isCurrentLink = (menuItem) => {
        // Search the submenus
        let foundSubMenuItems = subMenuItems.filter(sm => sm.menuLink === location.pathname && menuItem.key === sm.parentKey)

        if(foundSubMenuItems && foundSubMenuItems.length>0)
        {
            return true
        }
        else
        {
            return false;
        }
    }

    return (
        <MenuContext.Consumer>
        {({isOpen, setIsOpen}) => (
        <>
        <div  onClick={()=> setIsOpen(false)}  className={`side-menu-backdrop ${isOpen?'side-menu-backdrop-show':'side-menu-backdrop-hide'}`}></div>
       
        
        <div style={{overflowY:'auto', background:'linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%)', background:'rgba(20,20,20,0.5)', background:'white',/* boxShadow:'inset 20px 2px 0px #a7faba' */}} className={`side-menu-container ${isOpen?'side-menu-open':'side-menu-close'}`}>

        <div style={{margin:'0px', display:'flex', flexDirection:'column', width:'100%', justifyContent:'left'}}>
        {/*<div style={{display:'flex', flexDirection:'column', padding:8, paddingBottom:2, margin:'0px 0px', alignItems:'center', justifyContent:'center'}}>
            <input placeholder="Search" value={menuSearchString} onChange={(e)=>setMenuSearchString(e.target.value)} style={{boxSizing:'border-box', boxShadow:'0px 0px 2px rgba(10,10,10,0.2)', background:"#eeeeee", padding:'2px 10px', height:30, border:'1px solid #CCCCCC', width:'100%', borderRadius:5}}/>
        </div>*/}
        {
            filterMenuItems(mainMenuItems).map(ss => <div key={ss.key} style={{marginTop:0}}>
                {ss.menuLink ? 
                ss.isExternal ?  
                <a href={ss.menuLink} className={`side-menu-category-label ${ss.menuLink === location.pathname || isCurrentLink(ss)? 'side-menu-category-label-active' : 'side-menu-category-label-inactive'}`}><span className={`side-menu-category-label`}>{ss.name}</span></a>
                
                :
                <Link to={ss.menuLink} className={`side-menu-category-label ${ss.menuLink === location.pathname || isCurrentLink(ss)? 'side-menu-category-label-active' : 'side-menu-category-label-inactive'}`}><span className={`side-menu-category-label`}>{ss.name}</span></Link> :
                <div className={'side-menu-category-label-inactive side-menu-category-label'}>{ss.name}</div>}
                <ul className={'side-menu-links-container'}>
                {!ss.isCollapsed && subMenuItems.filter(s => s.parentKey === ss.key).map(sm => <li key={sm.menuLink} className={'side-menu-links-item'}><div>
                
                    <Link className={'side-menu-container-link'} style={{fontWeight:`${sm.menuLink === location.pathname ? 'bold':'normal' }`}} to={sm.menuLink}>{sm.menuCaption}</Link>
                    </div></li>)}
                </ul>
                </div>)
        }
        {/*user? <div style={{cursor:'pointer'}} onClick={(e)=>{e.preventDefault(); signOutUser(dispatch)}} className={'side-menu-category-label-inactive side-menu-category-label'}>Logout</div>:<Link className={'side-menu-category-label'} to={'/login'}>Login</Link>*/}
        </div>
        </div></>)}
        </MenuContext.Consumer>)
}

export default SideMenu;