import React from 'react'
import {useSpring, animated, config}from 'react-spring'
 
const FlashNotification = (props) => {

  const [{angle, size}, api] = useSpring(()=>({from:{angle:-280, size: 0.5}, config:config.wobbly}))
 
  React.useEffect(()=>{

    api.start({to:[{angle:0, size:1},{angle:280, size:0.5}]})
  

  },[])


  return (<div style={{
    position:'relative',
    width:props.size || 180,
    height:props.size || 180}}>

    <animated.div style={{border:'2px solid grey',
        borderColor:`transparent ${props.color ? props.color : "#86ef86"}`,        
        position:'absolute',
        height:size.to(s => `${100*s}%`),
        width:size.to(s => `${100*s}%`),
        margin:'auto',
        left:'50%',
        top:'50%',
        borderRadius:'50%',
        transform:angle.to(a=>`translateX(-50%) translateY(-50%) rotate(${a}deg)`)}}>

    </animated.div> 

    <animated.div style={{border:'2px solid grey',
        borderColor:`${props.color ? props.color : "#86ef86"} transparent`,
        position:'absolute',
        height:size.to(s => `${90*s}%`),
        width:size.to(s => `${90*s}%`),
        margin:'auto',        
        left:'50%',
        top:'50%',
        borderRadius:'50%',
        transform:angle.to(a=>`translateX(-50%) translateY(-50%) rotate(${a}deg)`)}}>

    </animated.div>

 

    <animated.div style={{border:'2px solid grey',
        borderColor:'transparent grey',
        position:'absolute',
        height:size.to(s => `${80*s}%`),
        width:size.to(s => `${80*s}%`),
        margin:'auto',
        //transformOrigin:'0 -100px',
        //tansform:'translateX(50%) translateY(50%)',
        left:'50%',
        top:'50%',
        borderRadius:'50%',
        transform:angle.to(a=>`translateX(-50%) translateY(-50%) rotate(${-a}deg)`)}}>       

    </animated.div>

    <animated.div style={{border:'2px solid grey',
        borderColor:`${props.color ? props.color : "green"} transparent`,
        position:'absolute',
        height:size.to(s => `${70*s}%`),
        width:size.to(s => `${70*s}%`),
        margin:'auto',
        //transformOrigin:'0 -100px',
        //tansform:'translateX(50%) translateY(50%)',
        left:'50%',
        top:'50%',
        borderRadius:'50%',
        transform:angle.to(a=>`translateX(-50%) translateY(-50%) rotate(${a}deg)`)}}>       

    </animated.div>

    <animated.div style={{border:'0px solid red',
        background: props.color ? props.color : '#86ef86',
        position:'absolute',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height:'60%',
        width:'60%',
        margin:'auto',
        //transformOrigin:'0 -100px',
        transform:'translateX(-50%) translateY(-50%)',
        left:'50%',
        top:'50%',
        borderRadius:'50%',
        //</div>transform:angle.to(a=>`translateX(-50%) translateY(-50%) rotate(${a}deg)`)

      }}>
        {props.children}
    </animated.div>

  </div>

   

  )

}


export default FlashNotification;