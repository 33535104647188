import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import Logo1  from '../assets/images/brain-trainer-logo-large.svg';
import backgroundImage from '../assets/images/background_01.svg';
import DropIns from '../games/components/DropIns/DropIns';
import {animated, config, useSpring} from 'react-spring';
import { useSelector, useDispatch} from 'react-redux';

const SetupView = (props) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const viewStages = ["name", "gender", "age"]

    const [currentViewStage, setCurrentViewStage] = useState(0);

    const [{x, opacity}, api] = useSpring(()=>({from:{x:-60, opacity:0}, config:config.wobbly}))

    const user = useSelector( state => state.user);

    useEffect(()=>
    {
        api.start(()=>({from:{x:-60, opacity:0},to:{x:0, opacity:1}}))
    },[currentViewStage])

    const updateUserField = (e) => {
        const {name, value} = e.target;
        dispatch({type:"UPDATE_USER_INFO", data:{[name]:value, sessionTime:new Date().toISOString()}});    

    }

    const gotToNext = () => {

        if(currentViewStage >= 2)
        {
            navigate("/all-games")
        }
        else
        {
            setCurrentViewStage(c => c + 1)
        }

    }

    const goToBack = () => {

        if(currentViewStage == 0)
        {
            navigate("/")
        }
        else
        {
            setCurrentViewStage(c => c - 1)
        }

    }
    
        

    return(
        <div style={{display:'flex',
         justifyContent:'space-between',
         padding:30,
         alignItems:'center',
         position:'absolute', 
         textAlign:'center',
         flexDirection:'column',
         background:'linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%)',
         background:`url('${backgroundImage}')`,
         //background:`white`,
         backdropFilter:'greyscale(80%)',
         backgroundRepeat:'no-repeat',
         backgroundSize:'cover',
         height:'100vh', width:'100vw'}}>
            <div style={{
                width:'100%',
                fontSize:20,
                color:'#108c54',
                display:'flex',
                justifyContent:'space-between'
            }}>
                <span onClick={()=>goToBack()} style={{
                    cursor:'pointer',
                    borderBottom:'1px dotted green',
                    padding:5
                }}>Back</span>

                <span onClick={()=>gotToNext()} style={{
                    cursor:'pointer',
                    borderBottom:'1px dotted green',
                    padding:5
                }}>Next</span>
            </div>
                          
            <div style={{position:'relative', width:100, height:100}}>                
                <animated.img width={100} style={{position:'absolute',left:x, opacity}} src={Logo1}/>
            </div>
           
           <div style={{color:'#0f5a4d', fontSize:28, maxWidth:250}}  >
                <div>What's your</div>
                {currentViewStage == 0 && <div>Name?</div>}
                {currentViewStage == 1 && <div>Gender?</div>}
                {currentViewStage == 2 && <div>Age?</div>}
               
            </div>
            <animated.div style={{ position:'relative', top:x, opacity,}} >
                
                
            {currentViewStage == 0 && <input className='primary-text-input'
                name={'name'}
                value={user?.name}
                onChange={updateUserField}/>}
            {currentViewStage == 1 && <input className='primary-text-input'
                name={'gender'}
                value={user?.gender}
                onChange={updateUserField}/>}
            {currentViewStage == 2 && <select className='primary-text-input'
                name={'age'}
                value={user?.age}
                onChange={updateUserField}>                    
                    <option>Less than 10</option> 
                    <option>10 - 18</option> 
                    <option>19 - 25</option> 
                    <option>26 - 35</option> 
                    <option>36 - 55</option> 
                    <option>over 55</option>   
                </select>}
                
            </animated.div>
            <div>
                <button onClick={()=>navigate('/all-games')} style={{fontFamily:'Righteous', boxShadow:'0px 1px 0px 1px rgb(26 108 140)'}} className='start-button'>{currentViewStage == 2 ? "START" :"SKIP"}</button>
                <div style={{color:'#0f5a4d',bottom:-15, position:'relative', fontSize:12, maxWidth:250}} >
                    <p>
                    Brain Trainer 2022
                    </p>
                </div>
            </div>
           
        {/* <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
         */}</div>
        )
}

export default SetupView;//LayoutWrapper(SplashScreen, {showNavigationBar:false, showSideMenu:false});