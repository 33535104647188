import React from 'react';
import FluidBox from '../../components/FluidBox/FluidBox';
import FlashCards from '../FlashCards';
import MatrixPattern from '../MatrixPattern/MatrixPattern';
import MatchingMatrix from '../MatchingMatrix/MatchingMatrix';
import MemoryJogger from '../MemoryJogger/MemoryJogger';
import PatternRecognition from '../PatternRecognition/PatternRecognition';
import TicTacToe from '../TicTacToe/TicTacToe';
import CharactersInGrid from '../CharactersInGrid/CharactersInGrid';
import Arithmetic from '../Arithmetic/Arithmetic';
import Fractions from '../Fractions/Fractions';
import {v4 as uuidv4} from 'uuid';
import WordGame from '../WordGame/WordGame';
import WordGame2 from '../WordGame/WordGame2';
//import CustomStake from '../CustomStake/CustomStake';
//import TicTacToe from '../TicTacToe/TicTacToe';
//import Whot from '../Whot/Whot';

const GameInfo = (props) => {

}

const GameSelector = (props) => 
{    
    console.log(props.gameCode)

    const sessionId = uuidv4();

    switch(props.gameCode)
    {
        case "recall-the-digits" : //quick-peek
            return <MemoryJogger sessionId={sessionId} {...props}/>;
        case "arithmetic-speed" :
            return <Arithmetic sessionId={sessionId} {...props}/>;
        case "tic-tac-toe" : //quick-peek
            return <TicTacToe sessionId={sessionId} {...props}/>;
        case "matrix-pattern" : //quick-peek
            return <MatrixPattern sessionId={sessionId} {...props}/>;
        case "matching-matrix" : 
            return <MatchingMatrix sessionId={sessionId} {...props}/>;
        case "find-the-characters":
            return <CharactersInGrid sessionId={sessionId} {...props}/>;
        case "spot-the-circles" : //spot-it
            return <PatternRecognition sessionId={sessionId} {...props}/>;
        case "fraction-comparison" :
            return <Fractions sessionId={sessionId} {...props}/>
        case "word-joggler-2" :
            return <WordGame2 sessionId={sessionId} {...props}/>
        default:
            return <WordGame sessionId={sessionId} {...props}/>
        

    }
}

export default GameSelector;