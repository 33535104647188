import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  useSpringRef,
  animated,
  useTransition,
  useSpring,
  config,
} from 'react-spring';
import coin from '../../assets/images/coin.png'
import ok_button from '../../assets/images/ok-button.svg'
import FlipCard from '../components/FlipCard/FlipCard';
import CountDownTicker from '../components/CountDownTicker/CountDownTicker';
import FluidBox from '../../components/FluidBox/FluidBox';
import useMeasure from 'react-use-measure';
import { useTimerCount } from '../hooks/timerCount';
import {v4 as uuidv4} from 'uuid';
import { TimerBox, ScoreBox } from '../components/InfoComponents/infoComponents';

import {useDispatch, useSelector} from 'react-redux';
import KeyboardInput from '../../components/KeyboardInput/KeyboardInput';


const MemoryJogger = (props) => {

    const [flipped, setFlipped] = useState(false);

    const dispatch = useDispatch();
    
    const [isShowingPickedItems, setIsShowingPickingCard] = useState(true);

    const [days, hours, minutes, seconds] = useTimerCount(new Date());

    const [isLoadingNext, setIsLoadingNext] = useState(false);
    
    const [entry, setEntry] = useState("");
  
    const [points, setPoints] = useState(0);
  
    const [currentOption, setCurrentOption] = useState(null);

    const tref = React.useRef(null);

    const [startRef, { left:startLeft, width:sWidth, top:startTop, height:sHeight }] = useMeasure()
  
    const [endRef, { left:endLeft, width:eWidth, top:endTop, height:eHeight }] = useMeasure()
  
    const [toggle, setToggle] = React.useState(false)
  
    const [offset, setOffset] = React.useState({x:0,y:0})
  
   
   /*  useEffect(()=>{
      
      console.log(currentOption)
  
    },[currentOption]) */
  
    const gamePlayOptions = useSelector(state => state.gamePlayOptions)

    const [selectedGamePlayOptions, setSelectedGamePlayOptions] = useState(null);

    React.useEffect(()=>{
      setCurrentOption({value:generateCharacters()})
    },[selectedGamePlayOptions])

    React.useEffect(()=>{

        //if(selectedGame)
        {
            let gameOptions = gamePlayOptions.find(g => g.gameCode === props.gameCode);

            if(gameOptions)
            {
                setSelectedGamePlayOptions({...gameOptions})
            }
            else
            {
                setSelectedGamePlayOptions({
                    gameCode:props.gameCode,
                    isAudioOn:false,
                    difficulty: 1
                }) 
            }
        }

        //setCurrentOption({value:generateCharacters()})

    },[gamePlayOptions])

    const generateCharacters = () =>
    {
      let length = 6;

      if(selectedGamePlayOptions)
      {
        length = 2 + (2*selectedGamePlayOptions.difficulty)
      }
      
      return uuidv4().substring(0,length).replace("-","");
    }

    const checkEntry = () => { 
      
      console.log({c:currentOption.value, p: points, e: entry});
  
      if(entry == currentOption.value)
      {
        setPoints(p => p+1);
        setToggle(t => !t);
      }

     /*  let length = 6;

      if(selectedGamePlayOptions)
      {
        length = 2 + (2*selectedGamePlayOptions.difficulty)
      }
       */
      //console.log({length, d:uuidv4().substring(0,length).replace("-","")})
      setCurrentOption({value:generateCharacters()})
      setEntry("")
      setIsLoadingNext(false)//s => !s)
      setFlipped(false)//f => !f)
      
  
    }
  
    const handleChange = (e) =>
    {
      const {value} = e.target;
  
      setEntry(value);
    }

    const handleKeyboardChange = (val) =>
    {       
      

      if(val == "BK_SPC")
      {
        setEntry(ev => ev &&  ev.length > 1 ? ev.slice(0,ev.length-1): "");
      }
      else if(val === "SPACE")
      {
        setEntry(ev => ev + " ");
      }
      else if(val === "OK" || val === "ENTER")
      {
        checkEntry();
      }
      else
      {
        setEntry(ev => ev + val);
      }
    }

    React.useLayoutEffect(()=>{
      const {x, y} = tref.current.getBoundingClientRect();
      console.log("OFFSET",{x,y, el:endLeft, et:endTop,eh: eHeight})
      setOffset({x, y})
    },[])

    useEffect(()=>{
      props.onSessionPointChange && props.onSessionPointChange(points)
      dispatch({type:"UPDATE_GAME_SCORE", data:{gameCode:props.gameCode, sessionTime:new Date().toISOString(), sessionId: props.sessionId, points:points}});
    },[points])
  
    return(
      <FluidBox start={[startLeft + sWidth/2-offset.x, startTop+sHeight/2 -offset.y]} show={toggle} end={[endLeft + eWidth/2-offset.x, endTop + eHeight/2-offset.y]}>
      <div  ref={tref} style={{
        //width:'100vw',
        //height:'100vh',
        height:'100%', width:'100%', position:'absolute',
        //background:'rgb(245, 226, 214)',
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
      }}>
  
      <div className={'game-play-info-header'}>      
        {!props.hideGameTime && <TimerBox/>}       
      
        <ScoreBox isAudioOn={selectedGamePlayOptions?.isAudioOn} points={points} ref={endRef} />      
      </div>
  
      <div style={{
        width:'100%',
        //flexGrow:1,
        position:'relative',
        //background:'#EEEEEE',
        padding:5,
        height:80,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
      }}>
        {/* <CountDownBox duration={10} onEnd={()=>setFlipped(f => !f)}/> */}
        {flipped == true ? <CountDownTicker key={1 + isLoadingNext && flipped} refreshHash={flipped} duration={12} 
        //onEnd={()=>setIsShowingPickingCard(f => !f)}
        onEnd={()=>checkEntry()}
        />
        : <CountDownTicker key={2 + isLoadingNext&&flipped} refreshHash={!flipped} duration={3} onEnd={()=>setFlipped(true)}/>
        }
      </div>
  
      <div style={{
        width:'100%',
        flexGrow:1,
        //background:'#EEEEEE',
        padding:20,
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexDirection:'column',
  
      }}>
      
  
        <div style={{
          display:'flex', 
          justifyContent:'center', 
          //flexDirection:'column',
          width:'100%',
          alignItems:'center', 
        }}> 
        {[1].map(i => <div style={{ display:'flex', 
          justifyContent:'center', 
          width:'90%',
          border:0,
          alignItems:'center', margin:5}}><FlipCard flipped={!flipped} front={<div style={{ display:'flex', 
          justifyContent:'center', 
          //flexDirection:'column',
          background:'#CCCCCC',
          background:'linear-gradient(150deg, rgba(251,251,251,1) 0%, rgba(237,233,212,1) 100%)',
          boxShadow:'2px 2px 2px rgba(20, 20, 20, 0.4)',
          fontSize:30,
          letterSpacing:10,
          fontWeight:'bold',
          alignItems:'center', padding:'15px 25px',
          color:'orange',
          //width:80,
          borderRadius:10, margin:5}}>{currentOption && currentOption.value}</div>}
          back={
            <input style={{ display:'flex', 
          justifyContent:'center', 
          //flexDirection:'column',
          textAlign:'center',
          boxSizing:'border-box',
          background:'#CCCCCC',
          background:'linear-gradient(150deg, rgba(251,251,251,1) 100%, rgba(237,237,212,1) 0%)',
          boxShadow:'2px 2px 2px rgba(20, 20, 20, 0.4)',
          fontSize:30,
          border:0,
          letterSpacing:10,
          fontWeight:'bold',
          alignItems:'center', padding:'15px 25px',
          outline:'none',
          color:'green',
          width:'100%',
          borderBottom:'2px solid #ff9900',
          borderRadius:10, margin:5}} value={entry} onChange={handleChange} />
          }/>
          </div>
  
          )}
  
        </div>
  
        
      
  
      </div>

      <div ref={startRef} style={{
        width:'100%',
        flexGrow:1,
        maxWidth:400,
        //background:'#EEEEEE',
        padding:2,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        visibility: !flipped ? 'hidden' : 'visible',
        
  
      }}>
          {/* <div  ref={startRef} onClick={()=>checkEntry()} style={{width:50, cursor:'pointer', height:50, visibility: !flipped ? 'hidden' : 'visible', borderRadius:'50%', background:'none'}}>
          <img className={'game-ok-button'} src={ok_button}/>  
          </div> */}
         
              <KeyboardInput onKeyPress={handleKeyboardChange}/>
        
          
        </div>
  
      
  
      
  
      </div>
      </FluidBox>
    )
  }

export default MemoryJogger;




