import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import CountDownTicker from '../components/CountDownTicker/CountDownTicker';
import {shiftArrayX, shiftArrayY, flipX, flipY, rotate, shiftX } from '../../utilities/arrayUtilities.js';

import useMeasure from 'react-use-measure';
import { useTimerCount } from '../hooks/timerCount';

import coin from '../../assets/images/coin.png'
import ok_button from '../../assets/images/ok-button.svg'
import FluidBox from '../../components/FluidBox/FluidBox';
import { ScoreBox, TimerBox } from '../components/InfoComponents/infoComponents';
import DropIns from '../components/DropIns/DropIns';

import rotateRight from '../../assets/images/rotate_right.svg';
import rotateLeft from '../../assets/images/rotate_left.svg';
import flipYIcon from '../../assets/images/flip_x_axis.svg';
import flipXIcon from '../../assets/images/flip_y_axis.svg';
import shiftRightIcon from '../../assets/images/move_right.svg';
import shiftUpIcon from '../../assets/images/move_up.svg';
import transformArrow from '../../assets/images/transform-arrow.svg';

import {useDispatch, useSelector} from 'react-redux';
import FlashNotification from '../components/FlashNotification';

console.log(shiftX([1,2,3,4,5,6],2))

console.log("SHIFT_ALL_Y", shiftArrayY([
    [1,2,3],      
    [4,5,6],
    [7,8,9]     
],2))

console.log("SHIFT_ALL_X", shiftArrayX([
    [1,2,3],      
    [4,5,6],
    [7,8,9]     
],2))

console.log("FLIPY", flipY([
    [1,2,3],      
    [4,5,6],
    [7,8,9]     
]))

console.log("FLIPX", flipX([
    [1,2,3],      
    [4,5,6],
    [7,8,9]     
]))

console.log([
    [1,2,3,4],      
    [5,6,7,8],
    [9,10,11,12],
    [13,14,15,16]    
])

console.log(rotate([
    [1,2,3,4],      
    [5,6,7,8],
    [9,10,11,12],
    [13,14,15,16]    
], false))


const getColorFromValue = (value) =>
{
    switch(value)
    {
        case 0 : return "black"
        case 1 : return "red";//linear-gradient(135deg,rgb(0 6 68) 0%,rgb(0 55 255) 100%)"
        case 2 : return "yellow";//linear-gradient(135deg,rgb(136 0 0) 0%,rgb(255 0 0) 100%)"
        default: return "black";//linear-gradient(135deg,rgb(207 238 236) 0%,rgb(253 253 253) 100%)"
    }
}

const BoxCell = styled.div`
    border-radius: 0px;
    display: flex;

    background: ${props => getColorFromValue(props.value) };    
    /* height: 60px; */
    padding: 5px;
    width: 100%;
    aspect-ratio:1/1;
    /* margin:10px; */
    cursor:pointer;
    border:1px solid #222121;
    box-shadow:rgb(100 100 100 / 60%) -2px -2px 3px inset;

    &:hover {
        transform: scale(1.1);
        box-shadow: 1px 1px 10px rgb(153 153 153 / 70%);
      }

`;


const MatchingMatrix = (props) => {

    const dispatch = useDispatch();

    const transforms = ["ROTATE_RIGHT", "ROTATE_LEFT", "FLIP_Y", "FLIP_X", "SHIFT_Y", "SHIFT_X", "NONE"];

    const transformsLabel = {"ROTATE_RIGHT" :"Rotated to the right", 
    "ROTATE_LEFT":"Rotated to the left", 
    "FLIP_Y":"Flipped Vertical",
    "FLIP_X":"Flipped Horizontal", 
    "SHIFT_Y":"Shifted to down", 
    "SHIFT_X":"Shifted to the right",
    "NONE":"No Change"};

    const [state, setState] = useState([
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
    ]);

    const [transformedState, setTransformedState] = useState([
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
    ])

    const [points, setPoints] = useState(0);

    const [transformOption, setTransformOption] = useState(transforms[0]);

    const [flip, setFlip] = useState(false);

    const [questionTransformOption, setQuestionTransformOption] = useState(transforms[0]);

    

    const [isLoadingNext, setIsLoadingNext] = useState(false);

    const tref = React.useRef(null);

    const [startRef, { left:startLeft, width:sWidth, top:startTop, height:sHeight }] = useMeasure()
  
    const [endRef, { left:endLeft, width:eWidth, top:endTop, height:eHeight }] = useMeasure()

    const [toggle, setToggle] = React.useState(false)
  
    const [offset, setOffset] = React.useState({x:0,y:0})

    const [showCorrectNotification, setShowCorrectNotification] = useState(false);
    const [showIncorrectNotification, setShowIncorrectNotification] = useState(false);

    const gamePlayOptions = useSelector(state => state.gamePlayOptions)

    const [selectedGamePlayOptions, setSelectedGamePlayOptions] = useState(null);

    React.useEffect(()=>{

        //if(selectedGame)
        {
            let gameOptions = gamePlayOptions.find(g => g.gameCode === props.gameCode);

            if(gameOptions)
            {
                setSelectedGamePlayOptions({...gameOptions})
            }
            else
            {
                setSelectedGamePlayOptions({
                    gameCode:props.gameCode,
                    isAudioOn:false,
                    difficulty: 1
                }) 
            }
        }

    },[gamePlayOptions])

    React.useLayoutEffect(()=>{
        const {x, y} = tref.current.getBoundingClientRect();
        console.log("OFFSET",{x,y, el:endLeft, et:endTop,eh: eHeight})
        setOffset({x, y})
      },[])

    React.useEffect(()=>{
        initGrid();
    },[])

    const initGrid =()=>
    {
        // 1. Generate the grid.
        const GRID_SIZE = 5;

        let grid = [];

        for(let i = 0; i < GRID_SIZE; i++)
        {
            let row = [];

            for(let j = 0; j < GRID_SIZE; j++)
            {
                row.push(Math.ceil(Math.random()*3))
            }

            grid.push(row);
        }

        setState(grid);

        // 2. Randomly pick the transform
        

        //const questionTransformOption = transforms[Math.floor(Math.random()*transforms.length)]

        const currentTransform = transforms[Math.floor(Math.random()*transforms.length)]

        setTransformOption(currentTransform);

        const questionTransform = transforms[Math.floor(Math.random()*transforms.length)]

        setQuestionTransformOption(questionTransform)

        console.log(currentTransform)

        console.log(questionTransform)

        switch(currentTransform)
        {
            case "ROTATE_RIGHT":
                setTransformedState(rotate(grid, false));
                break;
            case "ROTATE_LEFT":
                setTransformedState(rotate(grid, true));
                break;
            case "FLIP_Y":
                setTransformedState(flipY(grid));
                break;
            case "FLIP_X":
                setTransformedState(flipX(grid));
                    break;
            case "SHIFT_Y":
                setTransformedState(shiftArrayY(grid));
                break;
            case "SHIFT_X":
                setTransformedState(shiftArrayX(grid));
                    break;
            default:
                setTransformedState(grid);
                    break;
        }

       
    }

    useEffect(()=>{
        console.log(flip)
        //setFlip(f => !f)
    },[state])

    const flashCorrectNotification = () => {
        setShowCorrectNotification(true);

        setTimeout(()=>setShowCorrectNotification(false), 1000);
        setToggle(t => !t);
    }


    const flashIncorrectNotification = () => {
        setShowIncorrectNotification(true)
        setTimeout(()=>setShowIncorrectNotification(false), 1000);
    }

    const handleClick = (userOption)=> {

       if((userOption == transformOption))// == userOption )
       {
            console.log("OKAY")
            setPoints(p => p + 2)
            //setToggle(t => !t);
            flashCorrectNotification();
       }
       else
       {
            console.log("NOT OKAY")
            flashIncorrectNotification();
       }

       setIsLoadingNext(true)//s => !s)
       setFlip(f => !f)
       

       initGrid();

    }

    const getNext = () => {
        if(!isLoadingNext)
        {
            setFlip(f => !f);
            setIsLoadingNext(false)
        }

        initGrid()
    }

    useEffect(()=>{

        props.onSessionPointChange && props.onSessionPointChange(points)
          dispatch({type:"UPDATE_GAME_SCORE", data:{gameCode:props.gameCode, sessionTime:new Date().toISOString(), sessionId: props.sessionId, points:points}});
    },[points])

    const buttonStyle = {
        border:'0px dotted black', fontWeight:'bold', 
        margin:10, padding:'15px', borderRadius:10, 
        background:'linear-gradient(135deg,rgb(140 230 224) 0%,rgb(253 253 253) 100%)', 
        background:'#b9ffd9',
        display:'flex', alignItems:'center', justifyContent:'center', 
        fontSize:13, 
        boxShadow:'inset rgb(15 15 15 / 70%) -2px -2px 2px', 
        flexDirection:'column',
        borderRadius:'50%'
    }

    return(

        <FluidBox start={[startLeft + sWidth/2-offset.x, startTop+sHeight/2 -offset.y]} show={toggle} end={[endLeft + eWidth/2-offset.x, endTop + eHeight/2-offset.y]}>
<div  ref={tref} style={{
  height:'100%', width:'100%', position:'absolute',
  background:'linear-gradient(0deg, rgb(255 255 255) 0%, rgb(253, 253, 253) 100%)',
  display:'flex',
  flexDirection:'column',
  userSelect:'none'
}}>

<div className={'game-play-info-header'}>     
    {!props.hideGameTime && <TimerBox/>}     
    
    <ScoreBox isAudioOn={selectedGamePlayOptions?.isAudioOn} points={points} ref={endRef} />      
</div>
            <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center',  justifyContent:'space-around'}}>
                <CountDownTicker key={`${flip}`}  refreshHash={`${flip}`} duration={6} onEnd={()=>getNext()}/>
                <div style={{height:50, fontSize:'1p6x', color:'#343434'}}>
                    {`Pick the transformation`}?
                </div>

                <div style={{display:'flex', justifyContent:'center'}}>
                    <img style={{maxWidth:'40%'}} src={transformArrow}/>
                </div>

                <div style={{display:'flex', width:'95%', maxWidth:500}}> 
                    
                    <div style={{border:'0px dotted black', margin:10, background:'none', display:'flex', alignItems:'center', justifyContent:'center', width:'70%', borderRadius:10, boxShadow:'1px 1px 10px rgb(153 153 153 / 70%)',  aspectRatio:'1/1', flexDirection:'column'}}>
                    {state.map((row, r) => 
                        <div key={r} style={{border:'0px solid black', width:'100%', display:'flex'}}>
                            {row.map((col, c) => <div key={`${r}-${c}`} style={{flexGrow:1,border:'0px dotted #cecece'}}><BoxCell value={col} /></div>)}
                        </div>)}
                    </div>
                    <div style={{border:'0px dotted black', margin:5, background:'none', display:'flex', alignItems:'center', justifyContent:'center', width:'20%', aspectRatio:'1/1', flexDirection:'column'}}>
                    
                    <div onClick={()=>handleClick("SHIFT_Y")} style={{border:'0px dotted black', color:'#ff3b00', fontFamily:'cursive', fontWeight:'bold', margin:5, width:50, height:50, padding:'15px', borderRadius:'50%', background:'linear-gradient(135deg, rgb(230 195 140) 0%, rgb(253, 253, 253) 100%)', display:'flex', alignItems:'center', justifyContent:'center', fontSize:24, boxShadow:'inset rgba(15 15 15 / 70%) 2px 2px 10px', flexDirection:'column'}}>
                        ?
                    </div>
                    </div>
                    <div style={{border:'0px dotted black', margin:10, background:'none', display:'flex', alignItems:'center', justifyContent:'center', width:'70%', borderRadius:10, boxShadow:'1px 1px 10px rgb(153 153 153 / 70%)',  aspectRatio:'1/1', flexDirection:'column'}}>
                    {transformedState.map((row, r) => 
                        <div key={r} style={{border:'0px solid black', width:'100%', display:'flex'}}>
                            {row.map((col, c) => <div key={`${r}-${c}`} style={{flexGrow:1,border:'0px dotted #cecece'}}><BoxCell value={col} /></div>)}
                        </div>)}
                    </div>
                </div>
                <div ref={startRef} data-is-control={true} style={{display:'flex', justifyContent:'center', flexWrap:'wrap', width:'80%'}}> 
                    <div onClick={()=>handleClick("ROTATE_RIGHT")} style={{...buttonStyle}}>
                       
                        <img width={32} src={rotateRight}/>
                    </div>
                    <div onClick={()=>handleClick("ROTATE_LEFT")} style={{...buttonStyle}}>
                        <img width={32} src={rotateLeft}/>
                    </div>
                    <div onClick={()=>handleClick("FLIP_Y")} style={{...buttonStyle}}>
                        <img width={32} src={flipYIcon}/>
                    </div>
                    <div onClick={()=>handleClick("FLIP_X")} style={{...buttonStyle}}>
                        <img width={32} src={flipXIcon}/>
                    </div>
                    <div onClick={()=>handleClick("SHIFT_X")} style={{...buttonStyle}}>
                        <img width={32} src={shiftRightIcon}/>
                    </div>
                    <div onClick={()=>handleClick("SHIFT_Y")} style={{...buttonStyle}}>
                        <img width={32} src={shiftUpIcon}/>
                    </div>
                    <div style={{display:'flex', alignItems:'center' }}>
                        <div onClick={()=>handleClick("NONE")} style={{ margin:10,
                        background:'#96fbc4',
                        borderRadius:20,
                        fontSize:16,
                        color:'#003c36',
                        border:0,
                        padding:'10px 25px',
                        boxShadow:'2px 2px 2px rgba(20,20,20,0.5)'}}>
                        No Change
                        </div>                        
                    </div>
                    
                </div>
            </div>
           
            {showCorrectNotification && <div style={{fontSize:30,
                 position:'absolute', height:'100%', width:'100%', 
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
                 flexDirection:'column',
                 background:'rgba(20,20,20,0.9)', color:'#9cef9c', fontWeight:'normal'}}>
                <FlashNotification size={260}><span style={{fontSize:30, color:'#065806',fontWeight:'normal'}}>Correct!</span></FlashNotification>              
               
               
            </div>}    

            {showIncorrectNotification && <div style={{fontSize:30,
                 position:'absolute', height:'100%', width:'100%', 
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
                 flexDirection:'column',
                 background:'rgba(20,20,20,0.9)', color:'#9cef9c', fontWeight:'normal'}}>
                <FlashNotification color={'red'} size={260}><span style={{fontSize:30, color:'#FFFFFF',fontWeight:'normal'}}>Incorrect!</span></FlashNotification>              
               
               
            </div>}    

            </div>
</FluidBox>
        
    )
}

export default MatchingMatrix;