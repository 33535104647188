let user = JSON.parse(localStorage.getItem('user'));
let gamePlayData = JSON.parse(localStorage.getItem('gamePlayData'));
let trainingData = JSON.parse(localStorage.getItem('trainingData'));
let gamePlayOptions = JSON.parse(localStorage.getItem('gamePlayOptions'));
/*
linear-gradient(135deg, rgb(255 153 0) 0%, rgb(255 249 88) 100%)

linear-gradient(135deg, rgb(251 150 150) 0%, rgb(162 134 249) 100%)

linear-gradient(135deg, rgb(10 151 251) 0%, rgb(134 249 222) 100%)

linear-gradient(135deg, rgb(150 251 153) 0%, rgb(134 164 249) 100%)

linear-gradient(135deg, rgb(255 104 104) 0%, rgb(255 248 56) 100%)

linear-gradient(135deg, rgb(145 255 99) 0%, rgb(249, 245, 134) 100%)

linear-gradient(135deg, rgb(75 65 239) 0%, rgb(134 249 238) 100%)
*/
const cognitiveCategories = [
    {
        code:'memory',
        name:'Memory',
        color:'linear-gradient(135deg, rgb(249 216 205) 0%, rgb(230, 238, 255) 100%)',
        description:''
    },
    {
        code:'speed',
        name:'Speed',
        color:'linear-gradient(135deg, rgb(221 205 249) 0%, rgb(230 238 255) 100%)',
        description:''
    },
    {
        code:'reasoning',
        name:'Reasoning',
        color:'linear-gradient(135deg, rgb(221 205 249) 0%, rgb(230 238 255) 100%)',
        description:''
    },
    {
        code:'attention',
        name:'Attention',
        color:'linear-gradient(135deg, rgb(221 205 249) 0%, rgb(230 238 255) 100%)',
        description:''
    }
]
const games = [
    {
        code:'recall-the-digits',
        name:'Quick peek!',
        color:'linear-gradient(135deg, rgb(249 216 205) 0%, rgb(230, 238, 255) 100%)',
        description:''
    },
    {
        code:'spot-the-circles',
        name:'Spot the circles!',
        color:'linear-gradient(135deg, rgb(221 205 249) 0%, rgb(230 238 255) 100%)',
        description:''
    },
    /*  {
        code:'tic-tac-toe',
        name:'Tic-Tac-Toe!',
        color:'linear-gradient(135deg, rgb(150 251 153) 0%, rgb(134 164 249) 100%)',
        description:''
    }, */
    {
        code:'matrix-pattern',
        name:'Matrix Pattern!',
        color:'linear-gradient(135deg, rgb(255 227 227) 0%, rgb(224 241 235) 100%)',
        description:''
    },
    
    {
        code:'matching-matrix',
        name:'Pattern Recognition!',
        color:'linear-gradient(135deg, rgb(199 243 200) 0%, rgb(233 239 255) 100%)',
        description:''
    },
    {
        code:'arithmetic-speed',
        name:'Arithmetic!',
        color:'linear-gradient(135deg, rgb(211 230 255) 0%, rgb(238 240 245) 100%)',
        description:''
    },
    /* {
        code:'find-the-characters',
        name:'Find the Characters!',
        color:'linear-gradient(135deg, rgb(150 251 153) 0%, rgb(134 164 249) 100%)',
        description:''
    }, */
    {
        code:'fraction-comparison',
        name:'Fractions!',
        color:'linear-gradient(135deg, rgb(243 251 150) 0%, rgb(235 223 247) 100%)',
        description:''
    },
    {
        code:'word-joggler',
        name:'Word Puzzle!',
        color:'linear-gradient(135deg, rgb(198 227 249) 0%, rgb(239 216 216) 100%)',
        description:''
    },
    {
        code:'word-joggler-2',
        name:'Word Puzzle II!',
        color:'linear-gradient(135deg, rgb(150 251 153) 0%, rgb(134 164 249) 100%)',
        description:''
    },
    /* {
        code:'shape-process',
        name:'Process Building!',
        color:'linear-gradient(135deg, rgb(150 251 153) 0%, rgb(134 164 249) 100%)',
        description:''
    }*/
] 


const gameInfo = [
    {
        code:"recall-the-digits",
        title:"Quick Peek",
        description:"This game tests and trains your ability to quickly memorise visual information. Random characters (letters and numbers) are initially presented to you for 3 seconds and then quickly flipped, showing you a text box to enter the same characters in 10 seconds. Each correct entry amounts to 1 point.",
        howToPlay:["Take note of the initial set of characters.","After the board flips, enter the characters in the text field."],
        benefits:["Test your ability to retain information.", "Trains your short term memory."]
    },
    {
        code:"spot-the-circles",
        title:"Spot the Circles",
        description:"This game tests and trains your ability to quickly memorise visual information. Circles of different sizes are initially presented to you for 3 seconds and then quickly flipped, showing you a mix of other circles. You are required to spot the initial set of circles in 10 seconds. Each correct pick amounts to 1 point.",
        howToPlay:["Take note of the initial set of circles and their positions.","Click or tap on the same circles after the board flips to score points."],
        benefits:["Trains your visual and spatial memory."]
    },
    {
        code:"matrix-pattern",
        title:"Matrix Pattern",
        description:"This game tests and trains your ability to quickly memorise visual information. The Stars or Circles in a grid are initially presented to you for 3 seconds and then quickly flipped, showing you a matrix of squares. You are required to spot the initial set of circles or stars presented to you in 10 seconds by tapping on the square shapes covering it. Each correct pick amounts to 1 point.",
        howToPlay:["Take note of the initial set of circles or stars and their positions","Click or tap on the squares covering the circles or shapes after the board flips."],
        benefits:["Trains your visual and spatial memory."]
    },
    {
        code:"matching-matrix",
        title:"Pattern Recognition!",
        description:"This is game measures and trains your ability to transform visual information. The matrix shown on the left with a particular pattern, is tranformed to get the matrix on the right. You have 10 seconds to identify the transformation (rotation to the right, rotation to the left, flipping along the x-axis, flipping along the y-axis, shifting the shape up or shifting the shape to the right) applied to the matrix on the left to get the matrix on the right. Each correct pick amounts to 1 point.",
        howToPlay:["Compare the left and right matrices.","Click or tap on the right transform in the options below the matrices."],
        benefits:["Test your visual abilities to manipulate/transform shapes."]
    },
    {
        code:"arithmetic-speed",
        title:"Arithmetic",
        description:"This game measures and trains your math skills; your ability to quickly perform daily computations if you are someone who has to play with numbers. Quickly figure the answer to the arithmetic and type it in using the keyboard presented to you.",
        howToPlay:["Compute the arithmetic and enter the answer in the box to the right."],
        benefits:["Improves your math skills; accuracy and speed."]
    },    
    {
        code:"fraction-comparison",
        title:"Fractions!",
        description:"This game measures and trains your math skills; your ability to quickly compute and compare fractions. Quickly compare the fraction on the left to the one on the right of the question mark, then select an option indicating if the left fraction is less than, greater than or equal to the one on the right.",
        howToPlay:["Compute the arithmetic and enter the answer in the box to the right."],
        benefits:["Improves your accuracy and speed of your computation of fractions."]
    },        
    {
        code:"word-joggler",
        title:"Word Puzzle",
        description:"This game tests and trains your vocabulary. The meaning of the word is displayed. You are to fill in the missing letters from the options below.",
        howToPlay:["Drag the missing letters from the list below into the empty boxes. You can click on the delete button below the character to remove it and return to the list below or drag."],
        benefits:["Improves your vocabulary."]
    },
    {
        code:"word-joggler-2",
        title:"Word Puzzle 2",
        description:"This game tests and trains your vocabulary. The meaning of the word is displayed. You are to fill in the missing letters from the options below.",
        howToPlay:["Drag the missing letters from the list below into the empty boxes. You can click on the delete button below the character to remove it and return to the list below or drag."],
        benefits:["Improves your vocabulary."]
    }
    
]

/* const gamePlayOptions = [
    {
        gameCode:'recall-the-digits',
        isAudioOn:false,
        difficulty: 1
    }
] */

const initState = {
    user:user ? {...user, loggedIn: false, loggingIn:false}:null,
    rooms:[],
    games:[...games],
    gameInfo:[...gameInfo],
    gamePlayData: gamePlayData ? [...gamePlayData] : [],
    gamePlayOptions: gamePlayOptions ? [...gamePlayOptions]:[],
    trainingData: trainingData ? [...trainingData] : [],//? trainingData
    cognitiveCategories: [...cognitiveCategories],
    assets:[],
    users:[],
    consignments:[],
    endOfMonth:1,
}

const rootReducer = (state = initState, action) => {
    console.log("reducer", action)
    switch (action.type) {
        /* User Reducer Operations */
        case "LOGIN_USER":
            return {
                ...state,
                user:{
                    loggedIn: true,
                    ...action.user
                }
            };    
        /* case "SAVE_GAME_PROGRESS":
            return {
                ...state,
                gameData:[...gameData, action.data]
            }; */
        case "UPDATE_GAME_SCORE":
            if(action.data.points > 0)
            {
                let updatedGamePlayData = state.gamePlayData.find(g => g.sessionId === action.data.sessionId) ?  state.gamePlayData.map(
                    (m, i) => m.sessionId === action.data.sessionId ? { ...m, points: action.data.points}
                                            : m
                ) : [...state.gamePlayData, {...action.data, startTime:action.data.sessionTime}]

                localStorage.setItem('gamePlayData',JSON.stringify(updatedGamePlayData));

                return {
                    ...state,
                    gamePlayData:[...updatedGamePlayData]    
                };    
            }
            return {
                ...state,
            };    
        
        case "UPDATE_GAME_PLAY_OPTIONS":
            if(action?.data)
            {
                let updatedGamePlayOptions =  state.gamePlayOptions.find(g => g.gameCode === action?.data?.gameCode) ? state.gamePlayOptions.map(
                    (m, i) => m.gameCode === action.data.gameCode ? { ...m, ...action.data}
                                            : m)
                                            : [...state.gamePlayOptions, {...action.data}]
                

                console.log("play options", [...updatedGamePlayOptions])
                localStorage.setItem('gamePlayOptions',JSON.stringify(updatedGamePlayOptions));

                return {
                    ...state,
                    gamePlayOptions:[...updatedGamePlayOptions]    
                };    
            }
            return {
                ...state,
            };    

        case "CLEAR_GAME_SCORES":           
    
                localStorage.setItem('gamePlayData',JSON.stringify([]));
                return {
                    ...state,
                    gamePlayData:[]    
                };    
        case "LOGOUT_USER":
            return {
                ...state,
                user:null
            };  
        
        case 'SAVE_TRAINING_DATA':
           
            if(action.data)
            {
               // if(state.trainingData)
                {
                    let updatedTrainingData = state.trainingData?.find(g => g.sessionId === action.data.sessionId) ?  state.trainingData?.map(
                        (m, i) => m.sessionId === action.data.sessionId ? { ...m, ...action.data}
                                                : m
                    ) : [...state.trainingData, {...action.data, startTime:action.data.sessionTime}]

                    if(updatedTrainingData)
                    {
                        localStorage.setItem('trainingData',JSON.stringify(updatedTrainingData));
                    }

                    return {
                        ...state,
                        trainingData:[...updatedTrainingData]    
                    };   
                }
                /*else
                 {
                    let updatedTrainingData =  [{...action.data, startTime:action.data.sessionTime}]

                    if(updatedTrainingData)
                    {
                        localStorage.setItem('trainingData',JSON.stringify(updatedTrainingData));
                    }

                    return {
                        ...state,
                        gamePlayData:[...updatedTrainingData]    
                    };   
                } */
                

                 
            }
            return {
                ...state,
            };    
       
         /* Consignment Reducer Operations */
         case 'CREATE_ROOM':
            return {
                ...state,
                rooms:[...state.rooms, action.data]
            }
        case 'UPDATE_ROOM':
            {
                return{
                    ...state,                    
                    rooms: state.rooms.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'FETCH_ROOMS':
            {
                return{
                    ...state,                    
                    rooms: [...action.data]
                }
            }
        case 'DELETE_ROOM':
            {                         
                return{
                    ...state,
                    rooms:[...state.rooms.filter(m => {return m.id !== action.data})]
                }
            }
      
        /* Users Reducer Operations */
        case 'CREATE_USER':
            return {
                ...state,
                users:[...state.users, action.data]
            }
        case 'UPDATE_USER':
            {
                return{
                    ...state,                    
                    users: state.users.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'UPDATE_USER_INFO':
            {
                localStorage.setItem('user',JSON.stringify({...state.user, ...action.data}));

                return{
                    ...state,
                    user:{...state.user, ...action.data}
                }

                
            }
      
        default:
            return state;
    }
};

export default rootReducer;