import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useSpring, animated as a, config } from 'react-spring';


const StyledButton = styled.div`
    color: white;
    background: #373737;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid #a1a1a1;
    font-weight: bold;
`


const PopUp = (props) => {
  const [flip, set] = useState(false)
  const { x , ox, y, scale, opacity} = useSpring({
    //reset: true,
    //reverse: flip,
    from: { x: 0,ox:0, y:'0%', scale:0.5, opacity:0 },
    x: 1,    
    ox: 3,
    y:flip? '50%':'0%',
    opacity:flip?1:0,
    scale:flip?1:0.5,
    delay: 200,
    config: config.wobbly,
    //onRest: () => set(!flip),
  })

  useEffect(()=>{

    set(props.open)
    
  },[props.open])

  const style = {
    color: "white",
      background: "#373737",
      fontSize: "16px",
      padding: "10px 20px",
      borderRadius: "5px",
      border: "2px solid #a1a1a1",
      fontWeight: "bold",
  }

  return ( <a.div className={"counter-offset-centered-view"} style={{position:'fixed', left:0, zIndex:2, alignItems:'center', justifyContent:'center', display:`${flip ? 'flex' : 'none'}`, height:'100vh', width:'100vw', background:'rgba(10,10,10,0.8)'}}>
      
        <a.div style={{width:'90%', maxWidth:400, transform:scale.to(s => `scale(${s})`), opacity, top:y}}>
        {/* <div style={{width:200, height:100, background:'white'}}></div>
        <div style={{width:200, height:50, background:'black'}}>
            <button style={style} onClick={() => set(false)}>Ok</button>
            <button style={style} onClick={() => setTimeout(()=>set(false), 10000)}>Cancel</button>
        </div> */}
        {props.children}
        </a.div>
    </a.div>
  )
}


export default PopUp;