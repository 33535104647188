import React from 'react'
import styled, {keyframes,css} from 'styled-components'; 
import closeButtonIcon from '../../assets/images/close-button.svg';  
import {useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo1  from '../../assets/images/brain-trainer-logo.svg';

const ScoreBoxContainer = styled.div`
    border-radius: 5px;
    display: flex;
    background:  rgb(255, 255, 255);
    box-shadow: 1px 1px 5px rgb(153 153 153 / 40%);
    height: 36px;
    margin-right:10px;
`;

const ActivityCloseButton = styled.div`
    border-radius: 5px;
    display: flex;
    background:  rgb(255, 255, 255);
    box-shadow: 1px 1px 5px rgb(153 153 153 / 40%);
    height: 50px;
    width: 50px
    margin-right:10px;
`;

const ScoreBoxImage = styled.div`
    background-color:  ${props => props.scoreType && props.scoreType.toLowerCase() === 'right' ? '#30ab53' : 'red'};
    width: 30px;
    height: 36px;
    border-radius: 0px 5px 5px 0px;
`;

const ScoreBoxDisplay = styled.div`
    font-size: 20px;
    font-weight: bold;
    padding: 5px 10px;
    color: ${props => props.scoreType && props.scoreType.toLowerCase() === 'right' ? '#30ab53' : 'red'};
`;

const ScoreBox = ({score, scoreType}) =>
{
    return (
        <ScoreBoxContainer>
            <ScoreBoxDisplay  scoreType={scoreType}>{score ? score : 0}</ScoreBoxDisplay>
            <ScoreBoxImage scoreType={scoreType}/>
        </ScoreBoxContainer>
    )
}

const ArrowRight = styled.div`
    width: 0; 
    height: 0; 
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 6px solid rgba(255,255,255,0.7);
`;


const GameHeader = ({titleSubText, activityTitle, activityName, showScore, redirectURL, backgroundColor, height, within}) => {

    const navigate = useNavigate();

    const gamePlayData = useSelector(state => state.gamePlayData);

    const closeActivity = () => {
        //history.push(redirectURL || '/lessons');
        if(redirectURL)
        {
            navigate(redirectURL);
        }
        else
        {
            navigate(-1);
        }
    }

    return (
    <>
    {/*<div style={{position:'fixed',zIndex:10, backgroundColor:'#FFFFFF', display:'flex', flexDirectio:'column', justifyContent:'space-between', width:'100%', alignItems:'center', height:30}}>
        <img style={{width:30}} src={LogoIcon}/>
        <CustomButton1>End</CustomButton1>
    </div>*/}
    <div style={{position: within? 'static' : 'fixed', zIndex:10, top:0, boxShadow:'0px 2px 2px rgba(0, 0, 0, 0.2)', background:backgroundColor? backgroundColor :'linear-gradient(135deg, rgb(168, 237, 234) 0%, rgb(254, 214, 227) 100%)', display:'flex', flexDirectio:'column', width:'100%', alignItems:'center', justifyContent:'space-between', height: height ? height : 60}}>                
                <div style={{padding:'10px 5px', display:'flex'}}>
                <img src={Logo1} style={{height:36, margin:7,fontFamily:'Dancing Script'}} alt="logo" />
                <div style={{display:'flex', marginLeft:10, flexDirection:'column', alignItems:'start'}}>
                    <div className='app-title' style={{ paddingLeft:0,/* maxWidth:140, whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden', display:'block', fontWeight:'bold', textShadow: '0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.5)', color:'#cc6700', fontFamily:'Dancing Script', fontSize:26 */}}
                    >{activityTitle}</div>
                    <span style={{ display:'inline-block', fontWeight:'normal', color:'rgba(65,65,65,1)', fontSize:10}}>{titleSubText}</span>                    
                    {/* <span style={{ display:'inline-block', fontWeight:'normal', padding:'0px 10px', color:'#555555', fontSize:12}}><ArrowRight/></span>
                    <span style={{ display:'inline-block', fontWeight:'normal', color:'rgba(255,255,255,0.7)', fontSize:12}}>{activityName}</span> */}
              
                </div>               
                </div>  

               
                <div>
                    <div><span style={{/* background:'linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%)', */ color:'#009ce', boxShadow:'inset -2px -2px 5px rgba(100,100,100,0.4)', padding:'5px 10px', display:'flex', flexDirection:'column', borderRadius:5}}><span style={{fontSize:24, textShadow: '0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.5)', color:'#019bdf'}}>{gamePlayData.reduce((p,c) => p + c.points, 0)}</span><span style={{fontSize:9}}>Total points </span></span></div>
                </div>

                <div onClick={closeActivity} style={{width:40, height:'100%', cursor:'pointer', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'rgba(226,4,4,0.3)',  borderRadius:'0%', margin:0}}>
                    
                    <img style={{width:20}} src={closeButtonIcon}/>    
                </div>     
            </div>
    </>)
}

export default GameHeader;