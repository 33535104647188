import React, {useState, useReducer} from 'react';

export const MenuContext = React.createContext()

export const SideMenuContext = (props) => {
    const [isOpen, setIsOpen] = useReducer(p => !p, false)//useState(false)   
    
    return(
        <MenuContext.Provider value={{isOpen, setIsOpen}}>
            {props.children}
        </MenuContext.Provider>
    )
}