import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { Route, Routes,BrowserRouter} from 'react-router-dom';  
import NotificationContainer from './notification/NotificationContainer';
import './assets/css/app-main.css'

// Loads the Pages,
import GameView from './views/GameView'
import AllGames from './views/AllGames';
import StatisticsView from './views/StatisticsView';
import HelpView from './views/HelpView';
import SplashScreen from './views/SplashScreen'
import Settings from './views/Settings';

/*import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'; // or any other pipeline*/


/* import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'; */


import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'
import SetupView from './views/SetupView';
import TrainingView from './views/TrainingView';
import ReactGA from 'react-ga';


const TRACKING_ID = "UA-104993010-2";//"G-V1EQ4ZSE1K";

ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <DndProvider backend={TouchBackend} options={{enableMouseEvents:true}} /* backend={MultiBackend} options={HTML5toTouch} */>
    <div className="App">
      <Routes>  
        <Route path='/all-games' element={<AllGames/>} />
        <Route path='/settings' element={<Settings/>} />
        <Route path='/user-statistics' element={<StatisticsView/>} />
        <Route path='/help' element={<HelpView/>} /> 
        <Route path='/game/:gameCode' element={<GameView/>} />
        <Route path='/setup' element={<SetupView/>} />
        <Route path='/train' element={<TrainingView/>} />
        
        {/* <Route path='/notifications' element={<PrivateRoute><UserNotifications/></PrivateRoute>} />  */}
        <Route path='/' element={<SplashScreen/>} />
      </Routes>
      <NotificationContainer/>
    </div>
    </DndProvider>
  );
}

export default App;
