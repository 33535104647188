import React from 'react'
import { useDragLayer } from 'react-dnd'

/*
const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

function getItemStyles(props) {
  const { currentOffset } = props
  if (!currentOffset) {
    return {
      display: 'none',
    }
  }

  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
  	width:"8%",
    transform: transform,
    WebkitTransform: transform,
    // height:"50%"
  }
}

class CustomDragLayer extends React.Component{
  render(){
  	// console.log(this.props)
  	if (!this.props.isDragging) {
	    return null
	  }
	if (!this.props.currentOffset) {
	    return null
	  }

	let top = 85
	let cards = [{name:this.props.item.name,up:true,isDragging:true}]
	if(this.props.item.getNextCards){
		cards = this.props.item.getNextCards(this.props.item.name)
	}
	// console.log(cards)

	cards = cards.map((card,index)=>{
		let cardStyle = {transform:"translate(0%,"+(top-=85)+"%)"}
		return(
					<div className = "play-card" style = {cardStyle} key={index}>
				     <Card name = {card.name}
				     	   up = {card.up}
					 />
				    </div>
		    )
	})
			     	   
	// console.log(cards)
  	return(
  		<div style = {layerStyles}>
  			<div style = {getItemStyles(this.props)}>
  			  {cards}
  			</div>
  		</div>
  		)
  }
}

function collect(monitor) {
  return {
    item: monitor.getItem(),
    // itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }
}

export default DragLayer(collect)(CustomDragLayer)*/

const CustomDragLayer = (props) => {
    const {
    item,
      itemType,
      isDragging,
      initialCursorOffset,
      initialFileOffset,
      currentFileOffset,
    } = useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialCursorOffset: monitor.getInitialClientOffset(),
      initialFileOffset: monitor.getInitialSourceClientOffset(),
      currentFileOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));
  
    if (!isDragging) {
      return null;
    }

    //console.log("draggable", item)

    const draggableObjectStyles = getItemStyles(
        initialCursorOffset,
        initialFileOffset,
        currentFileOffset
      )
  
    return (
      <div style={layerStyles}>
        <div
          style={{...draggableObjectStyles,
            width:35, 
            height:40, 

            boxShadow:'2px 2px 3px rgba(100,100,100,0.8)',
            background:`yellow`,
            border:'0px solid green', fontSize:26, borderRadius:10, margin:5, padding:'5px 15px', background:'white',
            ...props.style
        }}
        >   
        {
          item.card
        }       
        </div>
      </div>
    );
  };
  
  const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    //border: "10px solid red",
    background:'rgba(100,100,100,0.5)'
  };
  
  function getItemStyles(
    initialCursorOffset,
    initialOffset,
    currentOffset
  ) {
    if (!initialOffset || !currentOffset || !initialCursorOffset) {
      return {
        display: "none",
      };
    }
  
    /*const x = initialCursorOffset?.x + (currentOffset.x - initialOffset.x);
    const y = initialCursorOffset?.y + (currentOffset.y - initialOffset.y);*/
    const { x, y } = currentOffset
    const transform = `translate(${x}px, ${y}px)`;
  
    return {
      transform,
      WebkitTransform: transform,
      background: "red",
      borderRadius:10,
      width: "160px",
     
    };
  }
  

  export default CustomDragLayer;