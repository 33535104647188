import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import LayoutWrapper from '../layout/LayoutWrapper';
//import Logo1  from '../assets/images/logo.svg';
//import ViewWrapper from './ViewWrapper';
//import Prompt from '../components/Prompt/Prompt';
import GameHeader from '../components/GameHeader/GameHeader';

import GameSelector from '../games/GameSelector/GameSelector';
import {
    useParams,
    
} from 'react-router-dom';
import PopUp from '../components/PopUp/PopUp';
import Moment from 'moment';

import useAnalyticsEventTrack from '../games/hooks/analyticsTracker'


const GameView = (props)  => {

   
    const {gameCode} = useParams();

    const gaEventTracker = useAnalyticsEventTrack(gameCode)

    const games = useSelector(state => state.games);

    const currentGame = games.find(g => g.code == gameCode);

    const gamePlayData = useSelector(state => state.gamePlayData);

    useEffect(()=>{
        gaEventTracker("Launched Game@", gameCode)
    })

    const getGameLastPlayDataByCode = (code) => {
        
        //console.log("DDAA",Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime))))

        let lastPlayed = Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime)));

        if(lastPlayed && lastPlayed !== -Infinity)
        {
            console.log("lastPlayed",lastPlayed);
            return Moment(new Date(lastPlayed)).startOf('second').fromNow()
        }
        return "";//new Date(Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime))));
        
    }

    

    return( <>
    <GameHeader titleSubText={getGameLastPlayDataByCode(currentGame?.code)} activityTitle={currentGame?.name}/>
    <div style={{
        position:'relative',
        height:'calc(100vh - 60px)',
        width:'100%'
    }}>
        
        
        <div style={{
            backgroundColor:'green',
            background: 'linear-gradient(0deg, rgba(9,73,121,1) 19%, rgba(0,145,200,1) 47%, rgba(52,154,230,1) 69%, rgba(0,212,255,1) 100%)',
            background: 'linear-gradient(45deg, rgb(9, 73, 121) 19%, rgb(0, 145, 200) 47%, rgb(63 230 52) 69%, rgb(0, 212, 255) 100%)',
            
            width:'100%',
            height:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            position:'relative',
            alignItems:'center'
        }}>
            
            <div style={{backgroundColor:'none', height:'95%', display:'flex',
            flexDirection:'column',
            overflow:'hidden',
            borderRadius:10,
            background:'linear-gradient(0deg, rgb(255 133 95 / 15%) 0%, rgb(253 253 253) 100%)',
            justifyContent:'center',
            position:'relative',
            alignItems:'center', width:'95%', maxWidth:750, boxShadow:'2px 5px 20px rgba(10,10,10,0.6)'}}>

                   <GameSelector gameCode={gameCode}/>
            </div>

        </div>
       
    </div>
    </>)

}   

export default LayoutWrapper(GameView, {showNavigationBar:false, showSideMenu:false});