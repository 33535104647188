import React, {useEffect, useState} from 'react';
import {animated as a, useSpring} from 'react-spring';
import arrowLeft from './images/arrow-left.svg'
import arrowRight from './images/arrow-right.svg'


const HorizontalScrollView = (props) => {

    const containerRef = React.createRef();

    const scrollWrapperRef = React.createRef();

    const [left, setLeft] = useState(5);

    const [wrapperWidth, setWrapperWidth] = useState(200);
    const [containerWidth, setContainerWidth] = useState(200);

    const [{x}, api] = useSpring(()=>({x:5}))

    const scrollLeft = () => {

        let {width:cw} = containerRef.current?.getBoundingClientRect();

        //if(left <0 )
        let offsetW = left + cw/3 <= 0 ? cw/3 : -left;
        setLeft(l => l +(offsetW))
    }

    useEffect(()=>{
        let {width:cw} = containerRef.current?.getBoundingClientRect();//containerRef.current?.getClientRects()

        let {width:sw} = scrollWrapperRef.current?.getBoundingClientRect();

        setWrapperWidth(sw)
        setContainerWidth(cw)
    },[])

    const scrollRight = () => {

        // measure container and scrollwrapper
        //if(left)

        let {width:cw} = containerRef.current?.getBoundingClientRect();//containerRef.current?.getClientRects()

        let {width:sw} = scrollWrapperRef.current?.getBoundingClientRect();

        setWrapperWidth(sw)
        setContainerWidth(cw)

        console.log({cw, sw})

        if(left + sw > cw)
        setLeft(l => l -(cw/3))
        
    }

    useEffect(()=>{
        api.start({to:{x:left}})
    },[left])

    const buttonStyle = {
      
        margin:10, padding:'15px', borderRadius:10, 
        background:'linear-gradient(135deg,rgb(140 230 224) 0%,rgb(253 253 253) 100%)', 
        background:'#b9ffd9',
        display:'flex', alignItems:'center', justifyContent:'center', 
        fontSize:13, 
        boxShadow:'inset rgb(15 15 15 / 70%) -2px -2px 2px', 
        flexDirection:'column',
        borderRadius:'50%',
        height:'100%'
    }

    return(
        <div style={{display:'flex', width:'100%', overflowX:'hidden', overflowY:'visible'}}>
                <div onClick={scrollLeft}  style={{width:60, height:100, display:'flex', alignItems:'center', justifyContent:'center', opacity: left <0 ? 1: 0.3, zIndex:5, boxShadow:'2px 0px 20px rgba(20,20,20,0.6)'}}>
                    <div style={{...buttonStyle, borderRadius:'10px 0px 0px 10px'}}><img width={30} src={arrowLeft}/></div>
                </div>
                <div ref={containerRef} style={{flexGrow:1, display:'flex', alignItems:'center', justifyContent:'center', position:'relative', overflowX:'hidden',overflowY:'visible', padding:5, boxShadow:'inset 2px 0px 20px rgba(20,20,20,0.6)', background:'rgba(100,100,100,0.2)'}}>
                    <a.div ref={scrollWrapperRef} style={{background:'none', color:'black', position:'absolute', left:x, minWidth:200}}>{props.children}</a.div>
                </div>
                <div onClick={scrollRight} style={{width:60, height:100, display:'flex', alignItems:'center', justifyContent:'center', opacity: left + wrapperWidth > containerWidth ? 1 : 0.3, zIndex:5, boxShadow:'-2px 0px 20px rgba(20,20,20,0.6)'}}>
                <div style={{...buttonStyle, borderRadius:'0px 10px 10px 0px'}}><img width={30} src={arrowRight}/></div>
                </div>
        </div>
    )
}

export default HorizontalScrollView;