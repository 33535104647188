import React, { useEffect } from 'react';
import { useSpring, useSprings, animated, interpolate, to, config } from 'react-spring'
import { useDrag } from '@use-gesture/react'


const ToggleSelect = (props) => {
    
    const [toggleState, setToggleState] = React.useState(props.isTrue);
    const [{ x, y, opacity, size:height, size: width}, set] = useSpring(() => ({ x: toggleState ? '0%' : '60%', y: 0, opacity:1, size:0 }));

    useEffect(()=>
        {
            //set({ x: 25, y: 0, opacity:1, size: 20 })
            set({ x: toggleState ? '0%' : '60%', y: 0, opacity:1, size:0 })
            console.log(x)
            //set({to:[{ x: -25, y: -25, opacity:1}, { x: -50, y: -50, opacity:0}] })
        }
    ,[toggleState])

    useEffect(()=>
    {
        setToggleState(props.isTrue);
    }
    ,[props.isTrue])

    const handleClick = () => {
        props.onChange && props.onChange(!toggleState) || setToggleState(s => !s)
    }

    return(
        <div onClick={handleClick} style={{border:'1px solid #999999', boxSizing:'unset', width:50, height:20, margin:'0px 10px', boxShadow:'inset 2px 2px 3px rgba(20,20,20,0.4)', background: toggleState ? '#aadeaa' : 'grey', position:'relative', borderRadius:'20px'}}>
        {<animated.div style={{background:'rgba(100,100,100,0.3)', position:'absolute', borderRadius:'50%', width:20, left:x, height:20, /* transform: to([x, y], (x, y) => `translate(${x}px, ${y}px)`), */ opacity}}>
            <div style={{background:toggleState ? 'green' : 'red', margin:'5px', borderRadius:'50%', width:10, left:x, height:10,}}></div>
        </animated.div>}
        </div>
    )
}

export default ToggleSelect;