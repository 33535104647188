import React, {useState, useEffect} from 'react';
import o_img from './images/o_img.png';
import x_img from './images/x_img.png';
import styled from 'styled-components';
import FluidBox from '../../components/FluidBox/FluidBox';
import PullRelease from '../../components/PullRelease/PullRelease';
import { ScoreBox, TimerBox } from '../components/InfoComponents/infoComponents';
import { shuffle } from '../../utilities/arrayUtilities';
import useMeasure from 'react-use-measure';
import ok_button from '../../assets/images/cancel-button.svg';

const BoxCell = styled.div`
    border-radius: 5px;
    display: flex;
    background:  rgb(255, 255, 255);
    box-shadow: 1px 1px 10px rgb(153 153 153 / 70%);
    height:100%;
    width: 100%;
    padding: 5px;
    min-height: 60px;
    min-width: 60px;
    width: 100%;
    aspect-ratio:1/1;
    /*margin:10px;*/
    cursor:pointer;

    &:hover {
        transform: scale(1.1);
      }

`;


const renderCell = (val) =>{

    switch(val)
    {
        case 0 : return <BoxCell></BoxCell>
        case 1 : return <BoxCell><img src={o_img}/></BoxCell>
        case 2 : return <BoxCell><img src={x_img}/></BoxCell>
    }
}

const movePositions = [
  {
    code:'1-1',
    index:1
  },{
    code:'1-2',
    index:2
  },{
    code:'1-3',
    index:3
  },{
    code:'2-1',
    index:4
  },{
    code:'2-2',
    index:5
  },{
    code:'2-3',
    index:6
  },{
    code:'3-1',
    index:7
  },{
    code:'3-2',
    index:8
  },{
    code:'3-3',
    index:9
  }
];

const TicTacToe = (props) => {

    const [state, setState] = useState([
        [0,0,0],
        [0,0,0],
        [0,0,0]
    ])

    const [startRef, { left:startLeft, width:sWidth, top:startTop, height:sHeight }] = useMeasure()
  
    const [endRef, { left:endLeft, width:eWidth, top:endTop, height:eHeight }] = useMeasure()

    const [toggle, setToggle] = React.useState(false)
  
    const [offset, setOffset] = React.useState({x:0,y:0})

    const [isPlayerTurn, setIsPlayerTurn] = useState(true)
    const [playerMoves, setPlayerMoves] = useState([])
    const [pCMoves, setPCMoves] = useState([])
    const [availableMoves, setAvailableMoves] = useState([...movePositions])
  
    const [points, setPoints] = useState(0);
  
    const tref = React.useRef(null);

    const handleCellClick = (r, c) => {

        let newState = [...state];

        console.log({r, c, d:newState[r][c]},availableMoves)
        if(newState[r][c] == 0)
        {
            
            let currentPlayerMove = availableMoves.find(m => m.code == `${r+1}-${c+1}`)
            if(currentPlayerMove)
            {
              setPlayerMoves(pm => [...pm, {...currentPlayerMove}])
              setAvailableMoves(am => [...am.filter(m => m.code != `${r+1}-${c+1}`)])
              newState[r][c] = 1;//isPlayerTurn ? 1 : 2;

              setIsPlayerTurn(false);
            }
        }

        setState([...newState])

        //setIsPlayerTurn(ipt => !ipt);

    }

    const checkIfWinningMoves  = (moves) => {

      moves = moves.sort((a, b) => a.index - b.index);

      if(moves.length < 3) return false;

      let winningCombinations = [
        "1-2-3",
        "4-5-6",
        "7-8-9",
        "1-5-9",
        "3-5-7",
        "1-4-7",
        "2-5-8",
        "3-6-9",
      ]

      for(let i = 2; i < moves.length ; i++)
      {
        let moveSignature = `${moves[i-2].index}-${moves[i-1].index}-${moves[i].index}`;

        if(winningCombinations.some(wc =>wc === moveSignature))
        {
          //alert("win")
          return true;
        }
      }
    }

    useEffect(()=>{
      //if(availableMoves.length == 0)
      {
          if(checkIfWinningMoves(playerMoves))
          {
            setPoints(p => p+2);
          }
      }
    },[availableMoves])

    // PC Plays after player's turn
    useEffect(()=>{

      if(!isPlayerTurn)
      {

        // Check that there're available slots
        let pCAvailableMoves = [...availableMoves]
        pCAvailableMoves = shuffle(pCAvailableMoves);

        if(pCAvailableMoves > 0)
        {

        }

        // Randomly pick from the available slots

        let newState = [...state];
        let currentPCMove = pCAvailableMoves[0];
            if(currentPCMove)
            {
              let codePositions = currentPCMove.code.split("-")
              let r = parseInt(codePositions[0])-1;
              let c = parseInt(codePositions[1])-1;

              setPCMoves(pm => [...pm, {...currentPCMove}])
              setAvailableMoves(am => [...am.filter(m => m.code != `${r+1}-${c+1}`)])
              console.log("M", {r,c,newState,currentPCMove,availableMoves})
              newState[r][c] = 2;//isPlayerTurn ? 1 : 2;

              //setIsPlayerTurn(false);
            }

        setState([...newState])
        // Check the winning for Player and PC moves

        setIsPlayerTurn(true);
      }

    }, [isPlayerTurn])

    const restartGame = () => {
        setState([
            [0,0,0],
            [0,0,0],
            [0,0,0]
        ])
    }

    return(
        <FluidBox start={[startLeft + sWidth/2-offset.x, startTop+sHeight/2 -offset.y]} show={toggle} end={[endLeft + eWidth/2-offset.x, endTop + eHeight/2-offset.y]}>
      <div  ref={tref} style={{
        //width:'100vw',
        //height:'100vh',
        height:'100%', width:'100%', position:'absolute',
        //background:'rgb(245, 226, 214)',
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexDirection:'column'
      }}>
  
      <div className={'game-play-info-header'}>      
        {props.showGameTime && <TimerBox/>}    
      
        <ScoreBox points={points} ref={endRef} />      
      </div>

      <div style={{
        width:'100%',
        flexGrow:1,
        position:'relative',
        //background:'#EEEEEE',
        padding:5,
        minHeight:80,
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexDirection:'column',
      }}>
           
           <div ref={startRef} style={{border:'0px dotted black', width:'80%', maxWidth:500, minWidth:250, display:'flex', aspectRatio:'1/1', flexDirection:'column'}}>

                {state.map((row, r) => 
                <div key={r} style={{border:'0px solid black', display:'flex', flexGrow:1}}>
                    {row.map((col, c) => <div key={`${r}-${c}`} onClick={()=>handleCellClick(r, c)}  style={{ margin:5, width:'100%', border:'0px dotted #cecece'}}>{renderCell(col)} </div>)}
                </div>)}

                {state.map((row, r) => 
                <div key={r} style={{border:'0px solid black', display:'flex', flexGrow:1}}>
                    {row.map((col, c) => <div key={`${r}-${c}`} onClick={()=>handleCellClick(r, c)}  style={{ margin:5, width:'100%', border:'0px dotted #cecece'}}>{renderCell(col)} </div>)}
                </div>)}
            </div>

            <div>
          <div onClick={()=>restartGame()} style={{width:50, cursor:'handle', height:50, visibility: 'visible', borderRadius:'50%', cursor:'pointer', background:'none'}}>
          <img className={'game-cancel-button'} src={ok_button}/>  
          </div>
        </div>
                    
        </div>

        

    </div>
      {/* <PullRelease/> */}
      </FluidBox>
    )
}

export default TicTacToe;