import React, { useEffect, useState } from 'react';
import {animated, config, useSpring, animated as a} from 'react-spring';
import { useCountdownTimerCount } from '../../games/hooks/timerCount';


const CountDownBox = React.memo(({duration, onEndHandler}) =>{

    const {time, countDown} = useCountdownTimerCount(duration);

    const [days, hours, minutes, seconds] = time;

    const [springs,api] = useSpring(()=>({
        from: {
          //strokeDashoffset: 120,
          stroke:'#90ee90'
        },
        to: {
          //strokeDashoffset: 0,
          stroke:'#ff3300'
        },
        loop:true,
        //transform: `rotateX(${flipped ? 180 : 0}deg) translate(-50%, 0)`,
        //`perspective(600px) rotateX(${flipped ? 180 : 0}deg) translate(-50%, 0)`,
        //onRest:handleOnRest,//()=>{},//{console.log("Animation Ended"); props.onEnd && props.onEnd()},
        config: { duration: duration? duration * 1000 : 5000},
      }))

    useEffect(()=>{
        if(countDown === 0)
        {
            console.log("time ended");
            onEndHandler && onEndHandler();
        }
    },[countDown])


    //lightgreen => #90ee90

    return(
        <div style={{display:'flex', opacity:countDown <= 0 ? 0.3 : 1.0, alignItems:'center', width:'100%', justifyContent:'space-between', height:40}}>
               <div style={{margin:5, height:12, width:'70%', boxShadow:'inset 2px 2px 5px rgba(30,30,30,0.8)', borderRadius:6,background:'#CCCCCC'}}>
               {countDown > 0 && <a.div style={{margin:0, height:12, width:`${countDown/duration*100}%`, border:'1px solid transparent', transition:'width 1s', boxShadow:'inset -2px -2px 5px rgba(30,30,30,0.6)', borderRadius:6,
                    background:springs.stroke.to(s=>s)}}>
                
                    </a.div>}
               </div>
               <div>
               <div style={{fontWeight:'bold', letterSpacing:'2px', padding:'10px 5px', width:80, display:'inline-block', textShadow:'rgb(255 255 255 / 30%) 0px 1px 0px, rgb(0 0 0 / 50%) 0px -1px 0px', color:`${countDown > 10 ?'green': 'ec3209'}`, fontSize:24}}>{minutes}:{seconds}</div>
               </div>
               
            </div>
    )
})


export default CountDownBox;