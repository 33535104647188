import React from 'react';
import { useSelector, useDispatch} from 'react-redux';
import LayoutWrapper from '../layout/LayoutWrapper';
import ViewWrapper from './ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../components/Prompt/Prompt';
import coin from '../assets/images/coin.png';
import defaultIcon  from '../assets/images/default-game-icon.svg';
import Moment from 'moment'
const gamePlayData = [
    {
        gameTitle:'Guess the number',
        points:'2220',
        id:'guess-game'
    },
    {
        gameTitle:'Guess the number',
        points:'2220',
        id:'guess-game'
    }
]

const StatisticsView = (props) => {

    const gamePlayData  = useSelector(state => state.gamePlayData);

    const gamesList  = useSelector(state => state.games);

    const playedGamesCodes = gamePlayData.map(gp => gp.gameCode);

    const totalScore = gamePlayData.reduce((p, c) => p + c.points, 0);

    const dispatch = useDispatch();

    const getMaxPoints = (gdata) => {
        //console.log("MAX", gdata.map(d => d.points))
        return Math.max(...gdata.map(d => d.points));
    }

    const getTotalPoints = (gdata) => {
        return gdata.reduce((p, c) => p + c.points, 0);
    }

    const getGameNameByCode = (code) => {

        let game = gamesList.find(g => g.code === code)

        if(game)
        {
            return game.name;
        }

        return "";
    }

    const getGamePlayDataByCode = (code) => {
        
        return gamePlayData.filter(g => g.gameCode === code);
        
    }

    const getGameLastPlayDataByCode = (code) => {
        
        //console.log("DDAA",Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime))))
        return new Date(Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime))));
        
    }

    const clearData = () => {
       dispatch({type:"CLEAR_GAME_SCORES", data:{}});      
    }


    return(<div
        style={{           
            width:'100%', 
            padding:20, 
            maxWidth:800, 
            textAlign:'center'                     
        }}
    >
        
        <div style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}>
            <div style={{textAlign:'center', color:'#555555', fontSize:18}}>Total Points <span style={{color:'#cccccc'}}>:</span> <span style={{color:'orange'}}>{totalScore}</span></div>
            <button onClick={()=>clearData()} style={{fontFamily:'Righteous'}} className='flat-button-2'>Reset</button>
        </div>
        {gamesList.filter(s => playedGamesCodes.some(v => v == s.code)).map(gd => <div key={gd.code}
            style={{                
                width:'100%',               
                borderRadius:10,
                margin:'20px 0px',
                boxShadow:'2px 2px 20px rgba(130,130,130,0.7)',
                overflow:'hidden',
                display:'flex',
                //flexDirection:'column',
                border: '0px solid #CECECE'
            }}
            >
            <div style={{width:100, display:'flex', flexDirection:'column', alignItems:'center', padding:10, justifyContent:'space-between', background:'#f8f0e1'}}>
                
                <img  src={defaultIcon} style={{height:60, background:'#e4d3bd', borderRadius:5,   margin:2, }} alt="logo" />    
                
                <div style={{display:'flex', flexDirection:'column', paddingBottom:40}}>
                <img src={coin} width={32}/>
                <span style={{fontSize:18, color:'orange', fontWeight:'normal', textAlign:'center', textShadow: '0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.5)'}}>+{getTotalPoints(getGamePlayDataByCode(gd.code))}</span>
      
                </div>
            </div>

            <div
            style={{
                
                width:'100%',
                
                boxShadow:'2px 2px 20px rgba(180,180,180,0.5)',
                overflow:'hidden',
                display:'flex',
                flexDirection:'column',
                border: '0px solid #CECECE'
            }}
            >
                <div style={{flexGrow:1, display:'flex', flexDirection:'column', padding:10, alignItems:'flex-start', justifyContent:'space-around', background:'white'}}>
                    <span style={{fontSize:18, color:'#333333', fontWeight:'normal'}}>{gd.name}</span>
                    <div><span style={{fontSize:12, color:'grey', fontWeight:'normal'}}>Play Times: </span><span style={{fontSize:12, color:'orange', fontWeight:'normal'}}>{getGamePlayDataByCode(gd.code).length}</span></div>
                    <div><span style={{fontSize:12, color:'grey', fontWeight:'normal'}}>Last played: </span>
                        <span style={{fontSize:12, color:'orange', fontWeight:'normal'}}>{Moment(getGameLastPlayDataByCode(gd.code)).format("MMM Do YY")}</span>
                        <span style={{fontSize:10, color:'grey', fontWeight:'normal'}}>&nbsp;({Moment(getGameLastPlayDataByCode(gd.code)).startOf('second').fromNow()})</span>
                    </div>
                </div>
                <div style={{flexGrow:1, display:'flex', overflow:'auto',  background:'linear-gradient(90deg, rgba(174,238,211,1) 0%, rgba(255,255,255,1) 100%)', height:150, width:'100%', flexDirection:'row', padding:10, alignItems:'flex-end', justifyContent:'flex-start', }}>
                    {getGamePlayDataByCode(gd.code).map((b, index) => <div style={{
                        borderBottom:'5px solid #75d697',
                        minWidth:30,
                        display:'flex',
                        alignItems:'flex-end',
                        justifyContent:'center',
                        height:getMaxPoints(getGamePlayDataByCode(gd.code)) > 0 ? `${b.points/getMaxPoints(getGamePlayDataByCode(gd.code))*100}%` : 0, marginRight:2, borderRadius:'5px 5px 0px 0px', background:'linear-gradient(180deg, rgba(47,252,0,1) 0%, rgba(233,249,147,1) 100%)'}}>
                        <div style={{color:'green', fontSize:10}}>{b.points}</div>
                        </div>)}
                </div>

            </div>            
        </div>)}
    </div>)
}

//export default StatisticsView;

export default LayoutWrapper(StatisticsView, {showNavigationBar:true, showSideMenu:true});