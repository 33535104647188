import React from 'react';

const ViewWrapper = (props)  => {

    const [showFeedbackPrompt, setShowFeedbackPrompt] = React.useState(true);

    return(<div style={{width:'100%', boxSizing:'border-box'}}>
        <div className={'view-header'}>
            <div className={'view-header-title'}>{props.title}</div>
            <div style={{display:'flex'}}>
                {props.cancelHandler && <input type="button" className={'button-1'} onClick={()=>props.cancelHandler && props.cancelHandler()} value="Cancel" />}
                {props.saveHandler && <input type="button" className={'button-2'} onClick={()=>props.saveHandler && props.saveHandler()} value="Save" />}
            </div>            
        </div>
        <div className={'view-container'}>
            {props.children}
            
           {/*  {showFeedbackPrompt && <div style={{background:'rgba(34, 44, 52, 0.8)', 
            position:'fixed', 
            margin:'auto 20px',
            display:'flex',
            fontSize:14,
            justifyContent:'center',
            bottom:10, 
            right:10,
            color:'white',
            borderRadius:10, 
            padding:'10px 10px'}}>
                <div style={{background:'none',            
            display:'flex',
            fontSize:12,
            alignItems:'center',
            bottom:0, 
            right:0,
            left:0,
            color:'white',
            borderRadius:10, 
            padding:'0px 20px 0px 20px'}}><span>{'You\'ve got feedback? Reach out to us'}.</span></div>
            <button style={{background:'#27aae1', 
            justifyContent:'center',
            alignItems:'center',
            display:'flex',
            fontSize:12,
            bottom:20, 
            right:20,
            color:'white',
            borderRadius:25,
            fontWeight:'bold', 
            width:32,
            height:30,
            border:0,
            padding:'10px 30px'}} onClick={()=>setShowFeedbackPrompt()} >OK</button></div>} */}
        </div>
        </div>)

}   

export default ViewWrapper;