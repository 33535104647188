import { useEffect, useState } from 'react';

const useTimerCount = (targetDate) => {
  //const countDownDate = new Date(targetDate).getTime();

  const [countDownDate] = useState(new Date(targetDate).getTime())

  const [countDown, setCountDown] = useState(
    //countDownDate - new Date().getTime()
   
    new Date().getTime() - countDownDate
  );

  useEffect(() => {
    const interval = setInterval(() => {
      //  console.log(new Date().getTime() - countDownDate)
      setCountDown(new Date().getTime() - countDownDate);//countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const useCountdownTimerCount = (duration) => {
  //const countDownDate = new Date(targetDate).getTime();

  const [initialDuration] = useState(duration)

  const [countDown, setCountDown] = useState(duration);

  useEffect(() => {
    const interval = setInterval(() => {
       //console.log(countDown)
      setCountDown(c => c > 0 ? c - 1 : c);//countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [initialDuration]);

  //console.log(getCountDownReturnValues(countDown))

  return {time:getCountDownReturnValues(countDown), countDown};
};

const getReturnValues = (countDown) => {
  // calculate time left
  //console.log(countDown)
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000) < 10 ? `0${Math.floor((countDown % (1000 * 60)) / 1000)}` : `${Math.floor((countDown % (1000 * 60)) / 1000)}` ;

  return [days, hours, minutes, seconds];
};

const getCountDownReturnValues = (countDown) => {
  
  const days = Math.floor(countDown / (60 * 60 * 24));
  const hours = Math.floor((countDown % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((countDown % (60 * 60)) / (60));
  const seconds =  Math.floor((countDown % ( 60))) < 10 ? `0${Math.floor((countDown % ( 60)) )}` : `${Math.floor((countDown % ( 60)) )}` ;;

  return [days, hours, minutes, seconds];
};

export { useTimerCount, useCountdownTimerCount };