import React from 'react';
import NavigationBar from './navigation/NavigationBar';
import './LayoutWrapper.css'
import SideMenu from './sideMenu/SideMenu';
import {SideMenuContext} from './sideMenu/SideMenuContext';
import NotificationContainer from '../notification/NotificationContainer';
// Navigation Layout is a HOC for pages
// that will require navigation views to be present.
const LayoutWrapper = (PageComponent, options={}) => (props) => {
        
    return (<SideMenuContext>
            {/*<NotificationsWrapper>*/}
            <div style={{minHeight:'100vh'}}>
            {options.showNavigationBar && <NavigationBar showSideMenuButton={options.showSideMenu}/>}
             {/* Notification Container */}
             <NotificationContainer />

            <div background={options.backgroundImage} className={`centered-view ${options.showSideMenu? 'offset-centered-view':''}`} style={{
                backgroundColor:options.backgroundColor,
                backgroundImage:`url(${options.backgroundImage})`,
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover',
                flexDirection:'column',
                alignItems:`${options.alignCenter? 'center':'flex-start'}`,
                justifyContent:`${options.alignCenter? 'center': 'start'}`
            }}>              
                
                
                {/*Page Component*/}
                <PageComponent {...props}/>
               
                {/*Side Menu*/}
                {options.showSideMenu === true && <SideMenu/>}
            </div>
            </div>
            {/*</NotificationsWrapper>*/}
        </SideMenuContext>)
}

export default LayoutWrapper;


