import React, {useState, useEffect} from 'react';
import styled from 'styled-components'
import CountDownTicker from '../components/CountDownTicker/CountDownTicker';
import useMeasure from 'react-use-measure';
import { useTimerCount } from '../hooks/timerCount';

import coin from '../../assets/images/coin.png'
import ok_button from '../../assets/images/ok-button.svg'
import FluidBox from '../../components/FluidBox/FluidBox';
import { ScoreBox, TimerBox } from '../components/InfoComponents/infoComponents';

import returnIcon from './images/return-icon.png';

import backspaceIcon from './images/backspace-icon.png'

import arrowUpIcon from './images/arrow-up-icon.png'

import {useDispatch, useSelector} from 'react-redux';

import FlashNotification from '../components/FlashNotification';

const getColorFromValue = (value) =>
{
    switch(value)
    {
        case 0 : return "white"
        case 1 : return "red"
        case 2 : return "blue"
    }
}

const ViewBoxCell = styled.div`
    border-radius: 5px;
    display: flex;
    box-shadow: 1px 1px 10px rgb(153 153 153 / 70%);
    background:white;    
    /* height: 60px; */
    padding: 3px;
    width: 100%;
    justify-content:center;
    align-items:center;
    box-sizing:border-box;
    aspect-ratio:1/1;
    margin:5px; 
    font-family:'Righteous';
    cursor:pointer;
    /* border:1px solid #ACACAC; */
    

`;

const BoxCell = styled.div`
    border-radius: 5px;
    display: flex;

    background: ${props => getColorFromValue(props.value) };    
    /* height: 60px; */
    padding: 5px;
    width: 100%;
    aspect-ratio:1/1;
    /* margin:10px; */
    cursor:pointer;
    border:1px solid #ACACAC;

    &:hover {
        transform: scale(1.1);
        box-shadow: 1px 1px 10px rgb(153 153 153 / 70%);
      }

`;


const ViewBoxCell2 = (props) => {

    //const cellRef = React.useRef();
    //const [width, setWidth] = useState([]);

    //import useMeasure from 'react-use-measure'

    /* React.useLayoutEffect(()=>{
        const {width:w} = cellRef.current.getBoundingClientRect();

        setWidth(w);

        console.log("W", w)

    }) */

    const factor = props.children.length > 2 ? props.children.length -2 : 1;

    const [cellRef, bounds] = useMeasure();

    React.useEffect(()=>{
       
       //console.log("W", bounds)

    },[bounds])

    return(
        <ViewBoxCell ref={cellRef} onClick={props.onClick} style={{...props.style, fontSize:bounds.width/2.5/(factor)}}>{props.children}</ViewBoxCell>
    )
}


const Arithmetic = (props) => {

    const dispatch = useDispatch();

    const [days, hours, minutes, seconds] = useTimerCount(new Date());

    const [isLoadingNext, setIsLoadingNext] = useState(false);
    
    const [entry, setEntry] = useState("");
  
    const [points, setPoints] = useState(0);
  
    const [currentOption, setCurrentOption] = useState(null);

    const tref = React.useRef(null);

    const [startRef, { left:startLeft, width:sWidth, top:startTop, height:sHeight }] = useMeasure()
  
    const [endRef, { left:endLeft, width:eWidth, top:endTop, height:eHeight }] = useMeasure()
  
    const [toggle, setToggle] = React.useState(false)
  
    const [offset, setOffset] = React.useState({x:0,y:0})

    const gamePlayOptions = useSelector(state => state.gamePlayOptions)

    const [selectedGamePlayOptions, setSelectedGamePlayOptions] = useState(null);

    const [showCorrectNotification, setShowCorrectNotification] = useState(false);

    const [showIncorrectNotification, setShowIncorrectNotification] = useState(false);

    React.useEffect(()=>{

        //if(selectedGame)
        {
            let gameOptions = gamePlayOptions.find(g => g.gameCode === props.gameCode);

            if(gameOptions)
            {
                setSelectedGamePlayOptions({...gameOptions})
            }
            else
            {
                setSelectedGamePlayOptions({
                    gameCode:props.gameCode,
                    isAudioOn:false,
                    difficulty: 1
                }) 
            }
        }

    },[gamePlayOptions])

    React.useLayoutEffect(()=>{
        const {x, y} = tref.current.getBoundingClientRect();
        console.log("OFFSET",{x,y, el:endLeft, et:endTop,eh: eHeight})
        setOffset({x, y})
      },[])


    /////////////////////////////////////

    const [value, setValue] = useState("");

    const [answer, setAnswer] = useState(null);

    const [currentTask, setCurrentTask] = useState([]);

    const [timerRefreshHash, setTimerRefreshHash] = useState([Math.random]);



    const [state, setState] = useState([
        
        
        [7,8,9,'CLEAR'],
        [4,5,6,'BK_SPC'],
        [1,2,3,''],
        ['.',0,'-/+',"OK"]
    ])

    const [isPlayerTurn, setIsPlayerTurn] = useState(false)

    React.useEffect(()=>{generateQuestion()},[])

    const generateQuestion = () => {
        let leftValue = Math.ceil(Math.random()*100);

        let rightValue = Math.ceil(Math.random()*100);

        const operands = ["+","-","/","x"]

        let operand = operands[Math.floor(Math.random()*4)];

        let a = 0;

        switch(operand)
        {
            case "+": a = leftValue + rightValue;
                break;
            case "x": 
                leftValue = Math.ceil(Math.random()*100);
                rightValue = Math.ceil(Math.random()*10);
                a = leftValue * rightValue;
                break;
            case "/": 
                a = Math.ceil(Math.random()*100);
                 rightValue = Math.ceil(Math.random()*10);
                 leftValue = rightValue * a;
                break;
            default : a = leftValue - rightValue;
        }

        setAnswer(a)
        setCurrentTask([leftValue, operand, rightValue])    
        setValue("");    
    }


    const restartTimer = () => {
        setTimerRefreshHash(Math.random());
    }

    useEffect(()=>{

        props.onSessionPointChange && props.onSessionPointChange(points)
          dispatch({type:"UPDATE_GAME_SCORE", data:{gameCode:props.gameCode, sessionTime:new Date().toISOString(), sessionId: props.sessionId, points:points}});
    },[points])

    const flashCorrectNotification = () => {
        setShowCorrectNotification(true);

        setTimeout(()=>setShowCorrectNotification(false), 1000);
        setToggle(t => !t);
    }

    const flashIncorrectNotification = () => {
        setShowIncorrectNotification(true)
        setTimeout(()=>setShowIncorrectNotification(false), 1000);
    }

    const checkAnswer = () =>
    {
        console.log("Correct 2", {answer, value})
        if(answer.toString() == value.toString())
        {
            console.log("Correct", {answer, value})
            setValue("");
           // restartTimer();

           if(currentTask[1] == "x")
           {
             setPoints(p => p+2);
           }
           if(currentTask[1] == "/")
           {
             setPoints(p => p+4);
           }
           else
           {
             setPoints(p => p+1);
           }
     
           setIsLoadingNext(true)//s => !s)
           //setFlipped(false)//f => !f)
           //setToggle(t => !t);
           flashCorrectNotification();
        }
        else
        {
            flashIncorrectNotification();
        }
        setIsLoadingNext(true)
        restartTimer();
        generateQuestion(); 
    }

    const goToNextQuestion = () => {
        if(!isLoadingNext)
        {
            restartTimer();
            setIsLoadingNext(false)
        }
        //setIsLoadingNext(false)
        generateQuestion(); 
    }

    const handleCellClick = (val) => {

        /* let newState = [...state];

        console.log({r, c, d:newState[r][c]})
        if(newState[r][c] == 0)
        {
            newState[r][c] = isPlayerTurn ? 1 : 2;
        }

        setState([...newState]);

        setIsPlayerTurn(ipt => !ipt); */


        //setValue(v => `${v}${val}`)

        switch(val)
        {
            case "CLEAR": setValue(v => "")
                break;
            case "-/+": setValue(v => v[0] == "-" ? v.substring(1,v.length) : `${"-"}${v}`)
                break;
            case "BK_SPC": setValue(v => v.substring(0,v.length-1))
                break;
            case "OK": checkAnswer()
                break;                
            default : setValue(v => `${v}${val}`)
        }

        /* switch(val)
        {
            case "1": setValue(v => `${v}${val}`)
        } */

    }
    //#884f27
    //background:'linear-gradient(135deg, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 100%)', 
    const buttonStyle = {flexGrow:1, border:'1px solid #078b72', borderRadius:'50%', color:'#078b72', 
    background:'linear-gradient(35deg, rgb(184 239 219) 0%, rgb(238 251 249) 100%)', 
    fontWeight:'bold'}

    return(
        

<FluidBox start={[startLeft + sWidth/2-offset.x, startTop+sHeight/2 -offset.y]} show={toggle} end={[endLeft + eWidth/2-offset.x, endTop + eHeight/2-offset.y]}>
<div  ref={tref} style={{
  height:'100%', width:'100%', position:'absolute',
  //background:'rgb(245, 226, 214)',
  display:'flex',
  flexDirection:'column',
  userSelect:'none'
}}>

<div className={'game-play-info-header'}>     
    {!props.hideGameTime && <TimerBox/>}     
    
    <ScoreBox isAudioOn={selectedGamePlayOptions?.isAudioOn} points={points} ref={endRef} />      
</div>

<div style={{
     width:'100%',
     flexGrow:1,
     position:'relative',
     background:'#EEEEEE',
     padding:5,
     height:80,
     display:'flex',
     justifyContent:'center',
     alignItems:'center',
     flexDirection:'column',

  /*width:'100%',
  flexGrow:1,
  //background:'#EEEEEE',
  padding:20,
  
  //boxShadow:'0px -3px 10px 2px rgba(20,20,10,0.2)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  flexDirection:'column',*/
}}>
  {/* Content */}

  <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center',  justifyContent:'space-around'}}>
            <CountDownTicker key={timerRefreshHash } refreshHash={timerRefreshHash} duration={10} onEnd={()=>{goToNextQuestion(); }}/>

            <div style={{border:'0px dotted black', background:'none', display:'flex', alignItems:'center', justifyContent:'center', width:'60%', minWidth:280,  maxWidth:550, borderRadius:10,  boxShadow:'none', flexDirection:'column'}}>               
                <div style={{border:'0px solid black', width:'100%', display:'flex'}}>
                    {[...currentTask,"="].map((col, c) => <ViewBoxCell2 key={`${c}`}  style={{flexGrow:1, color:'#474747', border:'0px dotted #cecece'}} value={col}>{col}</ViewBoxCell2>)}
                    <ViewBoxCell2 value={0} style={{borderBottom:'2px solid #FF9900', color:'#13607d', background:'#ddebf7'}}>{value}</ViewBoxCell2>
                </div>
            </div>
            
            <div style={{border:'0px dotted black', background:'none', display:'flex', alignItems:'center', justifyContent:'center', width:'30%', minWidth:250, maxWidth:450, borderRadius:10, /* boxShadow:'1px 1px 10px rgb(153 153 153 / 70%)',  */ aspectRatio:'1/1', flexDirection:'column'}}>
                {/* {state.map((row, r) => 
                <div key={r} style={{border:'0px solid black', width:'100%', display:'flex'}}>
                    {row.map((col, c) => <ViewBoxCell2 key={`${r}-${c}`} onClick={()=>handleCellClick(col)}  style={{...buttonStyle}} value={col}>{col}</ViewBoxCell2>)}
                </div>)} */}
                <div key={1} style={{border:'0px solid black', width:'100%', display:'flex'}}>
                    <ViewBoxCell2 onClick={()=>handleCellClick(7)}  style={{...buttonStyle}} value={7}>{7}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick(8)}  style={{...buttonStyle}} value={8}>{8}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick(9)}  style={{...buttonStyle}} value={9}>{9}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick("CLEAR")}  style={{...buttonStyle}} value={"CLEAR"}>{"CLEAR"}</ViewBoxCell2>
                </div>
                <div key={2} style={{border:'0px solid black', width:'100%', display:'flex'}}>
                    <ViewBoxCell2 onClick={()=>handleCellClick(4)}  style={{...buttonStyle}} value={4}>{4}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick(5)}  style={{...buttonStyle}} value={5}>{5}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick(6)}  style={{...buttonStyle}} value={6}>{6}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick("BK_SPC")}  style={{...buttonStyle}} value={"BK_SPC"}>
                        <img ref={startRef} width={'50%'} src={backspaceIcon}/>
                    </ViewBoxCell2>
                </div>
                <div key={3} style={{border:'0px solid black', width:'100%', display:'flex'}}>
                    <ViewBoxCell2 onClick={()=>handleCellClick(1)}  style={{...buttonStyle}} value={1}>{1}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick(2)}  style={{...buttonStyle}} value={2}>{2}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick(3)}  style={{...buttonStyle}} value={3}>{3}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick("")}  style={{...buttonStyle}} value={""}>{""}</ViewBoxCell2>
                </div>
                <div key={4} style={{border:'0px solid black', width:'100%', display:'flex'}}>
                    <ViewBoxCell2 onClick={()=>handleCellClick(".")}  style={{...buttonStyle}} value={"."}>{"."}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick(0)}  style={{...buttonStyle}} value={0}>{0}</ViewBoxCell2>
                    <ViewBoxCell2 onClick={()=>handleCellClick("-/+")}  style={{...buttonStyle}} value={"-/+"}>{"-/+"}</ViewBoxCell2>
                    <ViewBoxCell2  onClick={()=>handleCellClick("OK")}  style={{...buttonStyle}} value={"OK"}>
                        <img ref={startRef} width={'50%'} src={returnIcon}/> </ViewBoxCell2>
                </div>
            </div>
        </div>
 
</div>

{showCorrectNotification && <div style={{fontSize:30,
        position:'absolute', height:'100%', width:'100%', 
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        background:'rgba(20,20,20,0.9)', color:'#9cef9c', fontWeight:'normal'}}>
    <FlashNotification size={260}><span style={{fontSize:30, color:'#065806',fontWeight:'normal'}}>Correct!</span></FlashNotification>              
    
    
</div>}    

{showIncorrectNotification && <div style={{fontSize:30,
        position:'absolute', height:'100%', width:'100%', 
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        background:'rgba(20,20,20,0.9)', color:'#9cef9c', fontWeight:'normal'}}>
    <FlashNotification color={'red'} size={260}><span style={{fontSize:30, color:'#FFFFFF',fontWeight:'normal'}}>Incorrect!</span></FlashNotification>              
    
    
</div>}   
 
</div>
</FluidBox>

        
    )
}

export default Arithmetic;