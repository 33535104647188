import React, {useEffect, useRef, useState} from 'react';
import { useSpring, config, animated as a } from 'react-spring';

import FlipCard from '../components/FlipCard/FlipCard';
import CountDownTicker from '../components/CountDownTicker/CountDownTicker';
import FluidBox from '../../components/FluidBox/FluidBox';
import useMeasure from 'react-use-measure';
import ok_button from '../../assets/images/ok-button.svg';
import { useTimerCount } from '../hooks/timerCount';
import {Howl} from 'howler'
import PullRelease from '../../components/PullRelease/PullRelease';
import {useDispatch, useSelector} from 'react-redux';
import { TimerBox, ScoreBox, GamePlayLifeBox, GamePlayLevelBox } from '../components/InfoComponents/infoComponents';
import { shuffle } from '../../utilities/arrayUtilities';

import rotateRight from '../../assets/images/rotate_right.svg'
import CountDownBox from '../../components/CountDownBox/CountDownBox';
import lifeFilled from '../../assets/images/life-filled.svg'
import lifeEmpty from '../../assets/images/life-empty.svg'


const Item = ({x,y,size,isSelected, renderHash, onClick}) => {

    const [styles, api] = useSpring(() => ({ from:{x:0, y:0, scale:0}}))

    useEffect(()=>{
    // Update spring with new props
    
    api.start({ to: {x:renderHash? 0: x,y: renderHash? 0:y, scale:renderHash? 0:1} })
    //console.log({renderHash, api})
    // Stop animation
    //api.stop()
    },[x,y,renderHash])
    

    return(<a.div onClick={()=>onClick()} style={{width:size, boxShadow:'inset -3px -3px 8px 2px rgba(10,10,10,0.7)', borderRadius:'50%', position:'absolute',  top:styles.y, left:styles.x, transform:styles.scale.to( s =>`scale(${s})`), height:size, background: isSelected? 'green':'linear-gradient(135deg, rgb(255 0 0) 0%, rgb(99 24 1) 100%)'}}></a.div>)

}

const PatternRecognitionView = React.forwardRef((props, containerRef) => {

    const [items, setItems] = useState([]);
    //...props.items]);    
    //console.log(items);

    useEffect(()=>{
        setItems([...props.items])
    },[props.items, props.renderHash])

    const clickHandler = (id) =>
    {
        console.log({id, c:props.itemClicked})
        props.itemClicked && props.itemClicked(id);
    }

    return(
        <div ref={containerRef} style={{width:'100%', overflow:'hidden', borderRadius:10, height:'100%', position:'absolute', boxShadow:'2px 2px 2px rgba(10,10,10,0.3)', background:'black' /* background:'linear-gradient(135deg,#5ee7df,#b490ca)' */}}>
            {items.map(item => <Item onClick={()=>clickHandler(item.id)} key={item.id} renderHash={props.renderHash} x={item.x} y={item.y} isSelected={item.isSelected} size={item.size}/> )}
        </div>
    )
})



const PatternRecognition = (props) => {

    const [flipped, setFlipped] = useState(false);

    const [isShowingPickedItems, setIsShowingPickingCard] = useState(true);

    const [isLoadingNext, setIsLoadingNext] = useState(false);
        
    const [entry, setEntry] = useState("");
  
    const [points, setPoints] = useState(0);
  
    const [currentOption, setCurrentOption] = useState(null);

    const containerRef = React.createRef();//React.useRef(null);

    const [items, setItems] = useState([]);

    const tref = React.useRef(null);

    const [days, hours, minutes, seconds] = useTimerCount(new Date());

    const [startRef, { left:startLeft, width:sWidth, top:startTop, height:sHeight }] = useMeasure()
  
    const [endRef, { left:endLeft, width:eWidth, top:endTop, height:eHeight }] = useMeasure()

    const [toggle, setToggle] = React.useState(false)
  
    const [offset, setOffset] = React.useState({x:0,y:0})

    const dispatch = useDispatch();

    const gamePlayOptions = useSelector(state => state.gamePlayOptions)

    const [selectedGamePlayOptions, setSelectedGamePlayOptions] = useState(null);

    React.useEffect(()=>{

        //if(selectedGame)
        {
            let gameOptions = gamePlayOptions.find(g => g.gameCode === props.gameCode);

            if(gameOptions)
            {
                setSelectedGamePlayOptions({...gameOptions})
            }
            else
            {
                setSelectedGamePlayOptions({
                    gameCode:props.gameCode,
                    isAudioOn:false,
                    difficulty: 1
                }) 
            }
        }

    },[gamePlayOptions])

    React.useLayoutEffect(()=>{
      const {x, y} = tref.current.getBoundingClientRect();
      setOffset({x, y})
    },[])




    const goToNextQuestion = React.useCallback((cc)=>{
      console.log("GG", {containerRef, cc})
      setIsShowingPickingCard(false);setFlipped(false);//getNextQuestions()
    },[containerRef])

    const getNextQuestions = () => {

        setItems(itms => []);

        console.log({c:containerRef.current})

        const {x, y, width, height} = containerRef.current.getBoundingClientRect();

        console.log({x, y, width, height})

        const GRID_SIZE = 6;
        const GRID_W_SIZE = width/GRID_SIZE;
        const GRID_H_SIZE = height/GRID_SIZE;

        const NUM_OF_MARKED_ITEMS = 2 + (2 * selectedGamePlayOptions?.difficulty);

        let count = 0;

        let ids = [];

        for(let i = 1; i < GRID_SIZE-1; i ++)
        {
            for(let j = 1; j < GRID_SIZE-1; j ++)
            {                
                ids.push(`${i}-${j}`)                   
            }
        }

        ids = shuffle(ids);

        ids  = ids.slice (0, NUM_OF_MARKED_ITEMS);

        console.log(ids)

        for(let i = 1; i < GRID_SIZE-1; i ++)
        {
            for(let j = 1; j < GRID_SIZE-1; j ++)
            {

                let newItem = {
                    id:`${i}-${j}`,
                    size: 10 + Math.random()*GRID_W_SIZE/2,
                    x:GRID_W_SIZE * i + (GRID_W_SIZE*Math.random()),
                    y:GRID_H_SIZE * j + (GRID_H_SIZE*Math.random()),
                    isMarked:ids.some(f => f == `${i}-${j}`),
                    isSelected:false
                }
                
                setItems(itms => [...itms, newItem])
            }
        }
    }

    useEffect(()=>{

        getNextQuestions();

    },[selectedGamePlayOptions])

    useEffect(()=>{

        //getNextQuestions();
        console.warn("PARENT",{flipped, isShowingPickedItems})
        if(!isShowingPickedItems)
          checkEntry();

    },[isShowingPickedItems])

    
  
    
    const itemClickedHandler = (itemId) => {

        console.log("CLICKed", itemId)
        setItems(itms => itms.map(item => item.id == itemId ? {...item, isSelected: !item.isSelected} : item))

    }
  
    /* useEffect(()=>{
  
      if(options && options.length > 0)
      {
        setCurrentOption(options[0]);
      }    
  
    },[options]) */

    useEffect(()=>{
  
        console.warn("PARENT",flipped)
    
    },[flipped])
  
    const checkEntry = () => { 
      
      //console.log({c: currentOption.value, p: points, e: entry});
  
      //if(entry == currentOption.value)
      {
        //setPoints(p => p+1);
        //setFlipped(f=> !f)
        //setIsLoadingNext(s => !s);
      }

      // Analyse the picks
      let count = items.filter(i => i.isSelected && i.isMarked).length;

      setIsShowingPickingCard(false);

      setPoints(p => p + count);

      //setIsShowingPickingCard(g => !g);

      setFlipped(false);//f=> !f);
      
      getNextQuestions();

      if(count > 0)
      setToggle(t => !t);
  
    }

    useEffect(()=>{

      props.onSessionPointChange && props.onSessionPointChange(points)
        dispatch({type:"UPDATE_GAME_SCORE", data:{gameCode:props.gameCode, sessionTime:new Date().toISOString(), sessionId: props.sessionId, points:points}});
    },[points])
  
    const handleChange = (e) =>
    {
      const {value} = e.target;
  
      setEntry(value);
    }

    
  
    return(
      <FluidBox start={[startLeft + sWidth/2-offset.x, startTop+sHeight/2 -offset.y]} show={toggle} end={[endLeft + eWidth/2-offset.x, endTop + eHeight/2-offset.y]}>
      
      <div ref={tref} style={{
        width:'100%',
        height:'100%',
        //background:'#FFFFFF',
        display:'flex',
        flexDirection:'column'
      }}>
  
      <div className={'game-play-info-header'}>
        {/*!props.hideGameTime && <TimerBox/>*/} 

        <GamePlayLevelBox levels={200}/>

        <GamePlayLifeBox lives={3}/>
        
        <ScoreBox isAudioOn={selectedGamePlayOptions?.isAudioOn} points={points} ref={endRef} />
      </div>

      <div style={{padding:10}}>
        <CountDownBox duration={200}/>
      </div>
      
  
      {true && <div style={{
        width:'100%',
        //flexGrow:1,
        //background:'#EEEEEE',
        padding:5,
        height:80,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
      }}>
        {flipped == true ? <CountDownTicker  key={1 + isLoadingNext} refreshHash={flipped} duration={12} onEnd={()=>goToNextQuestion()}/>
        : <CountDownTicker key={2 + isLoadingNext} refreshHash={!flipped} duration={3} onEnd={()=>setFlipped(true)}/>
        } 
        {/* <CountDownTicker refreshHash={!flipped} duration={flipped? 16 : 3} onEnd={()=>setFlipped(f => !f)}/> */}
      </div>}
        {/* <CountDownBox renderHash={flipped} duration={6} onEnd={()=>setFlipped(f => !f)}/> */}
      <div style={{
        width:'100%',
        flexGrow:1,
        //background:'#EEEEEE',
        padding:20,
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexDirection:'column',  
      }}>
        <div style={{
          display:'flex', 
          justifyContent:'center', 
          //flexDirection:'column',
          height:'100%',
          width:'100%',
          alignItems:'center', 
        }}> 
        <div style={{ display:'flex', 
          justifyContent:'center', 
          width:'90%',
          height:'90%',
          border:0,
          alignItems:'center', margin:5}}><FlipCard style={{maxWidth:'100%'}} flipped={!flipped} back={<PatternRecognitionView key={1} itemClicked={itemClickedHandler} renderHash={!flipped} ref={containerRef} items={items}/>}
          front={<PatternRecognitionView key={2} renderHash={flipped} items={items.filter(item => item.isMarked)}/>}/>
          </div>
  
          
  
        </div>
  
        <div>
          <div  ref={startRef} onClick={()=>checkEntry()} style={{cursor:'handle',  visibility: flipped ? 'visible' : 'visible', borderRadius:'50%', cursor:'pointer', 
          background:'#96fbc4',
          borderRadius:20,
          fontSize:16,
          color:'#003c36',
          border:0,
          padding:'10px 25px',
          letterSpacing:2,
          boxShadow:'2px 2px 2px rgba(20,20,20,0.5)'}}>
         {/*  <img className={'game-ok-button'} src={ok_button}/>  */} SUBMIT
          </div>
        </div>

        <div>
          <div  style={{width:50, cursor:'handle', height:50, visibility: flipped ? 'visible' : 'visible', borderRadius:'50%', cursor:'pointer', background:'none'}}>
           
          </div>
        </div>
      
  
      </div>
  
      </div>
      {/* <PullRelease/> */}
      </FluidBox>
      
    )
  }

export default PatternRecognition;