import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { HashRouter as Router } from "react-router-dom";
import {Provider} from 'react-redux';
import rootReducer from './redux/reducers/rootReducer';
import {createStore, applyMiddleware} from 'redux';
import {configureStore} from '@reduxjs/toolkit'
//import thunk from 'redux-thunk'
import axios from 'axios'


const store = configureStore({reducer:rootReducer})//,  applyMiddleware(thunk.withExtraArgument({axios})))

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>      
        <App />      
      </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



