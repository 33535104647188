import React, { useEffect } from 'react';
import { useSpring, useSprings, animated, interpolate, to, config } from 'react-spring'
import { useDrag } from '@use-gesture/react'


const ToggleSelect = () => {
    
    const [toggleState, setToggleState] = React.useState(true);
    const [{ x, y, opacity, size:height, size: width}, set] = useSpring(() => ({ x: toggleState ? '0%' : '60%', y: 0, opacity:1, size:0 }));

    useEffect(()=>
        {
            //set({ x: 25, y: 0, opacity:1, size: 20 })
            set({ x: toggleState ? '0%' : '60%', y: 0, opacity:1, size:0 })
            console.log(x)
            //set({to:[{ x: -25, y: -25, opacity:1}, { x: -50, y: -50, opacity:0}] })
        }
    ,[toggleState])

    const handleClick = () => {
        setToggleState(s => !s)
    }

    return(
        <div onClick={handleClick} style={{border:'1px solid #999999', boxSizing:'unset', width:50, height:20, background: toggleState ? '#aadeaa' : 'grey', position:'relative', borderRadius:'20px'}}>
        {<animated.div style={{background:'rgba(100,100,100,0.3)', position:'absolute', borderRadius:'50%', width:20, left:x, height:20, /* transform: to([x, y], (x, y) => `translate(${x}px, ${y}px)`), */ opacity}}>
            <div style={{background:'red', margin:'5px', borderRadius:'50%', width:10, left:x, height:10,}}></div>
        </animated.div>}
        </div>
    )
}

function PullRelease() {
    const [heightOffset, setHeightOffset] = React.useState(0);
    const [height, setHeight] = React.useState(40);
    const [{ x, y }, set] = useSpring(() => ({ x: 0, y: 0 , config: config.wobbly}));
  
    // Set the drag hook and define component movement based on gesture data
    const bind = useDrag(({ down, movement: [mx, my] }) => {
      set({ x: down ? mx : 0, y: down ? my : 0 })
      setHeightOffset(down ? my : 0);
      //set({ x: mx, y: my})
      //setHeightOffset( my);

      if(!down)
      {
        if(height - heightOffset < 100)
        {
            setHeight(40);
        }  
        else
        {
            //setHeight(h => h - my);
            setHeight(200);
            console.log(height - my)
        }
        
      }
    })
  
    // Bind it to a component
    return (
        <div style={{position:'absolute', overflow:'hidden', boxSizing:'border-box', bottom:0, minHeight:30, borderRadius:'20px 20px 0px 0px', height:`${height - heightOffset}px`, width:'100%', background:'#FFFFFF',border:'1px solid #CCCCCC', boxShadow:'0px -2px 2px rgba(100,100,100,0.2)'}}>
        <div style={{display:'flex', position:'relative', padding:10, justifyContent:'center'}}>
        <animated.div {...bind()} style={{ touchAction: 'none',  justifyContent:'center', alignItems:'flex-start', display:'flex', position:'relative', top:0, height:30, fontSize:8, width:300, borderRadius:5, border:'0px solid rgb(156 148 148 / 87%)', background:'none', cursor:'pointer'}}>
            <div  style={{ touchAction: 'none', height:10, fontSize:8, width:80, borderRadius:5, border:'0px solid rgb(156 148 148 / 87%)', background:'#21b065', cursor:'pointer'}}></div>
        </animated.div>
        </div>
        <div style={{display:'flex', alignItems:'center', flexDirection:'column', }}>
            <span style={{padding:10}}>Difficulty</span>
            <div>
                <div style={{borderRadius:20, display:'flex', boxShadow:'2px 2px 5px rgba(50,50,50,0.5)', overflow:'hidden', display:'inline-block'}}>
                    <div style={{padding:'5px 15px', display:'inline-block'}}>Easy</div>
                    <div style={{padding:'5px 15px', border:'1px solid #999999', borderWidth:'0px 1px', display:'inline-block'}}>Medium</div>
                    <div style={{padding:'5px 15px', display:'inline-block'}}>Hard</div>
                </div>
            </div>
            <span style={{padding:10}}>Difficulty</span>
            <div>
                <div style={{borderRadius:20, display:'flex', boxShadow:'2px 2px 5px rgba(50,50,50,0.5)', overflow:'hidden', display:'inline-block'}}>
                    <ToggleSelect/>
                </div>
            </div>
            
            
        </div>
    </div>)

}

export default PullRelease;