import React, { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import Logo1  from '../assets/images/brain-trainer-logo-large.svg';
import backgroundImage from '../assets/images/background_01.svg';
import backgroundImage02 from '../assets/images/background_02.svg';
import DropIns from '../games/components/DropIns/DropIns';
import {animated, config, useSpring} from 'react-spring';
import { useSelector, useDispatch} from 'react-redux';
import introImage from '../assets/images/intro-image.JPG'

const SplashScreen = (props) => {

    const navigate = useNavigate();

    const user = useSelector( state => state.user);

    console.log(user)

    const [{x, opacity}, api] = useSpring(()=>({from:{x:-60, opacity:0}, config:config.wobbly}))

    const navigateToNext = () => {

        if(user && user.name && user.gender)
            navigate('all-games')
        else
            navigate('setup')
    }

    useEffect(()=>
    {
        api.start(()=>({to:{x:0, opacity:1}}))
    },[])

    return(
        <div style={{display:'flex',
         justifyContent:'space-around',
         padding:30,
         textAlign:'center',
         alignItems:'center',
         position:'absolute', 
         flexDirection:'column',
         background:'linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%)',
         background:`url('${backgroundImage}')`,
         //background:`white`,
         backdropFilter:'greyscale(80%)',
         backgroundRepeat:'no-repeat',
         backgroundSize:'cover',
         height:'100vh', width:'100vw'}}>
             
                          
            <div style={{position:'relative', width:150, height:150}}>
                
                <animated.img width={150} style={{position:'absolute',left:x, opacity}} src={Logo1}/>
            </div>
           {/*  <animated.div style={{color:'#333333', width:'100%', background:'#E2E2E4', position:'relative', top:x, 
            opacity, fontFamily:'Arial',}} >
              
                <img style={{width:'100%', maxWidth:250, filter:'grayscale(1)'}} src={introImage}/>
            </animated.div> */}
            <div style={{color:'#0f5a4d', fontSize:28, maxWidth:250}}  >
                <div>Welcome</div>
                <div>to</div>
                <div className={"app-title"} style={{fontFamily:'Dancing Script', color:'#56BE8F', 
                display:'flex', whiteSpace:'pre-wrap', maxWidth:340, fontSize:46,
                background:'linear-gradient(45deg, #56be8f, #fdc90d)', backgroundClip:'text', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}}>
                   <DropIns>{'Brain'}</DropIns><span>&nbsp;</span><DropIns>{'Trainer'}</DropIns> 
                </div>
            </div>
           
            <div>
                <button onClick={()=>navigateToNext()} style={{fontFamily:'Righteous', boxShadow:'0px 1px 0px 1px rgb(26 108 140)'}} className='start-button'>START</button>
                <div style={{color:'#0f5a4d',bottom:-15, position:'relative', fontSize:12, maxWidth:250}} >
                    <p>
                    Brain Trainer 2022
                    </p>
                    <a style={{cursor:'pointer', textDecoration:'none', color:'grey'}} href='privacy-policy.html'>
                    Privacy Policy
                    </a>
                </div>
            </div>
           
        {/* <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
         */}</div>
        )
}

export default SplashScreen;//LayoutWrapper(SplashScreen, {showNavigationBar:false, showSideMenu:false});