/*import React, {useState} from 'react';
import styled from 'styled-components'

const getColorFromValue = (value) =>
{
    switch(value)
    {
        case 0 : return "white"
        case 1 : return "red"
        case 2 : return "blue"
        default : return "white"
    }
}

const BoxCell = styled.div`
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${props => getColorFromValue(props.value) };    
    
    padding: 5px;
    width: 100%;
    aspect-ratio:1/1;
    
    cursor:pointer;
    border:1px solid #ACACAC;

    &:hover {
        transform: scale(1.1);
        box-shadow: 1px 1px 10px rgb(153 153 153 / 70%);
      }

`;

const characters = [
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l","m", "n",
    "o", "p","q", "r","s", "t","u", "v","w", "x","y", "z"
]

const getCharacter = () => {
    return characters[Math.floor(Math.random() * characters.length)]
}

const generateGrid = (size, defaultValue) => {

    let generatedGrid = [];

    for(let i = 0; i < size; i ++)
    {
        let row = [];

        for(let j = 0; j < size; j ++)
        {
            row.push(defaultValue ? defaultValue : getCharacter());
        }

        generatedGrid.push(row);
    }

    return generatedGrid;

}

const CharactersInGrid = (props) => {

    const [stateData, setStateData] = useState(generateGrid(8))

    const [isPlayerTurn, setIsPlayerTurn] = useState(false)

    const handleCellClick = (r, c) => {

        let newState = [...stateData];

        console.log({r, c, d:newState[r][c]})

        if(newState[r][c] == 0)
        {
            newState[r][c] = isPlayerTurn ? 1 : 2;
        }

        setStateData([...newState])

        setIsPlayerTurn(ipt => !ipt);
    }

    return(
        
            
            <div style={{border:'0px dotted black', background:'#cecece', display:'flex', alignItems:'center', justifyContent:'center', width:'50%', minWidth:300, borderRadius:10, boxShadow:'1px 1px 10px rgb(153 153 153 / 70%)',  aspectRatio:'1/1', flexDirection:'column'}}>
                {stateData.map((row, r) => 
                    <div key={r} style={{border:'0px solid black', width:'100%', display:'flex'}}>
                        {row.map((col, c) => <BoxCell key={`${r}-${c}`} onClick={()=>handleCellClick(r, c)}  style={{flexGrow:1}} value={col}>{col}</BoxCell>)}
                    </div>)}
            </div>

        
    )
}

export default CharactersInGrid;*/

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useSpring, config, animated as a } from 'react-spring';
import coin from '../../assets/images/coin.png'
import FlipCard from '../components/FlipCard/FlipCard';
import CountDownTicker from '../components/CountDownTicker/CountDownTicker';
import FluidBox from '../../components/FluidBox/FluidBox';
import useMeasure from 'react-use-measure';
import ok_button from '../../assets/images/ok-button.svg';
import { useTimerCount } from '../hooks/timerCount';
import {useDispatch} from 'react-redux';
//import x_img from './images/x_img.png';
import PullRelease from '../../components/PullRelease/PullRelease';
import { ScoreBox, TimerBox } from '../components/InfoComponents/infoComponents';
import { shuffle } from '../../utilities/arrayUtilities';

const getColorFromValue = (value) =>
{
    switch(value)
    {
        case 0 : return "white"
        case 1 : return "red"
        case 2 : return "blue"
    }
}

const BoxCell = styled.div`
    border-radius: ${props => props.isShowingMarked == 1 ? '50%' : '5px'};
    display: flex;
    justify-content:center;
    align-items:center;
    background: ${props => getColorFromValue(props.value) };  
   
    background-repeat:no-repeat; 
    background-position: center center;.
      
    /* height: 60px; */
    padding: 5px;
    width: 100%;
    aspect-ratio:1/1;
    /* margin:10px; */
    cursor:pointer;
    border:1px solid #ACACAC;

    &:hover {
        transform: scale(1.1);
        box-shadow: 1px 1px 10px rgb(153 153 153 / 70%);
      }

`;

const characters = [
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l","m", "n",
    "o", "p","q", "r","s", "t","u", "v","w", "x","y", "z"
]

const getCharacter = () => {
    return characters[Math.floor(Math.random() * characters.length)]
}

const generateGrid = (size, defaultValue) => {

    let generatedGrid = [];

    for(let i = 0; i < size; i ++)
    {
        let row = [];

        for(let j = 0; j < size; j ++)
        {
            row.push(defaultValue ? defaultValue : getCharacter());
        }

        generatedGrid.push(row);
    }

    return generatedGrid;
}

const MatrixPatternGrid = React.forwardRef((props, containerRef) => {

    const [items, setItems] = useState([]);
    //...props.items]);    
    //console.log(props.items);

    useEffect(()=>{
        setStateData([...props.items])
    },[props.items, props.renderHash])

    
    const [stateData, setStateData] = useState([
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
    ])

    const [isPlayerTurn, setIsPlayerTurn] = useState(false)

    const clickHandler = (id) =>
    {
        console.log({id, c:props.itemClicked})
        !props.showMarked && props.itemClicked && props.itemClicked(id);
    }

    const handleCellClick = (r, c) => {

        let newState = [...stateData];

        console.log({r, c, d:newState[r][c]})
        if(newState[r][c] == 0)
        {
            newState[r][c] = isPlayerTurn ? 1 : 2;
        }

        setStateData([...newState])

        setIsPlayerTurn(ipt => !ipt);

    }

        return(
            <div  ref={containerRef} style={{border:'0px dotted black', background: props.showMarked ? 'none' :'#cecece', display:'flex', alignItems:'center', justifyContent:'center', width:'70%', borderRadius:10, boxShadow: props.showMarked ? 'none' : '1px 1px 10px rgb(153 153 153 / 70%)',  aspectRatio:'1/1', flexDirection:'column'}}>
                {stateData.map((row, r) => 
                    <div key={r} style={{border:'0px solid black', width:'100%', display:'flex'}}>
                        {row.map((col, c) => <div key={`${r}-${c}`} onClick={()=>clickHandler(col.id)} /* onClick={()=>handleCellClick(r, c)} */  style={{flexGrow:1,border:'0px dotted #cecece'}}><BoxCell isShowingMarked={props.showMarked} item={col} value={props.showMarked && col.isMarked ?  1 : col.isSelected ? 2 : 0} /></div>)}
                    </div>)}
            </div>
            )
})

const CharactersInGridView = (props) => {

    const [stateData, setStateData] = useState(props.data)

    //const [isPlayerTurn, setIsPlayerTurn] = useState(false)

    const handleCellClick = (r, c) => {

        let newState = [...stateData];

        console.log({r, c, d:newState[r][c]})

        if(newState[r][c] == 0)
        {
            newState[r][c] = 1;//isPlayerTurn ? 1 : 2;
        }

        setStateData([...newState])

        //setIsPlayerTurn(ipt => !ipt);
    }

    return(
        
            
            <div style={{border:'0px dotted black', background:'#cecece', display:'flex', alignItems:'center', justifyContent:'center', width:'50%', minWidth:230, borderRadius:10, boxShadow:'1px 1px 10px rgb(153 153 153 / 70%)',  aspectRatio:'1/1', flexDirection:'column'}}>
                {stateData.map((row, r) => 
                    <div key={r} style={{border:'0px solid black', width:'100%', display:'flex'}}>
                        {row.map((col, c) => <BoxCell key={`${r}-${c}`} onClick={()=>handleCellClick(r, c)}  style={{flexGrow:1}} value={col}>{col}</BoxCell>)}
                    </div>)}
            </div>

        
    )
}


const Item = ({x,y,size,isSelected, renderHash, onClick}) => {

    const [styles, api] = useSpring(() => ({ from:{x:0, y:0, scale:0}}))

    useEffect(()=>{
    // Update spring with new props
    
    api.start({ to: {x:renderHash? 0: x,y: renderHash? 0:y, scale:renderHash? 0:1} })
    //console.log({renderHash, api})
    // Stop animation
    //api.stop()
    },[x,y,renderHash])
    

    return(<a.div onClick={()=>onClick()} style={{width:size, boxShadow:'2px 2px 5px 2px rgba(10,10,10,0.5)', borderRadius:'50%', position:'absolute',  top:styles.y, left:styles.x, transform:styles.scale.to( s =>`scale(${s})`), height:size, background: isSelected? 'green':'linear-gradient(135deg, rgb(255 0 0) 0%, rgb(99 24 1) 100%)'}}></a.div>)

}



const CharactersInGrid = (props) => {

    const GRID_SIZE = 8;
    

    const NUM_OF_MARKED_ITEMS = 8;

    const [flipped, setFlipped] = useState(false);

    const [isShowingPickedItems, setIsShowingPickingCard] = useState(true);

    const [isLoadingNext, setIsLoadingNext] = useState(false);
        
    const [entry, setEntry] = useState("");
  
    const [points, setPoints] = useState(0);
  
    const [currentOption, setCurrentOption] = useState(null);

    const containerRef = React.createRef();//React.useRef(null);

    const [items, setItems] = useState([]);

    const tref = React.useRef(null);

    
    const [startRef, { left:startLeft, width:sWidth, top:startTop, height:sHeight }] = useMeasure()
  
    const [endRef, { left:endLeft, width:eWidth, top:endTop, height:eHeight }] = useMeasure()

    const [toggle, setToggle] = React.useState(false)
  
    const [offset, setOffset] = React.useState({x:0,y:0})

    const dispatch = useDispatch();


    React.useLayoutEffect(()=>{
      const {x, y} = tref.current.getBoundingClientRect();
      setOffset({x, y})
    },[])


    const [stateData, setStateData] = useState(generateGrid(8))

    const [isPlayerTurn, setIsPlayerTurn] = useState(false)

    const handleCellClick = (r, c) => {

        let newState = [...stateData];

        console.log({r, c, d:newState[r][c]})

        if(newState[r][c] == 0)
        {
            newState[r][c] = isPlayerTurn ? 1 : 2;
        }

        setStateData([...newState])

        setIsPlayerTurn(ipt => !ipt);
    }

    const goToNextQuestion = React.useCallback((cc)=>{
      console.log("GG", {containerRef, cc})
      setIsShowingPickingCard(false);setFlipped(false);//getNextQuestions()
    },[containerRef])

    const getNextQuestions = () => {

        setItems(itms => []);

        console.log({c:containerRef.current})

        const {x, y, width, height} = containerRef.current.getBoundingClientRect();

        console.log({x, y, width, height})

        const GRID_W_SIZE = width/GRID_SIZE;
        const GRID_H_SIZE = height/GRID_SIZE;

        let count = 0;

        let ids = [];

        for(let i = 1; i < GRID_SIZE-1; i ++)
        {
            for(let j = 1; j < GRID_SIZE-1; j ++)
            {                
                ids.push(`${i}-${j}`)                   
            }
        }

        ids = shuffle(ids);

        ids  = ids.slice (0, NUM_OF_MARKED_ITEMS);

        console.log(ids)

        for(let i = 1; i < GRID_SIZE-1; i ++)
        {
            let rowData = [];

            for(let j = 1; j < GRID_SIZE-1; j ++)
            {

                let newItem = {
                    id:`${i}-${j}`,
                    size: 10 + Math.random()*GRID_W_SIZE/2,
                    x:GRID_W_SIZE * i + (GRID_W_SIZE*Math.random()),
                    y:GRID_H_SIZE * j + (GRID_H_SIZE*Math.random()),
                    isMarked:ids.some(f => f == `${i}-${j}`),
                    isSelected:false
                }
                
               rowData.push(newItem)
            }

            setItems(itms => [...itms, rowData])
        }
    }

    useEffect(()=>{

        //getNextQuestions();

    },[])

    useEffect(()=>{

        //getNextQuestions();
        console.warn("PARENT",{flipped, isShowingPickedItems})
        if(!isShowingPickedItems)
          checkEntry();

    },[isShowingPickedItems])

    
  
    
    const itemClickedHandler = (itemId) => {

        //console.log("CLICKed", itemId, items);

        let pos = itemId.split("-")

        let row = pos[0] - 1;

        let col = pos[1] - 1;

        let newItems = [...items];

        newItems[row][col] = {...newItems[row][col], isSelected: !newItems[row][col].isSelected}

        setItems([...newItems]);//items.map(item => item.id == itemId ? {...item, isSelected: !item.isSelected} : item))

    }
  
    /* useEffect(()=>{
  
      if(options && options.length > 0)
      {
        setCurrentOption(options[0]);
      }    
  
    },[options]) */

    useEffect(()=>{
  
        console.warn("PARENT",flipped)
    
    },[flipped])
  
    const checkEntry = () => { 
      
      //console.log({c: currentOption.value, p: points, e: entry});
  
      //if(entry == currentOption.value)
      {
        //setPoints(p => p+1);
        //setFlipped(f=> !f)
        //setIsLoadingNext(s => !s);
      }

      // Analyse the picks
      let count = 0;//items.filter(i => i.isSelected && i.isMarked).length;

        for(let i = 0; i < GRID_SIZE-2; i ++)
        {
            for(let j = 0; j < GRID_SIZE-2; j ++)
            {                
                
                /* if(items[i][j].isSelected && items[i][j].isMarked)
                {
                    count++;
                }   */                
            }
        }

      setIsShowingPickingCard(false);

      setPoints(p => p + count);

      //setIsShowingPickingCard(g => !g);

      //console.log("Checking", isShowingPickedItems)

      setFlipped(false);//f=> !f);
      
      //getNextQuestions();

      if(count > 0)
        setToggle(t => !t);
  
    }

    
    useEffect(()=>{
        dispatch({type:"UPDATE_GAME_SCORE", data:{points:points}});
    },[points])
  
    const handleChange = (e) =>
    {
      const {value} = e.target;
  
      setEntry(value);
    }

    
  
    return(
      <FluidBox start={[startLeft + sWidth/2-offset.x, startTop+sHeight/2 -offset.y]} show={toggle} end={[endLeft + eWidth/2-offset.x, endTop + eHeight/2-offset.y]}>
      
      <div ref={tref} style={{
        width:'100%',
        height:'100%',
        background:'#FFFFFF',
        display:'flex',
        flexDirection:'column'
      }}>
  
      <div className={'game-play-info-header'}>

        <TimerBox/>       
      
        <ScoreBox points={points} ref={endRef} />
      </div>
  
      {true && <div style={{
        width:'100%',
        //flexGrow:1,
        position:'relative',
        background:'#EEEEEE',
        padding:5,
        height:80,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
      }}>
        {flipped == true ? <CountDownTicker  key={1 + isLoadingNext} refreshHash={flipped} duration={5} onEnd={()=>goToNextQuestion()}/>
        : <CountDownTicker key={2 + isLoadingNext} refreshHash={!flipped} duration={15} onEnd={()=>setFlipped(true)}/>
        } 
        {/* <CountDownTicker refreshHash={!flipped} duration={flipped? 16 : 3} onEnd={()=>setFlipped(f => !f)}/> */}
      </div>}
        {/* <CountDownBox renderHash={flipped} duration={6} onEnd={()=>setFlipped(f => !f)}/> */}
        <div style={{
        width:'100%',
        background:'#EEEEEE',
        padding:10,
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexDirection:'column',  
        position:'relative'
      }}>
          Find the "As"...
          </div>
      <div style={{
        width:'100%',
        flexGrow:1,
        background:'#EEEEEE',
        padding:20,
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexDirection:'column',  
        position:'relative'
      }}>
        <div style={{
          display:'flex', 
          justifyContent:'center', 
          //flexDirection:'column',
          height:'100%',
          width:'100%',
          alignItems:'center', 
        }}> 
            <div style={{ display:'flex', 
                justifyContent:'center', 
                width:'80%',
                height:'80%',
                border:0,
                alignItems:'center', margin:5}}><FlipCard flipped={!flipped} back={<CharactersInGridView key={1} itemClicked={itemClickedHandler} renderHash={!flipped} ref={containerRef} data={stateData}/>}
                front={<CharactersInGridView key={2} renderHash={flipped} showMarked={true} data={stateData}/>}/>
            </div>  
        </div>
  
        <div>
          <div  ref={startRef} onClick={()=>checkEntry()} style={{width:50, cursor:'handle', height:50, visibility: flipped ? 'visible' : 'visible', borderRadius:'50%', cursor:'pointer', background:'none'}}>
          <img src={ok_button}/>  
          </div>
        </div>

        <div>
          <div  style={{width:20, cursor:'handle', height:20, visibility: flipped ? 'visible' : 'visible', borderRadius:'50%', cursor:'pointer', background:'none'}}>
         
          </div>
        </div>
      
       
      </div>
  
      </div>
      <PullRelease/>
      </FluidBox>
    )
  }

export default CharactersInGrid;